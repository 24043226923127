import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ru';
import 'moment/locale/zh-cn';

import translationEn from './locales/en/admin.json';
import translationFr from './locales/fr/admin.json';

const IMPORT_BACKEND = {
    type: 'backend',
    init() {},
    read(language, namespace, callback) {
        moment.locale(language);
        switch (language) {
            case 'fr':
                callback(null, translationFr);
                break;
            case 'en':
                callback(null, translationEn);
                break;
            default:
                console.warn(`Invalid language ${language}, falling back to English`);
                callback(null, translationEn);
                break;
        }
    },
};

i18n.use(IMPORT_BACKEND)
    .use(reactI18nextModule)
    .init({
        fallbackLng: 'fr',
        ns: ['admin'],
        defaultNS: 'admin',

        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        react: {
            defaultTransParent: 'span',
            wait: true,
        },
    });

export default i18n;
