/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Col, Input, Label } from 'reactstrap';
import { translate } from 'react-i18next';

class NewTeamModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            title: '',
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}>{t('NewTeamModal-title')}</ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="orgaName" sm={2}>
                                {t('NewTeamModal-nameFieldLabel')}
                            </Label>
                            <Col sm={10}>
                                <Input
                                    type="text"
                                    name="title"
                                    id="orgaName"
                                    placeholder={t('NewTeamModal-nameFieldLabel')}
                                    value={this.state.title}
                                    onChange={(e) => this.setState({ title: e.target.value })}
                                />
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => this.props.createTeam(this.props.organization.id, this.state.title)}
                        >
                            {t('common-create')}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.props.toggle}>
                            {t('common-cancel')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default translate('admin')(NewTeamModal);
