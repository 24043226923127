import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.css';

class ReportTemplateLayout extends Component {
    render() {
        const { t, children, organization, ReportTemplateTitle } = this.props;

        const url = organization ? `/${organization.id}/report-template` : '';

        return (
            <div className="element-wrapper">
                <h6 className="element-header">
                    {organization ? organization.title : ''} /{' '}
                    <Link className="report-template-link" to={url}>
                        {t('ReportTemplate-title')}
                    </Link>{' '}
                    {ReportTemplateTitle ? ` / ${ReportTemplateTitle}` : ''}
                </h6>
                <div className="element-box">{children}</div>
            </div>
        );
    }
}

export default translate('admin')(ReportTemplateLayout);
