import React, { Component } from 'react';
import { translate } from 'react-i18next';
import BillingGroup from '../../api/BillingGroup.js';
import Credentials from '../export-api-datasets/ExportApiDatasetsCredentials';
import EmptyState from '../export-api-datasets/ExportApiDatasetsEmptyState';

import svgLoader from '../../../theme/img/loader.svg';

class BillingGroupExportApiDatasets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
        this.getCredentials = this.getCredentials.bind(this);
    }
    async getCredentials() {
        try {
            const { url, userId, userPassword } = await BillingGroup.getExportApiCredentials(
                this.props.billingGroup.id,
            );
            this.setState({ url, userId, userPassword, loading: false });
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this.getCredentials();
    }
    render() {
        const { loading, url, userId, userPassword } = this.state;
        const credentialsAreSet = url && userId && userPassword;

        return (
            <div>
                {loading && (
                    <div className="d-flex justify-content-center" style={{ height: '400px' }}>
                        <img src={svgLoader} alt="loader" />
                    </div>
                )}
                {loading === false && credentialsAreSet && (
                    <Credentials
                        notification={this.props.notificationSystem}
                        url={url}
                        userId={userId}
                        userPassword={userPassword}
                    />
                )}
                {!credentialsAreSet && loading === false && <EmptyState />}
            </div>
        );
    }
}

export default translate('admin')(BillingGroupExportApiDatasets);
