import React, { Component } from 'react';
import { translate, Trans } from 'react-i18next';
import moment from 'moment';
import Organization from '../../api/Organization';
import Auth from '../../../app/Auth';
import BillingGroup from '../../api/BillingGroup.js';

class Welcome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstname: null,
            rights: null,
            organization: null,
        };

        this.getOrganization = this.getOrganization.bind(this);
        this.getBillingGroups = this.getBillingGroups.bind(this);
        this.getRights = this.getRights.bind(this);
        this.getAllData = this.getAllData.bind(this);
    }

    async getOrganization() {
        const organization = await Organization.getById(this.props.match.params.organization_id);
        this.setState({ organization });
    }

    async getBillingGroups() {
        const billingGroups = await BillingGroup.get(this.props.match.params.organization_id);
        const billingGroupStats = {
            internal: 0,
            subcontractor: 0,
            observator: 0,
            free: 0,
        };

        billingGroups.forEach((bg) => {
            if (bg.license === 'internal') {
                billingGroupStats.internal += bg.occupied_seats;
            }
            if (bg.license === 'subcontractor') {
                billingGroupStats.subcontractor += bg.occupied_seats;
            }
            if (bg.license === 'observator') {
                billingGroupStats.observator += bg.occupied_seats;
            }
            if (!bg.is_default_internal && !bg.is_default_external) {
                billingGroupStats.free += bg.max_nb_seat - bg.occupied_seats;
            }
        });

        this.setState({ billingGroupStats, billingGroups });
    }

    async getRights() {
        const user = await Auth.getRights();
        this.setState({
            firstname: user.firstname,
            rights: {
                organizations: user.organizations,
                billingGroups: user.billingGroups,
                teams: user.teams,
            },
        });
    }

    async getAllData() {
        this.getOrganization();
        this.getBillingGroups();
        this.getRights();
    }

    componentDidMount() {
        this.getAllData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.organization_id !== this.props.match.params.organization_id) {
            this.getAllData();
        }
    }

    render() {
        const { t } = this.props;

        let totalLicenseCount = 0;
        if (this.state.billingGroupStats) {
            totalLicenseCount =
                this.state.billingGroupStats.internal +
                this.state.billingGroupStats.subcontractor +
                this.state.billingGroupStats.observator;
        }

        return (
            <div className="element-wrapper">
                {this.state.rights && this.state.organization && this.state.billingGroupStats && (
                    <div className="element-box">
                        <div className="form-header">
                            <h1>{t('Welcome-welcome')}</h1>
                        </div>

                        {this.state.rights.organizations.length > 0 && (
                            <div>
                                <p>
                                    <Trans i18nKey="Welcome-introSentenceOrganizationAdmin">
                                        Bonjour {this.state.firstname}, vous êtes <strong>administrateur</strong> de
                                        l'organisation <strong>{this.state.organization.title}</strong>.
                                    </Trans>
                                </p>
                                <div>
                                    <p>
                                        {t('Welcome-organizationAdmin-organizationAdminStats', {
                                            date: moment().format('LL'),
                                        })}{' '}
                                        <strong>
                                            {t('Welcome-organizationAdmin-organizationAdminStatsCount', {
                                                count: totalLicenseCount,
                                            })}
                                        </strong>
                                    </p>
                                    <ul>
                                        <li>
                                            {t('Welcome-organizationAdmin-stats-internal', {
                                                count: this.state.billingGroupStats.internal,
                                            })}
                                        </li>
                                        <li>
                                            {t('Welcome-organizationAdmin-stats-subcontractor', {
                                                count: this.state.billingGroupStats.subcontractor,
                                            })}
                                        </li>
                                        <li>
                                            {t('Welcome-organizationAdmin-stats-observator', {
                                                count: this.state.billingGroupStats.observator,
                                            })}
                                        </li>
                                    </ul>

                                    <p>
                                        {t('Welcome-organizationAdmin-stats-free', {
                                            count: this.state.billingGroupStats.free,
                                        })}
                                    </p>
                                </div>

                                <p>
                                    <Trans i18nKey="Welcome-organizationAdmin-toolExplanation">
                                        Cet espace d'administration vous permet de gérer vos{' '}
                                        <strong>licences Bulldozair</strong> ainsi que vos{' '}
                                        <strong>groupes de travail</strong> :
                                    </Trans>
                                </p>
                                <ul>
                                    <p>
                                        <li>
                                            <Trans i18nKey="Welcome-organizationAdmin-toolExplanation2">
                                                Les <strong>licences Bulldozair</strong> sont celles que vous avez
                                                achetées pour votre société. Vous avez la possibilité de les attribuer
                                                par groupe.
                                                <br /> Exemple: 200 licences pour Filiale 1, 150 licences pour Filiale
                                                2, 90 licences pour Filiale 3, etc...
                                            </Trans>
                                        </li>
                                    </p>
                                    <p>
                                        <li>
                                            <Trans i18nKey="Welcome-organizationAdmin-toolExplanation3">
                                                Les <strong>groupes de travail</strong> vous permettent de partager de
                                                l'information inter-projets avec vos collaborateurs internes et
                                                externes. <br /> Par exemple, vous pouvez partager vos modèles de
                                                formulaires et carnets de contacts.
                                                <br />
                                            </Trans>
                                        </li>
                                    </p>
                                </ul>

                                <Trans i18nKey="Welcome-organizationAdmin-toolExplanation4">
                                    <p>
                                        <strong>Remarque :</strong> les groupes de travail sont dissociés de la
                                        facturation. <br /> Pensez à attribuer des licences à vos collaborateurs avant
                                        de les inviter dans un groupe de travail.
                                    </p>
                                </Trans>
                            </div>
                        )}

                        {this.state.rights.organizations.length === 0 && this.state.rights.billingGroups.length > 0 && (
                            <div>
                                <p>
                                    {t('Welcome-introSentenceBillingGroupAdmin', {
                                        organizationTitle: this.state.organization.title,
                                    })}
                                </p>
                                <p>
                                    {t('Welcome-organizationAdmin-billingGroupAdminStats')}
                                    <ul>
                                        <li>
                                            {t('Welcome-organizationAdmin-stats-internal', {
                                                count: this.state.billingGroupStats.internal,
                                            })}
                                        </li>
                                        <li>
                                            {t('Welcome-organizationAdmin-stats-subcontractor', {
                                                count: this.state.billingGroupStats.subcontractor,
                                            })}
                                        </li>
                                        <li>
                                            {t('Welcome-organizationAdmin-stats-observator', {
                                                count: this.state.billingGroupStats.observator,
                                            })}
                                        </li>
                                        <li>
                                            {t('Welcome-organizationAdmin-stats-free', {
                                                count: this.state.billingGroupStats.free,
                                            })}
                                        </li>
                                    </ul>
                                </p>
                                <p>
                                    <Trans i18nKey="Welcome-organizationAdmin-toolExplanation22">
                                        Les <strong>licences Bulldozair</strong> sont celles qui ont été achetées pour
                                        votre société. Vous avez la possibilité d'attribuer ces licences à vos
                                        collaborateurs.
                                    </Trans>
                                </p>

                                <hr />
                            </div>
                        )}

                        {this.state.rights.organizations.length === 0 && this.state.rights.teams.length > 0 && (
                            <div>
                                <p>
                                    {t('Welcome-introSentenceTeamAdmin', {
                                        organizationTitle: this.state.organization.title,
                                    })}
                                </p>
                                <p>
                                    <Trans i18nKey="Welcome-organizationAdmin-toolExplanation32">
                                        Les <strong>groupes de travail</strong> vous permettent de partager de
                                        l'information inter-projets avec vos collaborateurs internes et externes. <br />{' '}
                                        Par exemple, vous pouvez partager vos modèles de formulaires et carnets de
                                        contacts.
                                        <br />
                                    </Trans>
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default translate('admin')(Welcome);
