import React, { Component } from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import Chart from 'chart.js';

import { nFormatter } from './nFormatter';

// set defaults
Chart.defaults.global.defaultFontFamily =
    '"Proxima Nova W01", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
Chart.defaults.global.tooltips.titleFontSize = 14;
Chart.defaults.global.tooltips.titleMarginBottom = 4;
Chart.defaults.global.tooltips.displayColors = false;
Chart.defaults.global.tooltips.bodyFontSize = 12;
Chart.defaults.global.tooltips.xPadding = 10;
Chart.defaults.global.tooltips.yPadding = 8;

class StatisticChart extends Component {
    constructor(props) {
        super(props);
        this.refreshChart = this.refreshChart.bind(this);
    }

    componentDidMount() {
        this.refreshChart();
    }

    componentDidUpdate(prevProps) {
        if (this.props.dates !== prevProps.dates) {
            this.refreshChart();
        }
    }

    refreshChart() {
        const { t, startDate, endDate } = this.props;
        const { lineChart } = this.refs;
        const rangeDurationInDays = Math.abs(
            moment(startDate, 'YYYY-MM-DD').diff(moment(endDate, 'YYYY-MM-DD'), 'days'),
        );

        // if the range is less or equal than 35 days, display in days
        const daysView = rangeDurationInDays <= 35;
        // if the range is more than 35 days and less than approx 8 months, display in weeks
        const weeksView = rangeDurationInDays > 35 && rangeDurationInDays <= 8 * 30;
        // else, display in months
        let dateFormatServer;
        let dateFormatDisplay;
        let prefix = '';
        if (daysView) {
            dateFormatServer = 'YYYY-MM-DD';
            dateFormatDisplay = 'Do MMM';
        } else if (weeksView) {
            dateFormatServer = 'YYYY-MM WW';
            dateFormatDisplay = 'll';
            prefix = '';
        } else {
            dateFormatServer = 'YYYY-MM';
            dateFormatDisplay = 'MMM YYYY';
        }

        if (this.myLineChart) {
            this.myLineChart.destroy();
        }

        const monthStrings = [];
        const monthValues = [];

        const { dates } = this.props;

        let min;
        let max;

        dates.forEach((date) => {
            const momentDate = moment(date.date, dateFormatServer);
            if (weeksView) {
                momentDate.week(date.date.substr(-2, 2));
            }
            const formattedDate = prefix + momentDate.format(dateFormatDisplay);
            monthStrings.push(formattedDate);
            monthValues.push(date.session_count);
            if (max === undefined || date.session_count > max) {
                max = date.session_count;
            }
            if (min === undefined || date.session_count < min) {
                min = date.session_count;
            }
        });

        // line chart data
        const lineData = {
            labels: monthStrings,
            datasets: [
                {
                    label: t('StatisticChart-session-count'),
                    fill: false,
                    lineTension: 0.3,
                    backgroundColor: '#fff',
                    borderColor: '#005378',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#fff',
                    pointBackgroundColor: '#141E41',
                    pointBorderWidth: 3,
                    pointHoverRadius: 10,
                    pointHoverBackgroundColor: '#FC2055',
                    pointHoverBorderColor: '#fff',
                    pointHoverBorderWidth: 3,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: monthValues,
                    spanGaps: false,
                },
            ],
        };

        // line chart init
        this.myLineChart = new Chart(lineChart, {
            type: 'line',
            data: lineData,
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                maxTicksLimit: 11,
                                maxRotation: 0,

                                fontSize: '10',
                                fontColor: '#969da5',
                            },
                            gridLines: {
                                color: 'rgba(0,0,0,0.05)',
                                zeroLineColor: 'rgba(0,0,0,0.05)',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            display: false,
                            ticks: {
                                beginAtZero: false,
                                min: 0,
                                max: max * 1.1,
                            },
                        },
                    ],
                },
            },
        });
    }

    render() {
        const { t, dates } = this.props;
        const currentUserCount = nFormatter(dates.reduce((a, b) => a + b.session_count, 0));

        return (
            <div>
                <div className="el-tablo bigger">
                    <div className="label">{t('StatisticChart-session-count')}</div>
                    <div className="value">{currentUserCount}</div>
                </div>
                <div className="el-chart-w">
                    <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand" />
                    </div>
                    <canvas
                        height="354"
                        id="lineChart"
                        ref="lineChart"
                        width="1422"
                        className="chartjs-render-monitor"
                        style={{ display: 'block', height: '177px', width: '711px' }}
                    />
                </div>
            </div>
        );
    }
}

export default translate('admin')(StatisticChart);
