import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { translate } from 'react-i18next';
import moment from 'moment';
import Select from 'react-select';
import { debounce } from 'debounce';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import './override-bootstrap-table.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react-dates-overrides.css';
import StatisticChart from './StatisticChart';
import Organization from '../../api/Organization';
import BillingGroup from '../../api/BillingGroup';
import Csv from '../../api/Csv';
import svgLoader from '../../../theme/img/loader.svg';

import StatisticLayout from './StatisticLayout';

const SORT_FIELD_LOCALSTORAGE_KEY = 'StatisticsUsersTableSortField';
const SORT_ORDER_LOCALSTORAGE_KEY = 'StatisticsUsersTableSortOrder';
const SIZE_PER_PAGE_FIELD_LOCALSTORAGE_KEY = 'StatisticsUsersTableSizePerPage';

const OLDEST_STAT_EVENT = '2020-03-01';

class EditBillingGroup extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {
            activeTab: '2',
            billingGroup: null,
            organization: null,
            showAllGroups: false,
            quickFilterRange: 'last-2-weeks',
            startDate: moment().subtract(15, 'days'),
            endDate: moment().subtract(1, 'days'),
            searchValue: null,
            defaultTableOrder: this.getDefaultTableOrdering(),
            defaultPageSize: this.getDefaultPageSize(),
        };
        this.toggle = this.toggle.bind(this);
        this.getOrganization = this.getOrganization.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.refreshUsersAndStats = this.refreshUsersAndStats.bind(this);
        this.refreshUsersAndStatsWithDebounce = debounce(this.refreshUsersAndStats, 100);
        this.onFocusChangeDatePicker = this.onFocusChangeDatePicker.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this);
        this.onChangeSelectBillingGroup = this.onChangeSelectBillingGroup.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.onChangeSelectQuickFilterRange = this.onChangeSelectQuickFilterRange.bind(this);
        this.selectAllBillingGroups = this.selectAllBillingGroups.bind(this);
    }

    async componentDidMount() {
        await this.getOrganization();
        await this.getBillingGroups();
        await this.refreshUsersAndStats();
    }

    exportToCsv() {
        const { t } = this.props;
        const { users } = this.state;
        const headers = {
            billing_group_title: t('StatisticUsers-licenceGroup'),
            firstname: t('StatisticUsers-firstname'),
            lastname: t('StatisticUsers-lastname'),
            email: t('StatisticUsers-email'),
            created_at: t('StatisticUsers-subscribeDate'),
            last_seen: t('StatisticUsers-lastSeen'),
            session_count: t('StatisticUsers-sessionCount'),
        };
        const headersOrder = [
            'billing_group_title',
            'firstname',
            'lastname',
            'email',
            'created_at',
            'last_seen',
            'session_count',
        ];
        const rows = users.map((user) => ({
            ...user,
            created_at: moment(user.created_at).format('L'),
            last_seen: user.last_seen ? moment(user.last_seen).format('L') : t('StatisticUsers-lastSeenNever'),
        }));
        const fileName = `${t('StatisticUsers-csvExportFilename')}_${moment().format('L')}`;
        Csv.exportCSVFile(headers, headersOrder, rows, fileName);
    }

    isOutsideRange(day) {
        // stats are available only since the 2nd of february
        return moment(day).isAfter(moment()) || moment(day).isBefore(moment(OLDEST_STAT_EVENT));
    }

    getDefaultTableOrdering() {
        const sort = {
            dataField: 'last_seen',
            order: 'desc', // desc or asc
        };

        if (localStorage) {
            const field = localStorage.getItem(SORT_FIELD_LOCALSTORAGE_KEY);
            const order = localStorage.getItem(SORT_ORDER_LOCALSTORAGE_KEY);
            if (field) {
                sort.dataField = field;
            }
            if (order) {
                sort.order = order;
            }
        }
        return sort;
    }

    getDefaultPageSize() {
        if (localStorage) {
            const sizePerPageLocalStorage = localStorage.getItem(SIZE_PER_PAGE_FIELD_LOCALSTORAGE_KEY);
            if (sizePerPageLocalStorage && Number.isInteger(parseInt(sizePerPageLocalStorage, 10))) {
                return parseInt(sizePerPageLocalStorage, 10);
            }
        }
        // default value is 50
        return 50;
    }

    async onChangeSelectQuickFilterRange(e) {
        const filter = e.target.value;
        let startDate;
        let endDate = moment().subtract(1, 'days');
        switch (filter) {
            case 'last-2-weeks':
                startDate = moment().subtract(15, 'days');
                break;
            case 'last-week':
                startDate = moment().subtract(8, 'days');
                break;
            case 'last-month':
                startDate = moment().subtract(31, 'days');
                break;
            case 'last-year':
                startDate = moment()
                    .subtract(1, 'years')
                    .subtract(1, 'days');
                break;
            case 'all':
            default:
                startDate = moment(OLDEST_STAT_EVENT);
        }
        if (startDate.isBefore(moment(OLDEST_STAT_EVENT))) {
            startDate = moment(OLDEST_STAT_EVENT);
        }
        await this.setState({ startDate, endDate, quickFilterRange: filter });
        this.refreshUsersAndStatsWithDebounce();
    }

    async onDatesChange({ startDate, endDate }) {
        await this.setState({ startDate, endDate, quickFilterRange: '--' });
        this.refreshUsersAndStatsWithDebounce();
    }

    async onSearchChange(e) {
        const newSearchValue = e.target.value.length ? e.target.value : null;
        await this.setState({ searchValue: newSearchValue });
        this.refreshUsersAndStatsWithDebounce();
    }

    async onChangeSelectBillingGroup(selection) {
        await this.setState({ selectedBillingGroups: selection });
        await this.refreshUsersAndStats();
    }

    async selectAllBillingGroups() {
        this.onChangeSelectBillingGroup(
            this.state.showAllGroups ? this.state.billingGroupsOptions : this.state.billingGroupsNotDeletedOptions,
        );
    }

    async toggleDeletedBillingGroups() {
        await this.setState({ showAllGroups: !this.state.showAllGroups });
        if (this.state.showAllGroups === false && this.state.selectedBillingGroups) {
            await this.setState({
                selectedBillingGroups: this.state.selectedBillingGroups.filter((selected) =>
                    this.state.billingGroupsNotDeletedOptions.find((elem) => elem.value === selected.value),
                ),
            });
        }
        await this.refreshUsersAndStats();
    }

    onFocusChangeDatePicker(datePickerFocusedInput) {
        this.setState({ datePickerFocusedInput });
    }

    async getBillingGroups() {
        const { t } = this.props;
        const { organization } = this.state;
        if (organization) {
            const billingGroups = await BillingGroup.getIncludeDeleted(organization.id);
            const billingGroupsNotDeletedOptions = [];
            const billingGroupsOptions = billingGroups.map((billingGroup) => {
                const billingGroupsOption = {
                    label: billingGroup.is_deleted
                        ? billingGroup.title + ' (' + t('Statistics-is-deleted') + ')'
                        : billingGroup.title,
                    value: billingGroup.id,
                };
                if (!billingGroup.is_deleted) {
                    billingGroupsNotDeletedOptions.push(billingGroupsOption);
                }
                return billingGroupsOption;
            });
            this.setState({ billingGroupsOptions, billingGroupsNotDeletedOptions });
        }
    }

    async getOrganization() {
        const organization = await Organization.getById(this.props.match.params.organization_id);
        await this.setState({ organization });
    }

    onSort(field, order) {
        if (localStorage) {
            localStorage.setItem(SORT_FIELD_LOCALSTORAGE_KEY, field);
            localStorage.setItem(SORT_ORDER_LOCALSTORAGE_KEY, order);
        }
    }

    onSizePerPageChange(sizePerPage) {
        if (localStorage) {
            localStorage.setItem(SIZE_PER_PAGE_FIELD_LOCALSTORAGE_KEY, sizePerPage);
        }
    }

    getColumns() {
        const { t } = this.props;
        const columns = [
            {
                dataField: 'billing_group_title',
                text: t('StatisticUsers-licenceGroup'),
                sort: true,
                onSort: this.onSort,
            },
            {
                dataField: 'firstname',
                text: t('StatisticUsers-firstname'),
                sort: true,
                onSort: this.onSort,
            },
            {
                dataField: 'lastname',
                text: t('StatisticUsers-lastname'),
                sort: true,
                onSort: this.onSort,
            },
            {
                dataField: 'email',
                text: t('StatisticUsers-email'),
                sort: true,
                onSort: this.onSort,
            },
            {
                dataField: 'created_at',
                text: t('StatisticUsers-subscribeDate'),
                sort: true,
                onSort: this.onSort,
                formatter: (cell) => {
                    return moment(cell).format('ll');
                },
            },
            {
                dataField: 'last_seen',
                text: t('StatisticUsers-lastSeen'),
                sort: true,
                onSort: this.onSort,
                formatter: (cell) => {
                    if (!cell) {
                        return t('StatisticUsers-lastSeenNever');
                    }
                    const cellDate = moment(cell);
                    // to avoid bug when server is ahead of user time
                    if (cellDate > moment()) {
                        return moment().fromNow();
                    }
                    return cellDate.fromNow();
                },
            },
            {
                dataField: 'session_count',
                text: t('StatisticUsers-sessionCount'),
                sort: true,
                onSort: this.onSort,
            },
        ];

        return columns;
    }

    toggle(newTab) {
        this.setState({ activeTab: newTab });
    }

    print() {
        window.print();
    }

    async refreshUsersAndStats() {
        const {
            startDate,
            endDate,
            organization,
            searchValue,
            selectedBillingGroups,
            showAllGroups,
            billingGroupsNotDeletedOptions,
        } = this.state;
        let billingGroupIds;
        if (!showAllGroups && !selectedBillingGroups) {
            billingGroupIds = billingGroupsNotDeletedOptions
                ? billingGroupsNotDeletedOptions.map((option) => option.value).join(',')
                : null;
        } else {
            billingGroupIds = selectedBillingGroups
                ? selectedBillingGroups.map((option) => option.value).join(',')
                : null;
        }
        if (startDate && endDate) {
            const params = {
                from: startDate.format('YYYY-MM-DD'),
                to: endDate.format('YYYY-MM-DD'),
                search: searchValue,
                billing_group_ids: billingGroupIds,
            };
            const [users, sessionsCount] = await Promise.all([
                Organization.getUsersWithStatistics(organization.id, params),
                Organization.getSessionsCount(organization.id, params),
            ]);
            await this.setState({ users, sessionsCount });
        }
    }

    render() {
        const { t } = this.props;
        const {
            organization,
            users,
            sessionsCount,
            startDate,
            endDate,
            datePickerFocusedInput,
            searchValue,
            billingGroupsOptions,
            selectedBillingGroups,
            defaultTableOrder,
            quickFilterRange,
            defaultPageSize,
            billingGroupsNotDeletedOptions,
            showAllGroups,
        } = this.state;

        return (
            <StatisticLayout activeTab="user" organization={organization}>
                <p>{t('StatisticUsers-description')}</p>
                <div>
                    <div className="row mb-3">
                        <small
                            className="col-md-10"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => this.toggleDeletedBillingGroups()}
                        >
                            {!this.state.showAllGroups
                                ? t('Statistics-showDeletedGroups')
                                : t('Statistics-hideDeletedGroups')}
                        </small>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-10">
                            <Select
                                styles={{
                                    control: (base, state) => {
                                        // base.height = '34px';
                                        base['min-height'] = '34px';
                                        return base;
                                    },
                                    container: (base) => {
                                        base.zIndex = 9;
                                        return base;
                                    },
                                }}
                                isClearable
                                isMulti
                                closeMenuOnSelect={false}
                                options={showAllGroups ? billingGroupsOptions : billingGroupsNotDeletedOptions}
                                value={selectedBillingGroups}
                                onChange={this.onChangeSelectBillingGroup}
                                placeholder={t('StatisticUsers-billingGroupSelectPlaceholder')}
                                noOptionsMessage={() => t('Statistics-noBillingGroups')}
                            />
                        </div>
                        <div className="col-md-2">
                            <Button outline color="primary" block onClick={this.selectAllBillingGroups}>
                                {t('StatisticUsers-SelectAllButton')}
                            </Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <select
                                className="form-control"
                                onChange={this.onChangeSelectQuickFilterRange}
                                value={quickFilterRange}
                            >
                                <option value="--">{t('StatisticUsers-dash')}</option>
                                <option value="last-2-weeks">{t('StatisticUsers-last15Days')}</option>
                                <option value="last-week">{t('StatisticUsers-lastWeek')}</option>
                                <option value="last-month">{t('StatisticUsers-lastMonth')}</option>
                                <option value="all">{t('StatisticUsers-allTime')}</option>
                            </select>
                        </div>
                        <div className="col-md-8">
                            <div className="d-flex justify-content-center">
                                <div class="form-group" style={{ minWidth: '220px' }}>
                                    <DateRangePicker
                                        hideKeyboardShortcutsPanel
                                        small
                                        startDate={startDate} // momentPropTypes.momentObj or null,
                                        startDateId="start_date_id" // PropTypes.string.isRequired,
                                        endDate={endDate} // momentPropTypes.momentObj or null,
                                        endDateId="end_date_id" // PropTypes.string.isRequired,
                                        onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
                                        focusedInput={datePickerFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={this.onFocusChangeDatePicker} // PropTypes.func.isRequired,
                                        isOutsideRange={this.isOutsideRange}
                                        styles={{
                                            zIndex: 10,
                                        }}
                                        minDate={moment(OLDEST_STAT_EVENT)}
                                        maxDate={moment()}
                                    />
                                </div>

                                <input
                                    className="form-control"
                                    style={{
                                        height: '34px',
                                    }}
                                    placeholder={t('StatisticUsers-searchUserPlaceholder')}
                                    onChange={this.onSearchChange}
                                    value={searchValue || ''}
                                    type="text"
                                />

                                <div className="ml-auto pl-4">
                                    <button
                                        style={{
                                            height: '34px',
                                        }}
                                        className="btn btn-primary"
                                        onClick={this.print}
                                    >
                                        <i className="os-icon os-icon-edit-3" />
                                        {t('Statistic-printButton')}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div>
                                <Button
                                    block
                                    style={{
                                        height: '34px',
                                    }}
                                    onClick={this.exportToCsv}
                                >
                                    <i class="os-icon os-icon-documents-03" />
                                    {t('StatisticUsers-csvExportButton')}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <hr />
                    {!sessionsCount && !users && (
                        <div className="d-flex justify-content-center" style={{ height: '400px' }}>
                            <img src={svgLoader} alt="loader" />
                        </div>
                    )}
                    {sessionsCount && <StatisticChart dates={sessionsCount} startDate={startDate} endDate={endDate} />}
                    <hr />
                    {users && users.length > 0 && (
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            bordered={false}
                            data={users}
                            columns={this.getColumns()}
                            defaultSorted={[defaultTableOrder]}
                            pagination={paginationFactory({
                                onSizePerPageChange: this.onSizePerPageChange,
                                sizePerPage: defaultPageSize,
                                sizePerPageList: [
                                    {
                                        text: '50',
                                        value: 50,
                                    },
                                    {
                                        text: '100',
                                        value: 100,
                                    },
                                    {
                                        text: '200',
                                        value: 200,
                                    },
                                ],
                            })}
                            wrapperClasses="table-responsive table-striped table-lightborder"
                        />
                    )}
                    {users && users.length === 0 && (
                        <div className="d-flex justify-content-center" style={{ marginTop: '30px' }}>
                            <p>{t('StatisticUsers-emptyTable')}</p>
                        </div>
                    )}
                </div>
            </StatisticLayout>
        );
    }
}

export default translate('admin')(EditBillingGroup);
