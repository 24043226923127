import React, { Component } from 'react';
import update from 'immutability-helper';
import Promise from 'bluebird';
import {
    Alert,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
} from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import NotificationSystem from 'react-notification-system';
import Select from 'react-select';
import get from 'get-value';
import BillingGroupUser from './BillingGroupUser';
import Auth from '../../../app/Auth';
import Organization from '../../api/Organization.js';
import BillingGroup from '../../api/BillingGroup.js';
import BillingGroupExportApiDatasets from './BillingGroupExportApiDatasets';

class EditBillingGroup extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {
            activeTab: '1',
            billingGroup: null,
            deleteConfirmationModalOpened: false,
            billingGroupAdmins: [],
            organization: null,
            users: [],
            isOrgaAdmin: false,
        };
        this.toggle = this.toggle.bind(this);
        this.updateBillingGroupFields = this.updateBillingGroupFields.bind(this);
        this.editBillingGroup = this.editBillingGroup.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.deleteBillingGroup = this.deleteBillingGroup.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.handleChangeSelectAdmin = this.handleChangeSelectAdmin.bind(this);
        this.saveBillingGroupAdmin = this.saveBillingGroupAdmin.bind(this);
        this.getBillingGroupData = this.getBillingGroupData.bind(this);
        this.updateBillingGroupInviteWithSSO = this.updateBillingGroupInviteWithSSO.bind(this);
        this.getUsersInBillingGroup = this.getUsersInBillingGroup.bind(this);
        this.getRights = this.getRights.bind(this);
        this.getBillingGroupLicenseTranslation = this.getBillingGroupLicenseTranslation.bind(this);
    }

    toggle(newTab) {
        this.setState({ activeTab: newTab });
    }

    toggleModal() {
        this.setState({
            deleteConfirmationModalOpened: !this.state.deleteConfirmationModalOpened,
        });
    }

    async getRights() {
        const rights = await Auth.getRights();
        const isOrgaAdmin =
            rights.organizations.find((o) => o.id === this.props.match.params.organization_id) !== undefined;
        this.setState({ isOrgaAdmin });
    }

    async getBillingGroupData() {
        const [billingGroup, organization] = await Promise.all([
            BillingGroup.getById(this.props.match.params.billing_group_id),
            Organization.getById(this.props.match.params.organization_id),
        ]);
        this.setState({ billingGroup, organization });
        this.getUsersInBillingGroup();
        await this.getRights();
        if (this.state.isOrgaAdmin) {
            this.getOptions();
        }
    }

    async refreshBillingGroup() {
        const billingGroup = await BillingGroup.getById(this.props.match.params.billing_group_id);
        this.setState({ billingGroup });
    }

    componentDidUpdate(prevProps) {
        const billingGroupChanged =
            this.props.match.params.billing_group_id !== prevProps.match.params.billing_group_id;
        const organizationChanged = this.props.match.params.organization_id !== prevProps.match.params.organization_id;
        if (billingGroupChanged || organizationChanged) {
            this.getBillingGroupData();
        }
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        this.getBillingGroupData();
    }

    updateBillingGroupFields(field, value) {
        if (field === 'max_nb_seat') {
            value = parseInt(value);
        }

        const newState = update(this.state, {
            billingGroup: { [field]: { $set: value } },
        });
        this.setState(newState);
    }

    updateBillingGroupInviteWithSSO(event) {
        const newValue = event.target.value === 'yes';
        const newState = update(this.state, {
            billingGroup: { invite_user_with_sso: { $set: newValue } },
        });
        this.setState(newState);
    }

    deleteBillingGroup() {
        BillingGroup.update(this.state.billingGroup.id, { is_deleted: true })
            .then((newBillingGroup) => {
                this.props.history.replace(`/${this.props.match.params.organization_id}/billing-group`);
            })
            .catch((err) => {});
    }

    editBillingGroup() {
        BillingGroup.update(this.state.billingGroup.id, this.state.billingGroup)
            .then((newBillingGroup) => {
                this.setState({ notEnoughSeatAvailable: false });
                this._notificationSystem.addNotification({
                    message: this.props.t('BillingGroup-saved-success'),
                    level: 'success',
                });
            })
            .catch((err) => {
                this.refreshBillingGroup();
                if (err.response && err.response.status === 402) {
                    this.setState({
                        notEnoughSeatAvailable: true,
                        nbRemainingSeats: err.response.data.meta.customData.remaining_seats,
                    });
                } else if (
                    err.response &&
                    err.response.status === 403 &&
                    get(err, 'response.data.meta.error_type') === 'TOO_LITTLE_SEATS_NUMBER'
                ) {
                    this._notificationSystem.addNotification({
                        message: this.props.t('BillingGroup-saved-error-too-little-seats-number'),
                        level: 'error',
                    });
                }
            });
    }

    async getUsersInBillingGroup() {
        const users = await BillingGroup.getUsers(this.props.match.params.billing_group_id, 100000, 0);
        this.setState({ users });
    }

    async getOptions() {
        const { match } = this.props;
        const [usersInOrganization, currentAdminsOfBillingGroup] = await Promise.all([
            Organization.getAllUsers(match.params.organization_id),
            BillingGroup.getAdmins(match.params.billing_group_id),
        ]);
        const setOfAdminsInBillingGroup = new Set(currentAdminsOfBillingGroup.map((admin) => admin.id));
        const setOfUsersInAllBillingGroups = new Set(usersInOrganization.map((user) => user.id));

        // we build a list of users that are either in the organization
        // either, billing group admin (as someone can be added as admin, then removed from billing group)
        const allUsers = [...usersInOrganization];
        currentAdminsOfBillingGroup.forEach((user) => {
            if (!setOfUsersInAllBillingGroups.has(user.id)) {
                allUsers.push(user);
            }
        });
        const options = [];
        const billingGroupAdmins = [];
        // we build the list of users in organization
        allUsers.forEach((user) => {
            const option = {
                value: user.id,
                label: user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : user.email,
            };
            options.push(option);
            // if the user is admin
            if (setOfAdminsInBillingGroup.has(user.id)) {
                billingGroupAdmins.push(option);
            }
        });
        await this.setState({ usersOptions: options, allUsers, billingGroupAdmins, currentAdminsOfBillingGroup });
    }

    handleChangeSelectAdmin(params) {
        this.setState({ billingGroupAdmins: params ? params : [] });
    }

    async saveBillingGroupAdmin() {
        const { currentAdminsOfBillingGroup, billingGroupAdmins } = this.state;

        // remove old admins
        await Promise.each(currentAdminsOfBillingGroup, async (currentAdmin) => {
            if (!billingGroupAdmins.find((billingGroupAdmin) => billingGroupAdmin.value === currentAdmin.id)) {
                await BillingGroup.revokeAdmin(this.props.match.params.billing_group_id, currentAdmin.id);
            }
        });
        // add new admins
        await Promise.each(billingGroupAdmins, async (newAdmin) => {
            if (!currentAdminsOfBillingGroup.find((currentAdmin) => newAdmin.value === currentAdmin.id)) {
                await BillingGroup.makeAdmin(this.props.match.params.billing_group_id, newAdmin.value);
            }
        });
        this._notificationSystem.addNotification({
            message: this.props.t('BillingGroup-saved-success'),
            level: 'success',
        });
    }

    getBillingGroupLicenseTranslation() {
        const { t } = this.props;
        const { billingGroup } = this.state;

        if (billingGroup.license === 'internal') {
            return t('BillingGroupList-license-internal');
        }
        if (billingGroup.license === 'subcontractor') {
            return t('BillingGroupList-license-subcontractor');
        }
        if (billingGroup.license === 'observator') {
            return t('BillingGroupList-license-observator');
        }
    }

    render() {
        const { t } = this.props;
        const { usersOptions, billingGroupAdmins, isOrgaAdmin } = this.state;

        const isDefaultBillingGroup =
            get(this.state, 'billingGroup.is_default_external') || get(this.state, 'billingGroup.is_default_internal');

        const isSsoEnabled = get(this.state, 'organization.is_sso_enabled');

        return (
            <div className="element-wrapper">
                <h6 className="element-header">
                    {this.state.organization && this.state.organization.title} / {t('BillingGroupList-title')} /{' '}
                    {this.state.billingGroup && this.state.billingGroup.title}
                </h6>
                <div className="element-box">
                    <div className="os-tabs-w">
                        <div className="os-tabs-controls tabs-control-custom">
                            <Nav tabs className="small">
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: this.state.activeTab === '1',
                                        })}
                                        onClick={() => {
                                            this.toggle('1');
                                        }}
                                    >
                                        {t('BillingGroup-users-tab')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: this.state.activeTab === '2',
                                        })}
                                        onClick={() => {
                                            this.toggle('2');
                                        }}
                                    >
                                        {t('BillingGroup-exportApiDatasets-tab')}
                                    </NavLink>
                                </NavItem>
                                {this.state.isOrgaAdmin &&
                                this.state.billingGroup &&
                                this.state.billingGroup.billing_type === 'bulldozair' ? (
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active: this.state.activeTab === '3',
                                            })}
                                            onClick={() => {
                                                this.toggle('3');
                                            }}
                                        >
                                            {t('BillingGroup-parameters-tab')}
                                        </NavLink>
                                    </NavItem>
                                ) : (
                                    ''
                                )}
                            </Nav>
                        </div>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                {this.state.billingGroup && (
                                    <BillingGroupUser
                                        getBillingGroupData={this.getBillingGroupData}
                                        billingGroup={this.state.billingGroup}
                                        notificationSystem={this._notificationSystem}
                                        toggle={this.toggle}
                                        isDefaultBillingGroup={isDefaultBillingGroup}
                                    />
                                )}
                            </TabPane>
                            <TabPane tabId="2">
                                {this.state.billingGroup && (
                                    <BillingGroupExportApiDatasets
                                        billingGroup={this.state.billingGroup}
                                        notificationSystem={this._notificationSystem}
                                    />
                                )}
                            </TabPane>
                            <TabPane tabId="3">
                                {this.state.billingGroup && (
                                    <div>
                                        <div>
                                            <h5 className="form-header">{t('BillingGroup-parameters-title')}</h5>
                                            <div className="form-desc">{t('BillingGroup-parameters-sentence')}</div>
                                            {this.state.billingGroup.is_default_internal && (
                                                <div>
                                                    <p>{t('BillingGroup-parameters-default-internal-billing-group')}</p>
                                                </div>
                                            )}
                                            {this.state.billingGroup.is_default_external && (
                                                <div>
                                                    <p>{t('BillingGroup-parameters-default-external-billing-group')}</p>
                                                </div>
                                            )}
                                            {this.state.notEnoughSeatAvailable && (
                                                <Alert color="danger">
                                                    {t('BillingGroupModal-warningTooManySeats', {
                                                        count: this.state.nbRemainingSeats,
                                                        licenseType: this.getBillingGroupLicenseTranslation(),
                                                    })}
                                                    <a href="mailto:sales@bulldozair.com">sales@bulldozair.com</a>.
                                                </Alert>
                                            )}

                                            {this.state.billingGroup.max_nb_seat < 0 && (
                                                <Alert color="danger">
                                                    {t('BillingGroupModal-warningSeatPositiveOrNull')}
                                                </Alert>
                                            )}
                                            <div className="form-group">
                                                <label> {t('BillingGroup-parameters-name')}</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Name"
                                                    type="text"
                                                    value={this.state.billingGroup.title}
                                                    disabled={isDefaultBillingGroup}
                                                    onChange={(e) =>
                                                        this.updateBillingGroupFields('title', e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label> {t('BillingGroup-select-license-type')}</label>
                                                <select
                                                    className="form-control"
                                                    value={this.state.billingGroup.license}
                                                    disabled
                                                    onChange={(e) =>
                                                        this.updateBillingGroupFields('license', e.target.value)
                                                    }
                                                >
                                                    <option value="internal">
                                                        {t('BillingGroup-internal-license')}
                                                    </option>
                                                    <option value="subcontractor">
                                                        {t('BillingGroup-subcontractor-license')}
                                                    </option>
                                                    <option value="observator">
                                                        {t('BillingGroup-observator-license')}
                                                    </option>
                                                </select>
                                            </div>
                                            {!isDefaultBillingGroup && (
                                                <div className="form-group">
                                                    <label> {t('BillingGroupModal-licenseField')}</label>
                                                    <Input
                                                        type="number"
                                                        name="max_nb_seat"
                                                        id="maxNbSeat"
                                                        placeholder={t('BillingGroupModal-licenseField')}
                                                        value={this.state.billingGroup.max_nb_seat}
                                                        disabled={!isOrgaAdmin || isDefaultBillingGroup}
                                                        onChange={(e) =>
                                                            this.updateBillingGroupFields('max_nb_seat', e.target.value)
                                                        }
                                                    />
                                                </div>
                                            )}

                                            {isSsoEnabled && (
                                                <div className="form-group">
                                                    <label> {t('BillingGroupModal-inviteUserWithSSO')}</label>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                type="radio"
                                                                name="invite-user-sso"
                                                                value="yes"
                                                                checked={this.state.billingGroup.invite_user_with_sso}
                                                                onChange={this.updateBillingGroupInviteWithSSO}
                                                            />{' '}
                                                            {t('BillingGroupModal-inviteUserSSOYes')}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                type="radio"
                                                                name="invite-user-sso"
                                                                value="no"
                                                                checked={!this.state.billingGroup.invite_user_with_sso}
                                                                onChange={this.updateBillingGroupInviteWithSSO}
                                                            />{' '}
                                                            {t('BillingGroupModal-inviteUserSSONo')}
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            )}

                                            <div className="form-buttons-w">
                                                <Button color="primary" onClick={this.editBillingGroup}>
                                                    {t('common-save')}
                                                </Button>
                                            </div>

                                            <fieldset className="form-group">
                                                <legend>
                                                    <span>{t('BillingGroup-parameters-admin-title')}</span>
                                                </legend>

                                                <p>{t('BillingGroup-parameters-admin-sentence')}</p>

                                                <Select
                                                    name="form-field-name"
                                                    value={billingGroupAdmins}
                                                    isMulti
                                                    onChange={this.handleChangeSelectAdmin}
                                                    options={usersOptions}
                                                    styles={{
                                                        container: (base, state) => {
                                                            base.zIndex = '3';
                                                            return base;
                                                        },
                                                    }}
                                                />

                                                <div className="form-buttons-w">
                                                    <Button color="primary" onClick={this.saveBillingGroupAdmin}>
                                                        {t('common-save')}
                                                    </Button>
                                                </div>
                                            </fieldset>

                                            <fieldset className="form-group">
                                                <legend>
                                                    <span style={{ zIndex: 1 }}>
                                                        {t('BillingGroup-parameters-danger-zone-title')}
                                                    </span>
                                                </legend>
                                                <Row>
                                                    <Col md={12}>
                                                        {isDefaultBillingGroup &&
                                                            t(
                                                                'BillingGroup-parameters-default-billing-group-are-not-deletable',
                                                            )}
                                                        {!isDefaultBillingGroup &&
                                                            t('BillingGroup-parameters-danger-zone-sentence')}
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '20px' }}>
                                                    <Col md={4}>
                                                        <button
                                                            className="btn btn-danger"
                                                            disabled={isDefaultBillingGroup}
                                                            onClick={this.toggleModal}
                                                        >
                                                            {' '}
                                                            {t('common-delete')}
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <Modal
                                                isOpen={this.state.deleteConfirmationModalOpened}
                                                toggle={this.toggleModal}
                                                className={this.props.className}
                                            >
                                                <ModalHeader toggle={this.toggleModal}>
                                                    {t('BillingGroup-parameters-delete-modal-title')}
                                                </ModalHeader>
                                                <ModalBody>
                                                    {isDefaultBillingGroup &&
                                                        t(
                                                            'BillingGroup-parameters-default-billing-group-are-not-deletable',
                                                        )}
                                                    {!isDefaultBillingGroup &&
                                                        t('BillingGroup-parameters-delete-modal-sentence')}
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button
                                                        color="danger"
                                                        disabled={isDefaultBillingGroup}
                                                        onClick={this.deleteBillingGroup}
                                                    >
                                                        {t('common-delete')}
                                                    </Button>{' '}
                                                    <Button color="secondary" onClick={this.toggleModal}>
                                                        {t('common-cancel')}
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>
                                    </div>
                                )}
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default translate('admin')(EditBillingGroup);
