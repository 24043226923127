import React, { Component } from 'react';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { translate } from 'react-i18next';

class StatisticLayout extends Component {
    render() {
        const { t, activeTab, children, organization } = this.props;
        const currentOrganizationId = this.props.match.params.organization_id;
        const organizationTitle = organization ? organization.title : '';
        return (
            <div className="element-wrapper">
                <h6 className="element-header">
                    {organizationTitle} / {t('EnterpriseApi-title')} / {t(`EnterpriseApi-title-${activeTab}`)}
                </h6>

                <div className="element-box">
                    <div className="os-tabs-w">
                        <div className="os-tabs-controls" style={{ marginBottom: '1rem' }}>
                            <Nav tabs className="small">
                                <NavItem>
                                    <Link
                                        className={classnames('nav-link', {
                                            active: activeTab === 'welcome',
                                        })}
                                        to={`/${currentOrganizationId}/enterprise-api/welcome`}
                                    >
                                        {t('EnterpriseApi-welcome-tab')}
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={classnames('nav-link', {
                                            active: activeTab === 'documentation',
                                        })}
                                        to={`/${currentOrganizationId}/enterprise-api/documentation`}
                                    >
                                        {t('EnterpriseApi-documentation-tab')}
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={classnames('nav-link', {
                                            active: activeTab === 'api-keys',
                                        })}
                                        to={`/${currentOrganizationId}/enterprise-api/keys`}
                                    >
                                        {t('EnterpriseApi-api-keys-tab')}
                                    </Link>
                                </NavItem>
                            </Nav>
                        </div>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={activeTab}>{children}</TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(translate('admin')(StatisticLayout));
