import React, { Component } from 'react';
import update from 'immutability-helper';
import get from 'get-value';
import { Row, Col, Button, FormGroup, Input, Table, Alert } from 'reactstrap';
import Infinite from 'react-infinite';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import checkSquare from '../../../theme/img/check-square.svg';
import square from '../../../theme/img/square.svg';
import minusSquare from '../../../theme/img/minus-square.svg';
import BillingGroup from '../../api/BillingGroup.js';
import Auth from '../../../app/Auth';
import threeDotsImg from '../../../theme/img/three-dots.svg';

const USER_SELECTED_STATES = {
    NONE: 'none',
    ALL: 'all',
    PARTIAL: 'partial',
};

const Spinner = () => (
    <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

class BillingGroupUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            selectUsersMode: true,
            usersToMove: {},
            moveLoading: false,
            usersSelectedState: USER_SELECTED_STATES.NONE,
            elements: this.buildElements([], false, {}),
            newEmails: '',
            isInfiniteLoading: false,
            infiniteLoadBeginEdgeOffset: 200,
            saving: false,
            resendInvitationStatus: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.addUsers = this.addUsers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this);
        this.resendInvitationEmail = this.resendInvitationEmail.bind(this);
        this.buildElements = this.buildElements.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.selectAllUsers = this.selectAllUsers.bind(this);
        this.unSelectAllUsers = this.unSelectAllUsers.bind(this);
        this.toggleSelectUsersMode = this.toggleSelectUsersMode.bind(this);
        this.moveUsers = this.moveUsers.bind(this);
        this.selectBillingGroup = this.selectBillingGroup.bind(this);
    }

    async getBillingGroups() {
        const currentOrganizationId = this.props.match.params.organization_id;
        const billingGroups = await BillingGroup.get(currentOrganizationId);
        const billingGroupsForMoveUsers = billingGroups.filter(
            (bg) =>
                bg.id !== this.props.billingGroup.id &&
                bg.is_default_internal !== true &&
                bg.is_default_external !== true,
        );
        const newState = {
            billingGroups,
            billingGroupsForMoveUsers,
        };
        if (billingGroupsForMoveUsers.length > 0) {
            newState.currentlySelectedBillingGroup = billingGroupsForMoveUsers[0].id;
        }
        this.setState(newState);
        const rights = await Auth.getRights();
        this.setState({ isOrgaAdmin: rights.organizations.length });
    }

    addUsers() {
        const { t } = this.props;
        this.setState({ saving: true });
        const emailsUnfiltered = this.state.newEmails.split('\n');
        const emails = [];

        // remove useless empty rows, keep only valid emails
        for (let i = 0; i < emailsUnfiltered.length; i++) {
            if (this._validateEmail(emailsUnfiltered[i])) {
                emails.push(emailsUnfiltered[i]);
            }
        }

        return BillingGroup.addUsers(this.props.billingGroup.id, emails)
            .then((results) => {
                let failedUsers = '';

                results.errors.forEach((err) => {
                    this.props.notificationSystem.addNotification({
                        message: t('EditBillingGroup-errorAddingUser', { email: err.userEmail }),
                        level: 'error',
                    });
                    failedUsers += `${err.userEmail}\n`;
                });

                const newState = update(this.state, {
                    saving: { $set: false },
                    users: { $push: results.results },
                    elements: {
                        $push: this.buildElements(results.results, this.state.selectUsersMode, this.state.usersToMove),
                    },
                    newEmails: { $set: failedUsers },
                });

                this.setState(newState);
                this.props.getBillingGroupData(this.props.billingGroup.id);
            })
            .catch((err) => {
                this.setState({ saving: false });
                if (err && err.response && err.response.status === 402) {
                    this.props.notificationSystem.addNotification({
                        message: this.props.t('error-notEnoughSeat'),
                        level: 'error',
                    });
                }
            });
    }

    _validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    deleteUser(userId) {
        return BillingGroup.removeUser(this.props.billingGroup.id, userId)
            .then(() => {
                const userIndex = this.state.users.findIndex((user) => user.id === userId);
                if (userIndex !== -1) {
                    const newState = update(this.state, {
                        users: { $splice: [[userIndex, 1]] },
                        elements: { $splice: [[userIndex, 1]] },
                    });

                    this.setState(newState);
                }
                this.props.getBillingGroupData(this.props.billingGroup.id);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    async resendInvitationEmail(userId) {
        try {
            await this.setState(
                update(this.state, {
                    elements: {
                        $set: this.buildElements(this.state.users, this.state.selectUsersMode, this.state.usersToMove),
                    },
                    resendInvitationStatus: {
                        [userId]: {
                            $set: 'sending',
                        },
                    },
                }),
            );
            await BillingGroup.resendInvitationEmail(this.props.billingGroup.id, userId);
            this.props.notificationSystem.addNotification({
                message: this.props.t('EditBillingGroup-resendInvitationSuccess'),
                level: 'success',
            });
            await this.setState(
                update(this.state, {
                    elements: {
                        $set: this.buildElements(this.state.users, this.state.selectUsersMode, this.state.usersToMove),
                    },
                    resendInvitationStatus: {
                        [userId]: {
                            $set: 'sent',
                        },
                    },
                }),
            );
        } catch (e) {
            console.error(e);
            await this.setState(
                update(this.state, {
                    elements: {
                        $set: this.buildElements(this.state.users, this.state.selectUsersMode, this.state.usersToMove),
                    },
                    resendInvitationStatus: {
                        [userId]: {
                            $set: undefined,
                        },
                    },
                }),
            );
            this.props.notificationSystem.addNotification({
                message: this.props.t('EditBillingGroup-resendInvitationError'),
                level: 'error',
            });
        }
    }

    async moveUsers() {
        try {
            const { usersToMove, currentlySelectedBillingGroup } = this.state;
            const userIds = Object.keys(usersToMove);
            await this.setState({ moveLoading: true });
            await BillingGroup.moveUsers(currentlySelectedBillingGroup, userIds);
            await this.setState({
                users: [],
                elements: [],
                isInfiniteLoading: false,
                infiniteLoadBeginEdgeOffset: 200,
                moveLoading: false,
            });
            await this.handleInfiniteLoad();
            this.props.notificationSystem.addNotification({
                message: this.props.t('EditBillingGroup-moveUsersSuccess'),
                level: 'success',
            });
            await this.setState({
                usersToMove: {},
                usersSelectedState: USER_SELECTED_STATES.NONE,
            });
            await this.props.getBillingGroupData(this.props.billingGroup.id);
        } catch (e) {
            await this.setState({
                moveLoading: false,
            });
            if (get(e, 'response.status') === 402) {
                this.props.notificationSystem.addNotification({
                    message: this.props.t('EditBillingGroup-moveUsersBillingGroupFullError'),
                    level: 'error',
                });
            } else {
                this.props.notificationSystem.addNotification({
                    message: this.props.t('EditBillingGroup-moveUsersError'),
                    level: 'error',
                });
            }
        }
    }

    selectBillingGroup(event) {
        this.setState({
            currentlySelectedBillingGroup: event.target.value,
        });
    }

    handleChange(event) {
        this.setState({ newEmails: event.target.value });
    }

    handleInfiniteLoad() {
        this.setState({
            isInfiniteLoading: true,
        });

        BillingGroup.getUsers(this.props.billingGroup.id, 50, this.state.users.length).then((newUsers) => {
            const newState = update(this.state, {
                users: { $push: newUsers },
                elements: { $push: this.buildElements(newUsers, this.state.selectUsersMode, this.state.usersToMove) },
                isInfiniteLoading: { $set: false },
            });

            if (newUsers.length === 0) {
                newState.infiniteLoadBeginEdgeOffset = undefined;
            }

            this.setState(newState);
        });
    }

    handleCheckboxChange(event) {
        const target = event.target;
        const userId = target.name;

        const usersToMove = this.getNextValue(userId, target.checked);
        const numberOfUsersSelected = Object.keys(usersToMove).length;
        let usersSelectedState = USER_SELECTED_STATES.NONE;

        if (numberOfUsersSelected === this.state.users.length) {
            usersSelectedState = USER_SELECTED_STATES.ALL;
        } else if (numberOfUsersSelected > 0) {
            usersSelectedState = USER_SELECTED_STATES.PARTIAL;
        }

        const newState = update(this.state, {
            usersToMove: {
                $set: usersToMove,
            },
            usersSelectedState: {
                $set: usersSelectedState,
            },
            elements: {
                $set: this.buildElements(this.state.users, true, usersToMove),
            },
        });

        this.setState(newState);
    }

    handleKeyUp(e) {
        if (e.key === 'Shift' && this.state.isShiftDown) {
            this.setState({ isShiftDown: false });
        }
    }

    handleKeyDown(e) {
        if (e.key === 'Shift' && !this.state.isShiftDown) {
            this.setState({ isShiftDown: true });
        }
    }

    toggleSelectUsersMode() {
        const { selectUsersMode, users } = this.state;
        const usersToMove = {};
        const elements = this.buildElements(users, !selectUsersMode, usersToMove);
        this.setState({ selectUsersMode: !selectUsersMode, usersToMove, elements });
    }

    selectAllUsers() {
        const { usersToMove, users } = this.state;
        users.forEach((user) => {
            usersToMove[user.id] = true;
        });
        const usersSelectedState = USER_SELECTED_STATES.ALL;
        this.setState({
            usersToMove,
            usersSelectedState,
            elements: this.buildElements(this.state.users, true, usersToMove),
        });
    }

    unSelectAllUsers() {
        const usersToMove = {};
        const usersSelectedState = USER_SELECTED_STATES.NONE;
        this.setState({
            usersToMove,
            usersSelectedState,
            elements: this.buildElements(this.state.users, true, usersToMove),
        });
    }

    getNextValue(selectedUserId, checked) {
        const { isShiftDown, usersToMove, users } = this.state;

        if (isShiftDown) {
            const firstSelectedItem = users.findIndex((user) => usersToMove[user.id]);
            const currentSelectedItem = users.findIndex((user) => user.id === selectedUserId);
            const firstItem = currentSelectedItem < firstSelectedItem ? currentSelectedItem : firstSelectedItem;
            const lastItem = currentSelectedItem >= firstSelectedItem ? currentSelectedItem : firstSelectedItem;
            let finished = false;
            let i = firstItem;
            while (!finished && i < users.length) {
                const currentUserId = users[i].id;
                if (checked) {
                    usersToMove[currentUserId] = true;
                } else {
                    delete usersToMove[currentUserId];
                }
                finished = i === lastItem;
                i++;
            }
            return usersToMove;
        }
        if (checked) {
            usersToMove[selectedUserId] = true;
        } else {
            delete usersToMove[selectedUserId];
        }
        return usersToMove;
    }

    elementInfiniteLoad() {
        const { t } = this.props;
        return <div>{t('EditBillingGroup-loading')}...</div>;
    }

    buildElements(newUsers, selectUsersMode, usersToMove) {
        const { t } = this.props;
        const listUsers = newUsers.map((user) => {
            return (
                <tr key={user.id}>
                    {selectUsersMode && (
                        <th>
                            <input
                                type="checkbox"
                                name={user.id}
                                checked={usersToMove && usersToMove[user.id] === true}
                                onChange={this.handleCheckboxChange}
                            />
                        </th>
                    )}
                    <td style={{ maxWidth: '15rem', overflow: 'scroll' }}>{user.email}</td>
                    <td>
                        {user.account_activated === false && (
                            <Button
                                outline
                                color="primary"
                                disabled={this.state && this.state.resendInvitationStatus[user.id]}
                                onClick={() => this.resendInvitationEmail(user.id)}
                            >
                                {t('EditBillingGroup-resendInvitationEmail')}
                            </Button>
                        )}
                    </td>
                    {this.props.billingGroup.billing_type !== 'dalkia' && (
                        <td>
                            {' '}
                            <Button outline color="danger" onClick={() => this.deleteUser(user.id)}>
                                {t('common-delete')}
                            </Button>
                        </td>
                    )}
                </tr>
            );
        });

        return listUsers;
    }

    getSelectButtonImage() {
        const { usersSelectedState } = this.state;
        if (usersSelectedState === USER_SELECTED_STATES.NONE) {
            return square;
        }
        if (usersSelectedState === USER_SELECTED_STATES.PARTIAL) {
            return minusSquare;
        }
        if (usersSelectedState === USER_SELECTED_STATES.ALL) {
            return checkSquare;
        }
    }

    getSelectButtonFunction() {
        const { usersSelectedState } = this.state;
        if (usersSelectedState === USER_SELECTED_STATES.NONE) {
            return this.selectAllUsers;
        }
        if (usersSelectedState === USER_SELECTED_STATES.PARTIAL) {
            return this.unSelectAllUsers;
        }
        if (usersSelectedState === USER_SELECTED_STATES.ALL) {
            return this.unSelectAllUsers;
        }
    }

    componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp, false);
        document.addEventListener('keydown', this.handleKeyDown, false);
        this.getBillingGroups();
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    render() {
        const { t } = this.props;
        const {
            selectUsersMode,
            usersSelectedState,
            currentlySelectedBillingGroup,
            billingGroupsForMoveUsers,
            moveLoading,
        } = this.state;
        const remainingSeats = this.props.billingGroup.max_nb_seat - this.props.billingGroup.current_nb_seat;

        return (
            <Row>
                <Col sm="7">
                    <Row>
                        <Col sm="2">
                            <button className="btn" onClick={this.getSelectButtonFunction()}>
                                <img
                                    src={this.getSelectButtonImage()}
                                    alt=" square"
                                    className="image-button-select-all"
                                />
                            </button>
                        </Col>

                        {usersSelectedState !== USER_SELECTED_STATES.NONE && (
                            <Col sm="3 move-to-text">
                                <span>{t('EditBillingGroup-moveUsersLabel')}</span>
                            </Col>
                        )}

                        {usersSelectedState !== USER_SELECTED_STATES.NONE && (
                            <Col>
                                <select
                                    className="form-control"
                                    value={currentlySelectedBillingGroup}
                                    onChange={this.selectBillingGroup}
                                >
                                    {billingGroupsForMoveUsers &&
                                        this.props.billingGroup &&
                                        billingGroupsForMoveUsers.map((billingGroup) => (
                                            <option key={billingGroup.id} value={billingGroup.id}>
                                                {billingGroup.title}
                                            </option>
                                        ))}
                                </select>{' '}
                            </Col>
                        )}
                        {usersSelectedState !== USER_SELECTED_STATES.NONE && (
                            <Col>
                                <button onClick={this.moveUsers} className="btn btn-move">
                                    {moveLoading && <Spinner />}
                                    {!moveLoading && t('EditBillingGroup-moveUsersButton')}
                                </button>
                            </Col>
                        )}
                    </Row>
                    <Infinite
                        elementHeight={63}
                        containerHeight={400}
                        preloadBatchSize={this.state.elements.length * 63}
                        infiniteLoadBeginEdgeOffset={this.state.infiniteLoadBeginEdgeOffset}
                        onInfiniteLoad={this.handleInfiniteLoad}
                        loadingSpinnerDelegate={this.elementInfiniteLoad()}
                        isInfiniteLoading={this.state.isInfiniteLoading}
                    >
                        <Table striped>
                            <thead>
                                <tr>
                                    {selectUsersMode && <th></th>}
                                    <th>{t('EditBillingGroup-emailColumn')}</th>
                                    <th></th>
                                    {this.props.billingGroup.billing_type !== 'dalkia' && <th></th>}
                                </tr>
                            </thead>
                            <tbody>{this.state.elements}</tbody>
                        </Table>
                    </Infinite>
                </Col>

                {this.props.isDefaultBillingGroup && (
                    <Col sm="5">
                        <Alert color="warning">{t('EditBillingGroup-notAllowedToAddUsersInDefaultBillingGroup')}</Alert>
                    </Col>
                )}

                {this.props.billingGroup.billing_type !== 'dalkia' && !this.props.isDefaultBillingGroup && (
                    <Col sm="5">
                        <FormGroup row>
                            <Col sm={12}>
                                <Input
                                    type="textarea"
                                    name="text"
                                    id="users"
                                    value={this.state.newEmails}
                                    onChange={this.handleChange}
                                    placeholder={t('EditBillingGroup-usersListPlaceholder')}
                                    disabled={remainingSeats <= 0}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={{ size: 6 }}>
                                {t('EditBillingGroup-numberOfRemainingLicense', {
                                    count: remainingSeats,
                                })}
                            </Col>
                        </FormGroup>
                        {remainingSeats <= 0 && (
                            <FormGroup row>
                                <Col sm={{ size: 12 }}>
                                    <Button outline color="primary" onClick={() => this.props.toggle('2')}>
                                        {t('EditBillingGroup-addMoreLicenceButton')}
                                    </Button>
                                </Col>
                            </FormGroup>
                        )}
                        {remainingSeats > 0 && (
                            <FormGroup row>
                                <Col sm={{ size: 4 }}>
                                    <Button
                                        outline
                                        color="success"
                                        onClick={this.addUsers}
                                        disabled={remainingSeats <= 0}
                                    >
                                        {this.state.saving ? (
                                            <img src={threeDotsImg} alt="waiting" className="loaderSvg" />
                                        ) : (
                                            t('EditBillingGroup-addUserButton')
                                        )}
                                    </Button>
                                </Col>
                            </FormGroup>
                        )}
                    </Col>
                )}
            </Row>
        );
    }
}

export default withRouter(translate('admin')(BillingGroupUser));
