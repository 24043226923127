import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.css';

class FormTemplateLayout extends Component {
    render() {
        const { t, children, organization, formTemplateTitle } = this.props;

        const url = organization ? `/${organization.id}/form-template` : '';

        return (
            <div className="element-wrapper">
                <h6 className="element-header">
                    {organization ? organization.title : ''} /{' '}
                    <Link className="form-template-link" to={url}>
                        {t('FormTemplate-title')}
                    </Link>{' '}
                    {formTemplateTitle ? ` / ${formTemplateTitle}` : ''}
                </h6>
                <div className="element-box">{children}</div>
            </div>
        );
    }
}

export default translate('admin')(FormTemplateLayout);
