import React, { Component } from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';
import BillingTableOneMonth from './BillingHistoryTableOneMonth';

const BillingList = ({ months, idOpened, toggleCollapse, t }) => {
    const listOfMonths = months.map((month, index) => {
        let numberOfLicenses = 0;

        month.billingGroups.forEach((billingGroup) => {
            numberOfLicenses += billingGroup.users.length;
        });

        return (
            <div className="card" key={month.date}>
                <div className="card-header" id={`heading${index}`}>
                    <div className="mb-0">
                        <button
                            style={{ textTransform: 'capitalize' }}
                            className="btn btn-link"
                            onClick={() => toggleCollapse(index)}
                            data-toggle="collapse"
                            data-target={`collapse${index}`}
                            aria-expanded="false"
                            aria-controls={`collapse${index}`}
                        >
                            {moment(month.date).format('MMMM YYYY')}{' '}
                            {index == 0 && t('BillingHistoryList-currentMonth')}
                        </button>
                        <span>{t('BillingHistoryList-numberOfLicenses', { count: numberOfLicenses })}</span>
                    </div>
                </div>

                <div
                    id={`collapse${index}`}
                    className={classnames({ collapse: true, show: idOpened == index })}
                    aria-labelledby={`heading${index}`}
                    data-parent="#accordion"
                >
                    <div className="card-body">
                        <BillingTableOneMonth month={month} />
                    </div>
                </div>
            </div>
        );
    });

    return <div id="accordion">{listOfMonths}</div>;
};

export default translate('admin')(BillingList);
