function convertToCSV(objArray, headersOrder) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let j = 0; j < headersOrder.length; j++) {
            if (line !== '') line += ',';

            if (array[i][headersOrder[j]] !== null && array[i][headersOrder[j]] !== undefined) {
                let data = array[i][headersOrder[j]];
                if (data && data.replace) {
                    data = data.replace(/"/g, '""');
                }
                let cell = `"${data}"`;
                line += cell;
            }
        }

        str += `${line}\r\n`;
    }

    return str;
}

export default {
    exportCSVFile(headers, headersOrder, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        const jsonObject = JSON.stringify(items);

        let csv = convertToCSV(jsonObject, headersOrder);

        const BOM = '\uFEFF';

        csv = BOM + csv;

        const exportedFilenmae = `${fileTitle}.csv` || 'export.csv';

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    },
};
