import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { translate } from 'react-i18next';
import Select from 'react-select';
import Team from '../../api/Team';

class TeamParameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            teamAdmins: [],
        };
        this.getOptions = this.getOptions.bind(this);
        this.handleChangeSelectAdmin = this.handleChangeSelectAdmin.bind(this);
        this.saveTeamAdmin = this.saveTeamAdmin.bind(this);
    }

    componentDidMount() {
        this.getOptions();
    }

    getOptions() {
        const { team } = this.props;
        return Team.getUsers(team.id, 100000, 0).then((users) => {
            const options = [];
            const teamAdmins = [];
            users.forEach((user) => {
                const option = { value: user.id };
                if (user.firstname && user.lastname) option.label = `${user.firstname} ${user.lastname}`;
                else option.label = user.email;
                options.push(option);
                if (user.role === 'admin') teamAdmins.push(option);
            });
            this.setState({ users, usersOptions: options, teamAdmins });
        });
    }

    handleChangeSelectAdmin(params) {
        this.setState({ teamAdmins: params });
    }

    saveTeamAdmin() {
        const promises = [];
        this.state.users.forEach((user) => {
            if (this.state.teamAdmins && this.state.teamAdmins.find((teamAdmin) => teamAdmin.value == user.id)) {
                promises.push(Team.makeAdmin(this.props.team.id, user.id));
            } else {
                promises.push(Team.revokeAdmin(this.props.team.id, user.id));
            }
        });
        return Promise.all(promises).then(() => {
            this.props.notificationSystem.addNotification({
                message: this.props.t('TeamParameters-save-admin-success'),
                level: 'success',
            });
        });
    }

    render() {
        const { t, isAllowedToEditTeam } = this.props;
        const { usersOptions, teamAdmins } = this.state;

        return (
            <div>
                <div>
                    <h5 className="form-header">{t('TeamParameters-title')}</h5>
                    <div className="form-desc">{t('TeamParameters-sentence')}</div>

                    {!isAllowedToEditTeam && (
                        <div className="alert alert-primary">{t('TeamParameters-DefaultTeamWarning')}</div>
                    )}

                    <div className="form-group">
                        <label> {t('TeamParameters-name')}</label>
                        <input
                            className="form-control"
                            placeholder="Name"
                            type="text"
                            disabled={!isAllowedToEditTeam}
                            value={this.props.team.title}
                            onChange={(e) => this.props.updateTeamTitle(e.target.value)}
                        />
                    </div>

                    {isAllowedToEditTeam && (
                        <div className="form-buttons-w">
                            <Button color="primary" onClick={this.props.saveTeam}>
                                {t('common-save')}
                            </Button>
                        </div>
                    )}

                    <fieldset className="form-group">
                        <legend>
                            <span> {t('TeamParameters-admin-title')}</span>
                        </legend>
                        <p>{t('TeamParameters-admin-sentence')}</p>

                        <Select
                            name="form-field-name"
                            value={teamAdmins}
                            isMulti
                            onChange={this.handleChangeSelectAdmin}
                            options={usersOptions}
                            styles={{
                                container: (base, state) => {
                                    base.zIndex = '3';
                                    return base;
                                },
                            }}
                        />

                        <div className="form-buttons-w">
                            <Button color="primary" onClick={this.saveTeamAdmin}>
                                {t('common-save')}
                            </Button>
                        </div>
                    </fieldset>

                    {isAllowedToEditTeam && (
                        <fieldset className="form-group">
                            <legend>
                                <span> {t('TeamParameters-delete-title')}</span>
                            </legend>
                            <Row>
                                <Col md={12}>{t('TeamParameters-delete-sentence')}</Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Col md={4}>
                                    <button className="btn btn-danger" onClick={this.props.toggleModal}>
                                        {' '}
                                        {t('common-delete')}
                                    </button>
                                </Col>
                            </Row>
                        </fieldset>
                    )}
                    <Modal
                        isOpen={this.props.deleteConfirmationModalOpened}
                        toggle={this.props.toggleModal}
                        className={this.props.className}
                    >
                        <ModalHeader toggle={this.props.toggleModal}>
                            {' '}
                            {t('TeamParameters-delete-modal-title')}
                        </ModalHeader>
                        <ModalBody>{t('TeamParameters-delete-modal-sentence')}</ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => this.props.deleteTeam(this.props.team.id)}>
                                {t('common-delete')}
                            </Button>{' '}
                            <Button color="secondary" onClick={this.props.toggleModal}>
                                {t('common-cancel')}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default translate('admin')(TeamParameters);
