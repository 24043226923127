import React, { Component } from 'react';
import { translate, Trans } from 'react-i18next';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import Organization from '../../api/Organization';
import ApiKey from '../../api/ApiKey';

import EnterpriseApiLayout from './EnterpriseApiLayout';

class EnterpriseApiDocumentation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async getOrganization() {
        const organization = await Organization.getById(this.props.match.params.organization_id);
        await this.setState({ organization });
    }

    async getDocumentation() {
        const documentation = await ApiKey.getDocumentation();
        await this.setState({ documentation });
    }

    componentDidMount() {
        this.getOrganization();
        this.getDocumentation();
    }

    render() {
        const { t } = this.props;
        const { organization, documentation } = this.state;

        return (
            <EnterpriseApiLayout organization={organization} activeTab="documentation">
                <h5 className="form-header">{t('EnterpriseApi-Documentation-title')}</h5>
                <div className="form-desc">{t('EnterpriseApi-Documentation-sentence')}</div>
                <p>
                    <Trans i18nKey="EnterpriseApi-Documentation-authenticationText">
                        To use these API routes outside of this documentation, simply place the API key in the
                        <code>Authorization</code> header.
                    </Trans>
                </p>
                {documentation && <SwaggerUI spec={documentation} />}
            </EnterpriseApiLayout>
        );
    }
}

export default translate('admin')(EnterpriseApiDocumentation);
