import React, { Component } from 'react';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { translate } from 'react-i18next';

class StatisticLayout extends Component {
    render() {
        const { t, activeTab, children, organization } = this.props;
        const currentOrganizationId = this.props.match.params.organization_id;
        return (
            <div className="element-wrapper">
                {organization && (
                    <h6 className="element-header">
                        {organization.title} / {t('Statistics-title')} / {t(`Statistics-title-${activeTab}`)}
                    </h6>
                )}
                <div className="element-box">
                    <div className="os-tabs-w">
                        <div className="os-tabs-controls" style={{ marginBottom: '1rem' }}>
                            <Nav tabs className="small">
                                <NavItem>
                                    <Link
                                        className={classnames('nav-link', {
                                            active: activeTab === 'user',
                                        })}
                                        to={`/${currentOrganizationId}/statistic/user`}
                                    >
                                        {t('StatisticUsers-users-tab')}
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={classnames('nav-link', {
                                            active: activeTab === 'project',
                                        })}
                                        to={`/${currentOrganizationId}/statistic/project`}
                                    >
                                        {t('StatisticUsers-projects-tab')}
                                    </Link>
                                </NavItem>
                            </Nav>
                        </div>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={activeTab}>{children}</TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(translate('admin')(StatisticLayout));
