import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import { debounce } from 'debounce';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import './override-bootstrap-table.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';

import Organization from '../../api/Organization';
import BillingGroup from '../../api/BillingGroup';
import ProjectTemplate from '../../api/ProjectTemplate';

import NotificationSystem from 'react-notification-system';

import ProjectTemplateLayout from './ProjectTemplateLayout';

import svgLoader from '../../../theme/img/loader.svg';

const SIZE_PER_PAGE_FIELD_LOCALSTORAGE_KEY = 'ProjectTemplatesTableSizePerPage';

class ProjectTemplateList extends Component {
    constructor(props) {
        super(props);
        this.notificationSystem = React.createRef();
        this.state = {
            loading: false,
            ProjectTemplates: [],
            ProjectTemplateSearchTitle: '',
            selectedBillingGroups: [],
            defaultPageSize: this.getDefaultPageSize(),
        };
        this.selectAllBillingGroups = this.selectAllBillingGroups.bind(this);
        this.onChangeSelectBillingGroup = this.onChangeSelectBillingGroup.bind(this);
        this.getBillingGroups = this.getBillingGroups.bind(this);
        this.getOrganization = this.getOrganization.bind(this);
        this.getProjectTemplates = this.getProjectTemplates.bind(this);
        this.getProjectTemplatesWithDebounce = debounce(this.getProjectTemplates, 200);
        this.onSearchChange = this.onSearchChange.bind(this);
    }

    getDefaultPageSize() {
        if (localStorage) {
            const sizePerPageLocalStorage = localStorage.getItem(SIZE_PER_PAGE_FIELD_LOCALSTORAGE_KEY);
            if (sizePerPageLocalStorage && Number.isInteger(parseInt(sizePerPageLocalStorage, 10))) {
                return parseInt(sizePerPageLocalStorage, 10);
            }
        }
        // default value is 50
        return 50;
    }

    getColumns() {
        const { t } = this.props;
        const columns = [
            {
                dataField: 'title',
                text: t('ProjectTemplate-TitleColumn'),
                sort: true,
                onSort: this.onSort,
            },
            {
                dataField: 'created_at',
                text: t('ProjectTemplate-CreatedAtColumn'),
                sort: true,
                onSort: this.onSort,
                formatter: (cell) => {
                    return moment(cell).format('ll');
                },
            },
            {
                dataField: 'creator_name',
                text: t('ProjectTemplate-CreatorNameColumn'),
                sort: true,
                onSort: this.onSort,
            },
            {
                dataField: 'action',
                text: '',
                sort: false,
            },
        ];

        return columns;
    }

    async getOrganization() {
        const organization = await Organization.getById(this.props.match.params.organization_id);
        await this.setState({ organization });
    }

    async getBillingGroups() {
        const billingGroups = await BillingGroup.get(this.props.match.params.organization_id);
        const billingGroupsOptions = billingGroups.map((billingGroup) => {
            const billingGroupsOption = {
                label: billingGroup.title,
                value: billingGroup.id,
            };
            return billingGroupsOption;
        });
        this.setState({ billingGroupsOptions });
    }

    async getProjectTemplates() {
        const { t } = this.props;
        const options = {};
        const billingGroupIds = this.state.selectedBillingGroups.map((option) => option.value);
        if (billingGroupIds.length > 0) {
            options.billingGroupIds = billingGroupIds;
        }
        if (this.state.ProjectTemplateSearchTitle.length > 0) {
            options.title = this.state.ProjectTemplateSearchTitle;
        }
        const ProjectTemplates = await ProjectTemplate.getByOrganizationId(
            this.props.match.params.organization_id,
            options,
        );
        const ProjectTemplatesWithButton = ProjectTemplates.map((ft) => {
            ft.action = (
                <Link to={`/${this.props.match.params.organization_id}/project-template/${ft.id}`}>
                    <Button outline>{t('ProjectTemplate-EditButton')}</Button>
                </Link>
            );
            return ft;
        });
        this.setState({ ProjectTemplates: ProjectTemplatesWithButton });
    }

    async onChangeSelectBillingGroup(selection) {
        const selectionOrDefault = selection ? selection : [];
        await this.setState({ selectedBillingGroups: selectionOrDefault });
        await this.getProjectTemplates();
    }

    selectAllBillingGroups() {
        this.onChangeSelectBillingGroup(this.state.billingGroupsOptions);
    }

    async onSearchChange(e) {
        const ProjectTemplateSearchTitle = e.target.value;
        await this.setState({ ProjectTemplateSearchTitle });
        this.getProjectTemplatesWithDebounce();
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        this.getOrganization();
        this.getBillingGroups();
        this.getProjectTemplates();
    }

    render() {
        const { t } = this.props;
        const {
            loading,
            ProjectTemplates,
            defaultPageSize,
            selectedBillingGroups,
            billingGroupsOptions,
            organization,
            ProjectTemplateSearchTitle,
        } = this.state;

        return (
            <ProjectTemplateLayout organization={organization}>
                <h5 className="form-header">{t('ProjectTemplate-title')}</h5>
                <div className="form-desc">{t('ProjectTemplate-sentence')}</div>
                {loading && (
                    <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: '100px', marginBottom: '100px', height: '100px' }}
                    >
                        <img src={svgLoader} alt="loader" />
                    </div>
                )}

                <div>
                    <div className="row mb-3">
                        <div className="col-md-10">
                            <Select
                                styles={{
                                    control: (base, state) => {
                                        // base.height = '34px';
                                        base['min-height'] = '34px';
                                        return base;
                                    },
                                    container: (base) => {
                                        base.zIndex = 9;
                                        return base;
                                    },
                                }}
                                isClearable
                                isMulti
                                closeMenuOnSelect={false}
                                options={billingGroupsOptions}
                                value={selectedBillingGroups}
                                onChange={this.onChangeSelectBillingGroup}
                                placeholder={t('StatisticUsers-billingGroupSelectPlaceholder')}
                                noOptionsMessage={() => t('Statistics-noBillingGroups')}
                            />
                        </div>
                        <div className="col-md-2">
                            <Button outline color="primary" block onClick={this.selectAllBillingGroups}>
                                {t('StatisticUsers-SelectAllButton')}
                            </Button>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <input
                                className="form-control"
                                style={{
                                    height: '34px',
                                }}
                                placeholder={t('ProjectTemplate-SearchProjectTemplatePlaceholder')}
                                onChange={this.onSearchChange}
                                value={ProjectTemplateSearchTitle}
                                type="text"
                            />
                        </div>
                    </div>
                    <BootstrapTable
                        bootstrap4
                        keyField="id"
                        bordered={false}
                        data={ProjectTemplates}
                        columns={this.getColumns()}
                        defaultSorted={[
                            {
                                dataField: 'title',
                                order: 'asc',
                            },
                        ]}
                        pagination={paginationFactory({
                            onSizePerPageChange: this.onSizePerPageChange,
                            sizePerPage: defaultPageSize,
                            sizePerPageList: [
                                {
                                    text: '50',
                                    value: 50,
                                },
                                {
                                    text: '100',
                                    value: 100,
                                },
                                {
                                    text: '200',
                                    value: 200,
                                },
                            ],
                        })}
                        wrapperClasses="table-responsive table-striped table-lightborder"
                    />
                </div>

                {loading === false && ProjectTemplates.length === 0 && <div />}
                <NotificationSystem ref={this.notificationSystem} />
            </ProjectTemplateLayout>
        );
    }
}

export default translate('admin')(ProjectTemplateList);
