import axios from 'axios';

export default {
    get(organizationId) {
        return axios
            .get(`${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/teams`, {
                params: { take: 100000, skip: 0 },
            })
            .then((data) => data.data);
    },

    getById(teamId) {
        return axios.get(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}`).then((data) => data.data);
    },

    create(organizationId, team) {
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/teams`, team)
            .then((data) => data.data);
    },

    update(teamId, team) {
        return axios
            .patch(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}`, team)
            .then((data) => data.data);
    },

    addUsers(teamId, emails) {
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/users`, emails)
            .then((data) => data.data);
    },

    deleteUser(teamId, userId) {
        return axios
            .delete(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/users/${userId}`)
            .then((data) => data.data);
    },

    getUsers(teamId, take = 50, skip = 0) {
        return axios
            .get(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/users?take=${take}&skip=${skip}`)
            .then((data) => data.data);
    },

    getProjects(teamId) {
        return axios
            .get(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/projects`)
            .then((data) => data.data);
    },

    generateCsvFormReport(teamId, formTemplateId, projectIds, startDate, endDate) {
        const body = {
            form_template_id: formTemplateId,
            project_ids: projectIds,
            start_date: startDate,
            end_date: endDate,
        };
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/reports`, body)
            .then((data) => data.data);
    },

    resendInvitationEmail(teamId, userId) {
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/users/${userId}/resend_email`)
            .then((data) => data.data);
    },

    delete(teamId) {
        return axios
            .patch(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}`, { is_deleted: true })
            .then((data) => data.data);
    },

    makeAdmin(teamId, userId) {
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/team_admins`, { user_id: userId })
            .then((data) => data.data);
    },

    revokeAdmin(teamId, userId) {
        return axios
            .delete(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/team_admins/${userId}`)
            .then((data) => data.data);
    },

    getContactBooks(teamId, take, skip) {
        return axios
            .get(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/contact_books?take=5000`)
            .then((data) => data.data);
    },

    getFormTemplates(teamId, take, skip) {
        return axios
            .get(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/form_templates?take=5000`)
            .then((data) => data.data);
    },

    removeContactBook(teamId, contactBookId) {
        return axios
            .delete(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/contact_books/${contactBookId}`)
            .then((data) => data.data);
    },

    removeFormTemplate(teamId, formTemplateId) {
        return axios
            .delete(`${process.env.REACT_APP_SERVER_URL}/client_admin/teams/${teamId}/form_templates/${formTemplateId}`)
            .then((data) => data.data);
    },
};
