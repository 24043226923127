import React from 'react';
import { translate } from 'react-i18next';
import { Button, Table } from 'reactstrap';

const BillingHistoryExport = ({ exportCsv, months, exportTotalStatsCsv, t }) => {
    const billingGroups = [];
    const BillingGroupTable = [];
    const billingGroupSet = new Set();

    months.forEach((month) => {
        month.billingGroups.forEach((billingGroup) => {
            if (!billingGroupSet.has(billingGroup.billingGroup.id)) {
                billingGroups.push(billingGroup.billingGroup);
                billingGroupSet.add(billingGroup.billingGroup.id);
                BillingGroupTable.push(
                    <tr key={billingGroup.billingGroup.id}>
                        <td>{billingGroup.billingGroup.title}</td>
                        <td>
                            <Button
                                color="primary"
                                onClick={() => exportCsv(billingGroup.billingGroup.id, billingGroup.billingGroup.title)}
                            >
                                {t('BillingGroup-exportBillingGroup-button')}
                            </Button>
                        </td>
                    </tr>,
                );
            }
        });
    });

    return (
        <div>
            <div className="billing-group-export-block">
                <h5 className="form-header">{t('BillingHistory-exportAll-title')}</h5>
                <div className="form-desc">{t('BillingGroup-exportAll-sentence')}</div>
                <div>
                    <Button color="primary" onClick={exportTotalStatsCsv}>
                        {t('BillingGroup-exportAll-button')}
                    </Button>
                </div>
            </div>
            <div className="billing-group-export-block">
                <h5 className="form-header">{t('BillingGroup-exportBillingGroup-title')}</h5>
                <div className="form-desc">{t('BillingGroup-exportBillingGroup-sentence')}</div>
                <div>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>{t('BillingGroup-exportBillingGroup-license-group-column')}</th>
                                <th>{t('BillingGroup-exportBillingGroup-export-column')}</th>
                            </tr>
                        </thead>
                        <tbody>{BillingGroupTable}</tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default translate('admin')(BillingHistoryExport);
