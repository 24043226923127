import React, { Component } from 'react';

import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import update from 'immutability-helper';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import Organization from '../../api/Organization';
import Team from '../../api/Team';

import TeamParameters from './TeamParameters.js';
import TeamUserList from './TeamUserList';
import ContactBookComponent from './TeamContactBook';
import FormTemplateComponent from './TeamFormTemplate';
import Auth from '../../../app/Auth';

class EditTeam extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {
            team: null,
            users: [],
            activeTab: '1',
            deleteConfirmationModalOpened: false,
            organization: null,
            isOrgaAdmin: false,
        };

        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.updateTeamTitle = this.updateTeamTitle.bind(this);
        this.saveTeam = this.saveTeam.bind(this);
        this.deleteTeam = this.deleteTeam.bind(this);
        this.getTeam = this.getTeam.bind(this);
        this.getOrganization = this.getOrganization.bind(this);
        this.getRights = this.getRights.bind(this);
        this.getAllData = this.getAllData.bind(this);
    }

    async getTeam() {
        const team = await Team.getById(this.props.match.params.team_id);
        const isAllowedToEditTeam = !team.billing_group_id && !team.default_organization_team;
        this.setState({ team, isAllowedToEditTeam });
    }

    async getOrganization() {
        const organization = await Organization.getById(this.props.match.params.organization_id);
        this.setState({ organization });
    }

    async getRights() {
        const rights = await Auth.getRights();
        const isOrgaAdmin =
            rights.organizations.find((o) => o.id === this.props.match.params.organization_id) !== undefined;
        this.setState({ isOrgaAdmin });
    }

    async getAllData() {
        this.getTeam();
        this.getOrganization();
        this.getRights();
    }

    componentDidUpdate(prevProps) {
        const teamChanged = this.props.match.params.team_id !== prevProps.match.params.team_id;
        const organizationChanged = this.props.match.params.organization_id !== prevProps.match.params.organization_id;
        if (teamChanged || organizationChanged) {
            this.getAllData();
        }
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        this.getAllData();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleModal() {
        this.setState({ deleteConfirmationModalOpened: !this.state.deleteConfirmationModalOpened });
    }

    deleteTeam(teamId) {
        Team.delete(teamId).then((team) => {
            this.props.history.push('/team');
        });
    }

    updateTeamTitle(newTitle) {
        const newState = update(this.state, {
            team: { title: { $set: newTitle } },
        });
        this.setState(newState);
    }

    saveTeam() {
        Team.update(this.state.team.id, this.state.team).then(() => {
            this._notificationSystem.addNotification({
                message: this.props.t('TeamParameters-save-admin-success'),
                level: 'success',
            });
        });
    }

    render() {
        const { t } = this.props;
        const { isAllowedToEditTeam } = this.state;
        return (
            <div className="element-wrapper">
                <h6 className="element-header">
                    {this.state.organization && this.state.organization.title} / {t('TeamList-title')} /{' '}
                    {this.state.team && this.state.team.title}
                </h6>
                <div className="element-box">
                    {this.state.team && (
                        <div>
                            <div className="os-tabs-w">
                                <div className="os-tabs-controls">
                                    <Nav tabs className="small">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '1' })}
                                                onClick={() => {
                                                    this.toggle('1');
                                                }}
                                            >
                                                {t('Organization-usersTitle')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                onClick={() => {
                                                    this.toggle('2');
                                                }}
                                            >
                                                {t('Organization-formTemplateTitle')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '3' })}
                                                onClick={() => {
                                                    this.toggle('3');
                                                }}
                                            >
                                                {t('Organization-contactBookTitle')}
                                            </NavLink>
                                        </NavItem>
                                        {this.state.isOrgaAdmin ? (
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '4' })}
                                                    onClick={() => {
                                                        this.toggle('4');
                                                    }}
                                                >
                                                    {t('Organization-parameterTitle')}
                                                </NavLink>
                                            </NavItem>
                                        ) : (
                                            ''
                                        )}
                                    </Nav>
                                </div>
                            </div>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <TeamUserList
                                                team={this.state.team}
                                                isAllowedToEditTeam={isAllowedToEditTeam}
                                                organizationId={this.props.match.params.organization_id}
                                                notificationSystem={this._notificationSystem}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <FormTemplateComponent
                                                team={this.state.team}
                                                notificationSystem={this._notificationSystem}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>
                                        <Col sm="12">
                                            <ContactBookComponent
                                                team={this.state.team}
                                                notificationSystem={this._notificationSystem}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Row>
                                        <Col sm="12">
                                            <TeamParameters
                                                team={this.state.team}
                                                isAllowedToEditTeam={isAllowedToEditTeam}
                                                notificationSystem={this._notificationSystem}
                                                toggleModal={this.toggleModal}
                                                deleteConfirmationModalOpened={this.state.deleteConfirmationModalOpened}
                                                deleteTeam={this.deleteTeam}
                                                updateTeamTitle={this.updateTeamTitle}
                                                saveTeam={this.saveTeam}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                    )}

                    <NotificationSystem ref="notificationSystem" />
                </div>
            </div>
        );
    }
}

export default translate('admin')(EditTeam);
