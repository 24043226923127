import React, { Component } from 'react';
import {
    Container,
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Button,
    Alert,
} from 'reactstrap';
import { parse } from 'query-string';
import get from 'get-value';
import { useHistory } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { translate } from 'react-i18next';
import Auth from './Auth';
import packageJson from '../../package.json';
const PASSWORD_MIN_SIZE = 8;

class FirstLoginForm extends Component {
    render() {
        const { t } = this.props;
        return (
            <Form onSubmit={this.props.login}>
                <FormGroup>
                    <Label for="email">{t('FirstLogin-email')}</Label>
                    <Input
                        type="email"
                        name="email"
                        value={this.props.email}
                        disabled="disabled"
                        id="email"
                        placeholder={t('Login-email')}
                        onChange={(e) => this.props.update('email', e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="password">{t('FirstLogin-password')}</Label>
                    <Input
                        type="password"
                        name="password"
                        value={this.props.password}
                        id="password"
                        placeholder={t('Login-password')}
                        onChange={(e) => this.props.update('password', e.target.value)}
                    />
                </FormGroup>
                <Button color="primary" block>
                    {t('FirstLogin-button')}
                </Button>
            </Form>
        );
    }
}

class LoginComponent extends Component {
    constructor(props) {
        super(props);

        this._notificationSystem = null;
        this.state = {
            email: '',
            password: '',
            passwordTooShortError: false,
        };

        this.update = this.update.bind(this);
        this.firstLogin = this.firstLogin.bind(this);
    }

    firstLogin(e) {
        e.preventDefault();

        if (this.state.password.length < PASSWORD_MIN_SIZE) {
            this.setState({
                passwordTooShortError: true,
            });
            return;
        }
        this.setState({
            passwordTooShortError: false,
        });

        const device = {
            name: get(window, 'navigator.appCodeName'),
            platform: 'CLIENT_ADMIN_TOOL',
            os: get(window, 'navigator.oscpu'),
            app_version: packageJson.version,
        };
        Auth.setPasswordFirstLogin(this.props.match.params.first_login_id, this.state.password, device)
            .then(() => Auth.getUser())
            .then(() => Auth.getRights())
            .then((rights) => {
                if (
                    rights.billingGroups.length === 0 &&
                    rights.organizations.length === 0 &&
                    rights.teams.length === 0
                ) {
                    window.location.href = process.env.REACT_APP_WEBAPP_URL;
                } else {
                    this.props.history.replace('/');
                }
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.data &&
                    err.response.data.meta &&
                    err.response.data.meta.error_type == 'ALREADY_USED'
                ) {
                    this._notificationSystem.addNotification({
                        message: this.props.t('FirstLogin-errorAlreadyUsedLink'),
                        level: 'error',
                    });
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.meta &&
                    err.response.data.meta.error_type == 'LINK_EXPIRED'
                ) {
                    this._notificationSystem.addNotification({
                        message: this.props.t('FirstLogin-errorLinkExpired'),
                        level: 'error',
                    });
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.meta &&
                    err.response.data.meta.error_type == 'PASSWORD_TOO_SHORT'
                ) {
                    this._notificationSystem.addNotification({
                        message: this.props.t('FirstLogin-passwordTooShort'),
                        level: 'error',
                    });
                } else {
                    this._notificationSystem.addNotification({
                        message: this.props.t('FirstLogin-failed'),
                        level: 'error',
                    });
                }
            });
    }

    update(field, value) {
        this.setState({
            [field]: value,
        });
    }

    componentDidMount() {
        const query = parse(window.location.search);
        if (query.email) {
            this.setState({ email: query.email });
        }
        this._notificationSystem = this.refs.notificationSystem;
    }

    render() {
        const { t } = this.props;

        return (
            <div className="background-bz">
                <Container>
                    <Row className="vertical-offset-100">
                        <Col md={{ size: 4, offset: 4 }}>
                            <Card className="loginBox">
                                <CardBody>
                                    <CardTitle className="text-center">
                                        <h4>{t('FirstLogin-title')}</h4>
                                    </CardTitle>
                                    <CardText>
                                        {this.state.passwordTooShortError && (
                                            <Alert color="danger">{t('FirstLogin-passwordTooShort')}</Alert>
                                        )}
                                        <FirstLoginForm
                                            email={this.state.email}
                                            password={this.state.password}
                                            update={this.update}
                                            login={this.firstLogin}
                                            t={t}
                                        />
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <NotificationSystem ref="notificationSystem" />
                </Container>
            </div>
        );
    }
}

export default translate('admin')(LoginComponent);
