import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import get from 'get-value';
import Intercom from './Intercom';
import deviceDetector from 'ua-device-detector';
import packageJson from '../../package.json';

const cookies = new Cookies();

const DEFAULT_COOKIE_OPTIONS = {
    path: '/',
};

const WEBAPP_TRANSITION_COOKIE_OPTIONS = {
    domain: '.bulldozair.com',
};

function checkIfValidUUID(str) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
}

export default {
    currentUser: null,
    currentDevice: null,
    rights: null,

    intercept401Requests(logout) {
        axios.interceptors.response.use(
            function(response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function(error) {
                if (
                    get(error, 'response.status') === 401 &&
                    get(error, 'response.data.meta.error_message') === 'Token not valid'
                ) {
                    logout();
                }
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                return Promise.reject(error);
            },
        );
    },

    authenticate() {
        const token = cookies.get(process.env.REACT_APP_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);

        if (!token) return false;

        try {
            // test if decoding the token is working
            jwtDecode(token);

            // set header in axios with auth token
            axios.defaults.headers.common.Authorization = token;
        } catch (e) {
            return false;
        }

        return true;
    },

    login(email, password, device) {
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/client_admin/login`, {
                email,
                password,
                device,
            })
            .then((data) => {
                this.setCookiesLogin(data.data);
                this.setDevice(data.data.device.id);
            });
    },

    async loginFromWebapp(token) {
        try {
            const deviceInfo = deviceDetector.parseUserAgent(navigator.userAgent);
            const device = {
                name: deviceInfo.browser,
                platform: 'CLIENT_ADMIN_TOOL',
                os: deviceInfo.os,
                app_version: packageJson.version,
                system_version: deviceInfo.os_version,
            };
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/login_from_webapp`,
                {
                    device,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                },
            );
            this.setCookiesLogin(data);
            this.setDevice(data.device.id);
        } catch (e) {
            // If this fails, it's not a problem, we don't set any cookies
            // And that's all.. The user will be redirected later as he doesn't have a cookie
            console.error(e);
        }
    },

    async syncCookieWithWebAppLoginAsCookie() {
        const user = cookies.get(process.env.REACT_APP_WEBAPP_LOGIN_AS_COOKIE_NAME, WEBAPP_TRANSITION_COOKIE_OPTIONS);
        if (user && user.token) {
            await this.loginFromWebapp(user.token);
        }
        cookies.remove(process.env.REACT_APP_WEBAPP_LOGIN_AS_COOKIE_NAME, WEBAPP_TRANSITION_COOKIE_OPTIONS);
    },

    getUser() {
        if (this.currentUser) return Promise.resolve(this.currentUser);

        const token = cookies.get(process.env.REACT_APP_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);
        const tokenDecoded = jwtDecode(token);

        return axios.get(`${process.env.REACT_APP_SERVER_URL}/users/${tokenDecoded.uid}`).then((data) => {
            if (process.env.REACT_APP_INTERCOM_USER_HASH_COOKIE) {
                data.data.intercom_user_hash = cookies.get(
                    process.env.REACT_APP_INTERCOM_USER_HASH_COOKIE,
                    DEFAULT_COOKIE_OPTIONS,
                );
            }
            this.currentUser = data.data;
            return this.currentUser;
        });
    },

    setPasswordFirstLogin(token, password, device) {
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/user/first_login/${token}`, {
                password,
                device,
            })
            .then((data) => {
                this.setCookiesLogin(data.data);
            });
    },

    setCookiesLogin(user) {
        // save user in cookie
        cookies.set(process.env.REACT_APP_COOKIE_NAME, user.token, DEFAULT_COOKIE_OPTIONS);

        // save intercom user hash cookie
        cookies.set(process.env.REACT_APP_INTERCOM_USER_HASH_COOKIE, user.intercom_user_hash, DEFAULT_COOKIE_OPTIONS);

        // set header in axios with auth token
        axios.defaults.headers.common.Authorization = user.token;
    },

    getRights() {
        if (this.rights) return Promise.resolve(this.rights);

        return axios.get(`${process.env.REACT_APP_SERVER_URL}/client_admin/myself`).then((data) => {
            this.rights = data.data;
            return this.rights;
        });
    },

    async setDevice(deviceId) {
        cookies.set(process.env.REACT_APP_DEVICE_COOKIE_NAME, deviceId, DEFAULT_COOKIE_OPTIONS);
    },

    logout() {
        this.currentUser = null;
        this.currentDevice = null;
        this.rights = null;
        cookies.remove(process.env.REACT_APP_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);
        cookies.remove(process.env.REACT_APP_WEBAPP_LOGIN_AS_COOKIE_NAME, WEBAPP_TRANSITION_COOKIE_OPTIONS);
        cookies.remove(process.env.REACT_APP_INTERCOM_USER_HASH_COOKIE, DEFAULT_COOKIE_OPTIONS);
        cookies.remove(process.env.REACT_APP_DEVICE_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);
        Intercom.shutdown();
        axios.defaults.headers.common.Authorization = '';
    },

    async sendEvent(pageName, type = 'pageview') {
        const deviceId = cookies.get(process.env.REACT_APP_DEVICE_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);
        const deviceInfo = deviceDetector.parseUserAgent(navigator.userAgent);
        if (!deviceId || !checkIfValidUUID(deviceId)) {
            return;
        }
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/stat_events`, {
            type,
            platform: 'CLIENT_ADMIN_TOOL',
            page_name: pageName,
            system_version: deviceInfo.os_version,
            app_version: packageJson.version,
            device_id: deviceId,
        });
    },
};
