import axios from 'axios';

export default {
    async get(organizationId) {
        const { data } = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/enterprise_api_keys`,
        );
        return data;
    },

    async getById(organizationId, apiKeyId) {
        const { data } = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/enterprise_api_keys/${apiKeyId}`,
        );
        return data;
    },

    async post(organizationId, body) {
        const { data } = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/enterprise_api_keys`,
            body,
        );
        return data;
    },

    async destroy(organizationId, apiKeyId) {
        await axios.delete(
            `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/enterprise_api_keys/${apiKeyId}`,
        );
    },

    async patch(organizationId, apiKeyId, body) {
        const { data } = await axios.patch(
            `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/enterprise_api_keys/${apiKeyId}`,
            body,
        );
        return data;
    },

    async getDocumentation() {
        const { data } = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/client_admin/enterprise_api/swagger_documentation`,
        );
        return data;
    },
};
