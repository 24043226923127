/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Col,
    Input,
    Label,
    Alert,
} from 'reactstrap';
import { translate } from 'react-i18next';

class NewBillingGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            max_nb_seat: 0,
            license: 'internal',
        };
        this.getBillingGroupLicenseTranslation = this.getBillingGroupLicenseTranslation.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isOpen !== nextProps.isOpen) {
            this.setState({
                title: '',
                max_nb_seat: 0,
                license: 'internal',
            });
        }
    }

    getBillingGroupLicenseTranslation() {
        const { t } = this.props;
        const { license } = this.state;

        if (license === 'internal') {
            return t('BillingGroupList-license-internal');
        }
        if (license === 'subcontractor') {
            return t('BillingGroupList-license-subcontractor');
        }
        if (license === 'observator') {
            return t('BillingGroupList-license-observator');
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}>{t('BillingGroupModal-title')}</ModalHeader>
                    <ModalBody>
                        <Form>
                            {(this.state.max_nb_seat > this.props.organization.max_nb_seat ||
                                this.props.notEnoughSeatAvailable) && (
                                <Alert color="danger">
                                    {t('BillingGroupModal-warningTooManySeats', {
                                        count: this.props.nbRemainingSeats,
                                        licenseType: this.getBillingGroupLicenseTranslation(),
                                    })}
                                    <a href="mailto:sales@bulldozair.com">sales@bulldozair.com</a>.
                                </Alert>
                            )}

                            {this.props.newBillingGroupInvalid && (
                                <Alert color="danger">{t('BillingGroupModal-warningInvalidBillingGroup')}</Alert>
                            )}

                            {this.state.max_nb_seat < 0 && (
                                <Alert color="danger">{t('BillingGroupModal-warningSeatPositiveOrNull')}</Alert>
                            )}
                            <FormGroup row>
                                <Label for="orgaName" sm={6}>
                                    {' '}
                                    {t('BillingGroupModal-nameField')}
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="orgaName"
                                        placeholder={t('BillingGroupModal-nameField')}
                                        value={this.state.title}
                                        onChange={(e) => this.setState({ title: e.target.value })}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="orgaName" sm={6}>
                                    {' '}
                                    {t('BillingGroupModal-licenseTypeField')}
                                </Label>
                                <Col sm={6}>
                                    <select
                                        className="form-control"
                                        value={this.state.license}
                                        onChange={(e) => this.setState({ license: e.target.value })}
                                    >
                                        <option value="internal">{t('BillingGroup-internal-license')}</option>
                                        <option value="subcontractor">{t('BillingGroup-subcontractor-license')}</option>
                                        <option value="observator">{t('BillingGroup-observator-license')}</option>
                                    </select>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="orgaName" sm={6}>
                                    {t('BillingGroupModal-licenseField')}
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="number"
                                        name="max_nb_seat"
                                        id="maxNbSeat"
                                        placeholder={t('BillingGroupModal-licenseField')}
                                        value={this.state.max_nb_seat}
                                        onChange={(e) => this.setState({ max_nb_seat: e.target.value })}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() =>
                                this.props.createBillingGroup(
                                    this.props.organization.id,
                                    this.state.title,
                                    this.state.max_nb_seat,
                                    this.state.license,
                                )
                            }
                        >
                            {t('common-create')}
                        </Button>
                        <Button color="secondary" onClick={this.props.toggle}>
                            {t('common-cancel')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default translate('admin')(NewBillingGroupModal);
