import React from 'react';
import { translate } from 'react-i18next';
import { Clipboard } from 'react-bootstrap-icons';

const Credentials = (props) => {
    const { t, url, userId, userPassword } = props;

    const writeToClipBoard = async (value) => {
        try {
            await navigator.clipboard.writeText(value);
        } catch (e) {
            console.error(e);
            props.notification.addNotification({
                message: t('ExportApiDatasets-failToCopy'),
                level: 'error',
            });
            throw e;
        }
    };

    const copyURL = async () => {
        await writeToClipBoard(url);
        props.notification.addNotification({
            message: t('ExportApiDatasets-URLCopied'),
            level: 'success',
        });
    };

    const copyUsername = async () => {
        await writeToClipBoard(userId);
        props.notification.addNotification({
            message: t('ExportApiDatasets-UsernameCopied'),
            level: 'success',
        });
    };

    const copyPassword = async () => {
        await writeToClipBoard(userPassword);
        props.notification.addNotification({
            message: t('ExportApiDatasets-PasswordCopied'),
            level: 'success',
        });
    };
    return (
        <div>
            <p>{t('ExportApiDatasets-empyStateText')}</p>
            <p>
                {t('ExportApiDatasets-tutorialText')}
                <a href="https://support.bulldozair.com/">{t('ExportApiDatasets-tutorialLink')}</a>
            </p>
            <div className="form-group">
                <label>{t('ExportApiDatasets-Url')}</label>
                <div class="input-group">
                    <input
                        className="form-control"
                        placeholder={t('ExportApiDatasets-Url')}
                        type="text"
                        value={url}
                        disabled
                    />
                    <div class="input-group-append">
                        <button class="btn btn-secondary" type="button" onClick={copyURL}>
                            <Clipboard size={16} />
                        </button>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label>{t('ExportApiDatasets-UserId')}</label>
                <div class="input-group">
                    <input
                        className="form-control"
                        placeholder={t('ExportApiDatasets-UserId')}
                        type="text"
                        value={userId}
                        disabled
                    />
                    <div class="input-group-append">
                        <button class="btn btn-secondary" type="button" onClick={copyUsername}>
                            <Clipboard size={16} />
                        </button>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label>{t('ExportApiDatasets-UserPassword')}</label>
                <div class="input-group">
                    <input
                        className="form-control"
                        placeholder={t('ExportApiDatasets-UserPassword')}
                        type="text"
                        value={userPassword}
                        disabled
                    />
                    <div class="input-group-append">
                        <button class="btn btn-secondary" type="button" onClick={copyPassword}>
                            <Clipboard size={16} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default translate('admin')(Credentials);
