export default {
    load() {
        (function() {
            const w = window;
            const ic = w.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', window.intercomSettings);
            } else {
                const d = document;
                var i = function() {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function(args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                function l() {
                    const s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`;
                    const x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                }
                if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();

        window.Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        });
    },

    setUser(user) {
        if (window.Intercom && user.intercom_user_hash) {
            window.Intercom('update', {
                name: `${user.firstname} ${user.lastname}`,
                email: user.email,
                user_id: user.id,
                user_hash: user.intercom_user_hash,
            });
        }
    },

    shutdown() {
        window.Intercom('shutdown');
        window.Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        });
    },
};
