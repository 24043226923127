import React, { Component } from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';

import Organization from '../../api/Organization';
import { Table } from 'reactstrap';
import EnterpriseApiLayout from './EnterpriseApiLayout';
import ApiKey from '../../api/ApiKey';
import svgLoader from '../../../theme/img/loader.svg';

import './style.css';

class FormTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            apiKeys: [],
        };
    }

    async getOrganization() {
        const organization = await Organization.getById(this.props.match.params.organization_id);
        await this.setState({ organization });
    }

    async getApiKeys() {
        const apiKeys = await ApiKey.get(this.props.match.params.organization_id);
        apiKeys.forEach((apiKey) => {
            apiKey.createdAtFormatted = moment(apiKey.created_at).format('LLL');
        });
        await this.setState({ apiKeys });
    }

    async init() {
        await Promise.all([this.getOrganization(), this.getApiKeys()]);
        await this.setState({ loading: false });
    }

    componentDidMount() {
        this.init();
    }

    render() {
        const { t } = this.props;
        const organizationId = this.props.match.params.organization_id;
        const { organization, apiKeys, loading } = this.state;

        return (
            <EnterpriseApiLayout organization={organization} activeTab="api-keys">
                <div className="loading-container">
                    <h5 className="form-header">{t('EnterpriseApi-ApiKeys-title')}</h5>
                    <div className="form-desc">{t('EnterpriseApi-ApiKeys-sentence')}</div>
                    <a
                        className="btn btn-sm btn-primary mb-4"
                        href={`/${organizationId}/enterprise-api/key/create/new`}
                    >
                        {t('EnterpriseApi-ApiKeys-createButton')}
                    </a>
                    {loading === true && (
                        <div className="loading d-flex justify-content-center">
                            <img src={svgLoader} className="loading-spinner" alt="loader" />
                        </div>
                    )}
                    <Table striped>
                        <thead>
                            <tr>
                                <th>{t('EnterpriseApi-titleColumn')}</th>
                                <th>{t('EnterpriseApi-createdAt')}</th>
                                <th>{t('EnterpriseApi-creator')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {apiKeys.map((apiKey) => {
                                return (
                                    <tr key={apiKey.id}>
                                        <td>{apiKey.title}</td>
                                        <td>{apiKey.createdAtFormatted}</td>
                                        <td>
                                            {apiKey.creator_firstname && apiKey.creator_lastname
                                                ? `${apiKey.creator_firstname} ${apiKey.creator_lastname}`
                                                : apiKey.creator_email}
                                        </td>
                                        <td>
                                            <a
                                                href={`/${organizationId}/enterprise-api/key/${apiKey.id}`}
                                                className="btn btn-outline-primary"
                                            >
                                                {t('EnterpriseApi-EditButton')}
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </EnterpriseApiLayout>
        );
    }
}

export default translate('admin')(FormTemplateList);
