import React, { Component } from 'react';
import { translate } from 'react-i18next';

import Organization from '../../api/Organization';
import EmptyState from './ExportApiDatasetsEmptyState';
import NotificationSystem from 'react-notification-system';

import svgLoader from '../../../theme/img/loader.svg';

import Credentials from './ExportApiDatasetsCredentials';

class ExportApiDatasets extends Component {
    constructor(props) {
        super(props);
        this.notificationSystem = React.createRef();
        this.state = {
            userId: null,
            userPassword: null,
            organizationId: null,
            loading: true,
        };
    }

    async getCredentials() {
        try {
            const currentOrganizationId = this.props.match.params.organization_id;
            const { url, userId, userPassword } = await Organization.getExportApiCredentials(currentOrganizationId);
            this.setState({ url, userId, userPassword, loading: false });
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        this.getCredentials();
    }

    render() {
        const { t } = this.props;
        const { loading, url, userId, userPassword } = this.state;
        const credentialsAreSet = url && userId && userPassword;

        return (
            <div className="element-wrapper">
                <div className="element-box">
                    <h5 className="form-header">{t('ExportApiDatasets-title')}</h5>
                    <div className="form-desc">{t('ExportApiDatasets-sentence')}</div>

                    {loading && (
                        <div className="d-flex justify-content-center" style={{ height: '400px' }}>
                            <img src={svgLoader} alt="loader" />
                        </div>
                    )}

                    {loading === false && credentialsAreSet && (
                        <Credentials
                            notification={this.notificationSystem.current}
                            url={url}
                            userId={userId}
                            userPassword={userPassword}
                        />
                    )}

                    {!credentialsAreSet && loading === false && <EmptyState />}
                </div>
                <NotificationSystem ref={this.notificationSystem} />
            </div>
        );
    }
}

export default translate('admin')(ExportApiDatasets);
