import React, { Component } from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import Select from 'react-select';
import Organization from '../../api/Organization';
import BillingGroup from '../../api/BillingGroup';
import ApiKey from '../../api/ApiKey';
import Auth from '../../../app/Auth';
import update from 'immutability-helper';
import { Alert, Button } from 'reactstrap';
import { EyeSlash, Eye, Clipboard, CheckLg, XCircle } from 'react-bootstrap-icons';
import svgLoader from '../../../theme/img/loader.svg';

import EnterpriseApiLayout from './EnterpriseApiLayout';

import './style.css';

const SCOPES_OPTIONS = [
    'file:write',
    'note:read',
    'note:write',
    'project:read',
    'project:write',
    'tag:read',
    'zone:write',
    'gis:read',
    'organization:read',
    'billing_group:read',
    'billing_group:write',
    'document:read',
    'document:write',
    'document:delete',
];

const GIS_API_KEY_TITLES = [
    "Clé d'API Projet SIG",
    'GIS Project API Key',
    'Clave API del proyecto SIG',
    'Chave da API do projeto GIS',
];

class EnterpriseApiEditKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            apiKeyHidden: true,
            apiKeyCopied: false,
            isOrgaAdmin: false,
            apiKey: {
                expires_at: null,
                organizations: [],
                billing_groups: [],
                projects: [],
                scopes: [],
            },
            scopesSelected: {},
        };
        this.toggleApiKeyVisibility = this.toggleApiKeyVisibility.bind(this);
        this.toggleOrganizationWideAccess = this.toggleOrganizationWideAccess.bind(this);
        this.handleScopeChange = this.handleScopeChange.bind(this);
        this.handleBillingGroupsChange = this.handleBillingGroupsChange.bind(this);
        this.handleProjectsChange = this.handleProjectsChange.bind(this);
        this.copyApiKey = this.copyApiKey.bind(this);
        this.saveApiKey = this.saveApiKey.bind(this);
        this.init = this.init.bind(this);
        this.handleExpiresAtChange = this.handleExpiresAtChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.deleteApiKey = this.deleteApiKey.bind(this);
        this.toggleScopeSelectAll = this.toggleScopeSelectAll.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.toggleExpiresAt = this.toggleExpiresAt.bind(this);
    }

    toggleApiKeyVisibility() {
        this.setState({ apiKeyHidden: !this.state.apiKeyHidden });
    }

    async copyApiKey() {
        try {
            await this.setState({ apiKeyCopied: true, apiKeyCopyError: false });
            await navigator.clipboard.writeText(this.state.apiKey.api_key);
            setTimeout(() => {
                this.setState({ apiKeyCopied: false });
            }, 3000);
        } catch (e) {
            console.error(e);
            this.setState({ apiKeyCopyError: true });
        }
    }

    async saveApiKey() {
        try {
            await this.setState({ loading: true, savingError: false });
            if (this.props.match.params.api_key_id) {
                await ApiKey.patch(
                    this.props.match.params.organization_id,
                    this.props.match.params.api_key_id,
                    this.state.apiKey,
                );
            } else {
                const createdApiKey = await ApiKey.post(this.props.match.params.organization_id, this.state.apiKey);
                this.props.history.replace(
                    `/${this.props.match.params.organization_id}/enterprise-api/key/${createdApiKey.id}`,
                );
            }
        } catch (e) {
            console.error(e);
            this.setState({ savingError: true });
            window.scrollTo(0, 0);
        }
        this.setState({ loading: false });
    }

    async deleteApiKey() {
        try {
            await this.setState({ loading: true, savingError: false });
            await ApiKey.destroy(this.props.match.params.organization_id, this.props.match.params.api_key_id);
            window.scrollTo(0, 0);
            this.props.history.replace(`/${this.props.match.params.organization_id}/enterprise-api/keys`);
        } catch (e) {
            console.error(e);
            this.setState({ savingError: true });
        }
        this.setState({ loading: false });
    }

    toggleExpiresAt() {
        const currentlyNull = this.state.apiKey.expires_at === null;
        const newState = update(this.state, {
            apiKey: {
                expires_at: {
                    $set: currentlyNull
                        ? moment()
                              .add(5, 'year')
                              .toDate()
                              .getTime()
                        : null,
                },
            },
        });
        this.setState(newState);
    }

    handleExpiresAtChange(e) {
        let newExpiresAt = null;
        if (e.target.value && e.target.value.length > 0) {
            newExpiresAt = new Date(e.target.value).getTime();
        }
        const updatedState = update(this.state, {
            apiKey: {
                expires_at: {
                    $set: newExpiresAt,
                },
            },
        });
        this.setState(updatedState);
    }

    toggleOrganizationWideAccess() {
        const { apiKey } = this.state;
        const organizationId = this.props.match.params.organization_id;
        const found = apiKey.organizations.find((o) => o.id === organizationId);
        let updatedState;
        if (found) {
            updatedState = update(this.state, {
                apiKey: {
                    organizations: {
                        $set: [],
                    },
                },
            });
        } else {
            updatedState = update(this.state, {
                apiKey: {
                    organizations: {
                        $set: [{ id: organizationId }],
                    },
                    billing_groups: {
                        $set: [],
                    },
                    projects: {
                        $set: [],
                    },
                },
                billingGroupsSelectedOptions: {
                    $set: [],
                },
                projectsSelectedOptions: {
                    $set: [],
                },
            });
        }
        this.setState(updatedState);
    }

    getScopeFromSelectedScopes(scopesSelected) {
        return Object.keys(scopesSelected)
            .map((key) => {
                if (scopesSelected[key]) {
                    return key;
                }
                return null;
            })
            .filter((r) => r !== null);
    }

    getAllSelectedToggle(scopesSelected) {
        return (
            SCOPES_OPTIONS.map((scopeOption) => scopesSelected[scopeOption]).filter((selected) => selected === true)
                .length === SCOPES_OPTIONS.length
        );
    }

    getScopeNextValue(scopesSelected, scope, checked) {
        const { isShiftDown } = this.state;

        if (isShiftDown) {
            const firstSelectedItem = SCOPES_OPTIONS.findIndex((scopeOption) => scopesSelected[scopeOption]);
            const currentSelectedItem = SCOPES_OPTIONS.findIndex((scopeOption) => scopeOption === scope);
            const firstItem = currentSelectedItem < firstSelectedItem ? currentSelectedItem : firstSelectedItem;
            const lastItem = currentSelectedItem >= firstSelectedItem ? currentSelectedItem : firstSelectedItem;
            let finished = false;
            let i = firstItem;
            while (!finished && i < SCOPES_OPTIONS.length) {
                const currentScope = SCOPES_OPTIONS[i];
                if (checked) {
                    scopesSelected[currentScope] = true;
                } else {
                    delete scopesSelected[currentScope];
                }
                finished = i === lastItem;
                i++;
            }
            return scopesSelected;
        }
        if (checked) {
            scopesSelected[scope] = true;
        } else {
            scopesSelected[scope] = false;
        }
        return scopesSelected;
    }

    toggleScopeSelectAll(e) {
        const scopesSelected = {};
        if (e.target.checked) {
            SCOPES_OPTIONS.forEach((scopeOption) => {
                scopesSelected[scopeOption] = true;
            });
        }
        const newScope = this.getScopeFromSelectedScopes(scopesSelected);
        const newState = update(this.state, {
            apiKey: {
                scopes: {
                    $set: newScope,
                },
            },
            scopesSelected: {
                $set: scopesSelected,
            },
            allScopesSelected: {
                $set: e.target.checked,
            },
        });
        this.setState(newState);
    }

    handleKeyUp(e) {
        if (e.key === 'Shift' && this.state.isShiftDown) {
            this.setState({ isShiftDown: false });
        }
    }

    handleKeyDown(e) {
        if (e.key === 'Shift' && !this.state.isShiftDown) {
            this.setState({ isShiftDown: true });
        }
    }

    handleScopeChange(e) {
        const [, scope] = e.target.id.split('-');
        const newScopesSelected = this.getScopeNextValue(this.state.scopesSelected, scope, e.target.checked);
        let newState = update(this.state, {
            scopesSelected: {
                $set: newScopesSelected,
            },
        });
        const newScope = this.getScopeFromSelectedScopes(newState.scopesSelected);
        const allScopesSelected = this.getAllSelectedToggle(newState.scopesSelected);
        newState = update(newState, {
            apiKey: {
                scopes: {
                    $set: newScope,
                },
            },
            allScopesSelected: {
                $set: allScopesSelected,
            },
        });
        this.setState(newState);
    }

    handleTitleChange(e) {
        const updatedApiKey = update(this.state.apiKey, {
            title: {
                $set: e.target.value,
            },
        });
        this.setState({ apiKey: updatedApiKey });
    }

    handleBillingGroupsChange(values) {
        const updatedState = update(this.state, {
            apiKey: {
                billing_groups: {
                    $set: values ? values.map((v) => ({ id: v.value })) : [],
                },
            },
            billingGroupsSelectedOptions: {
                $set: values || [],
            },
        });
        this.setState(updatedState);
    }

    handleProjectsChange(values) {
        const updatedState = update(this.state, {
            apiKey: {
                projects: {
                    $set: values ? values.map((v) => ({ id: v.value })) : [],
                },
            },
            projectsSelectedOptions: {
                $set: values || [],
            },
        });
        this.setState(updatedState);
    }

    async getOrganization() {
        try {
            const organization = await Organization.getById(this.props.match.params.organization_id);
            await this.setState({ organization });
        } catch (e) {
            console.error(e);
        }
    }

    async getApiKey() {
        try {
            if (!this.props.match.params.api_key_id) {
                return;
            }
            const apiKey = await ApiKey.getById(
                this.props.match.params.organization_id,
                this.props.match.params.api_key_id,
            );
            apiKey.createdAtFormatted = moment(apiKey.created_at).format('LLL');
            const billingGroupsSelectedOptions = apiKey.billing_groups.map((bg) => ({ value: bg.id, label: bg.title }));
            const projectsSelectedOptions = apiKey.projects.map((p) => ({ value: p.id, label: p.title }));
            const scopesSelected = [];
            apiKey.scopes.forEach((scope) => {
                scopesSelected[scope] = true;
            });
            const allScopesSelected = this.getAllSelectedToggle(scopesSelected);
            await this.setState({
                apiKey,
                billingGroupsSelectedOptions,
                projectsSelectedOptions,
                scopesSelected,
                allScopesSelected,
            });
        } catch (e) {
            console.error(e);
        }
    }

    async getRights() {
        try {
            const rights = await Auth.getRights();
            const isOrgaAdmin =
                rights.organizations.find((o) => o.id === this.props.match.params.organization_id) !== undefined;

            this.setState({
                isOrgaAdmin,
            });
        } catch (e) {
            console.error(e);
        }
    }

    async getBillingGroups() {
        try {
            const billingGroups = await BillingGroup.get(this.props.match.params.organization_id);
            const billingGroupsOptions = billingGroups.map((bg) => {
                return {
                    label: bg.title,
                    value: bg.id,
                };
            });
            this.setState({
                billingGroupsOptions,
            });
        } catch (e) {
            console.error(e);
        }
    }

    async getProjects() {
        try {
            const projects = await Organization.getProjects(this.props.match.params.organization_id);
            const projectsOptions = projects.map((p) => {
                return {
                    label: p.title,
                    value: p.id,
                };
            });
            this.setState({
                projectsOptions,
            });
        } catch (e) {
            console.error(e);
        }
    }

    async init() {
        await Promise.all([
            this.getOrganization(),
            this.getApiKey(),
            this.getRights(),
            this.getBillingGroups(),
            this.getProjects(),
        ]);
        this.setState({ loading: false });
    }

    componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp, false);
        document.addEventListener('keydown', this.handleKeyDown, false);
        this.init();
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    render() {
        const { t } = this.props;
        const {
            loading,
            organization,
            apiKey,
            apiKeyHidden,
            apiKeyCopied,
            apiKeyCopyError,
            projectsOptions,
            projectsSelectedOptions,
            billingGroupsOptions,
            billingGroupsSelectedOptions,
            isOrgaAdmin,
            savingError,
            scopesSelected,
            allScopesSelected,
        } = this.state;
        const organizationId = this.props.match.params.organization_id;
        const apiKeyId = this.props.match.params.api_key_id;
        const organizationWideAccess =
            apiKey && apiKey.organizations.find((o) => o.id === organizationId) ? true : false;
        const isGisApiKey = GIS_API_KEY_TITLES.includes(apiKey.title);

        return (
            <EnterpriseApiLayout organization={organization} activeTab="api-keys">
                <div className="loading-container">
                    <h5 className="form-header">
                        {apiKeyId ? t('EnterpriseApi-EditKey-title') : t('EnterpriseApi-EditKey-createTitle')}
                    </h5>
                    <div className="form-desc">{t('EnterpriseApi-EditKey-sentence')}</div>

                    {loading === true && (
                        <div className="loading d-flex justify-content-center">
                            <img src={svgLoader} className="loading-spinner" alt="loader" />
                        </div>
                    )}

                    {savingError && <Alert color="danger">{t('EnterpriseApi-EditKey-savingError')}</Alert>}

                    {isGisApiKey && (
                        <div class="alert alert-warning">{t('EnterpriseApi-EditKey-GISApiKeyCannotBeEdited')}</div>
                    )}

                    <div className="form-group">
                        <label className="font-weight-bold"> {t('EnterpriseApi-EditKey-titleLabel')}</label>
                        <input
                            className="form-control"
                            placeholder={t('EnterpriseApi-EditKey-title-placeholder')}
                            type="text"
                            value={apiKey.title}
                            onChange={this.handleTitleChange}
                            disabled={isGisApiKey}
                        />
                        {apiKeyId && (
                            <div className="mt-1">
                                <small>
                                    {t('EnterpriseApi-EditKey-ApiKeyCreatedOn')} {apiKey.createdAtFormatted}
                                </small>
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <label className="font-weight-bold"> {t('EnterpriseApi-EditKey-expirationLabel')}</label>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="expires-at-radio"
                                id="expires-at-never"
                                checked={apiKey.expires_at === null}
                                onChange={this.toggleExpiresAt}
                                disabled={isGisApiKey}
                                value="never"
                            />
                            <label class="form-check-label" for="expires-at-never">
                                {t('EnterpriseApi-EditKey-ExpiresAtNever')}
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="expires-at-radio"
                                id="expires-at-date"
                                checked={apiKey.expires_at !== null}
                                onChange={this.toggleExpiresAt}
                                disabled={isGisApiKey}
                                value="expires-at-date"
                            />
                            <label class="form-check-label" for="expires-at-date">
                                {t('EnterpriseApi-EditKey-ExpiresAtDate')}
                            </label>
                        </div>
                    </div>

                    {apiKey.expires_at !== null && (
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-4">
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={apiKey.expires_at ? moment(apiKey.expires_at).format('YYYY-MM-DD') : ''}
                                        onChange={this.handleExpiresAtChange}
                                        disabled={isGisApiKey}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {apiKeyId && (
                        <div className="form-group">
                            <label className="font-weight-bold"> {t('EnterpriseApi-EditKey-keyLabel')}</label>
                            <div class="input-group" id="show_hide_password">
                                <input
                                    value={apiKey.api_key}
                                    class="form-control"
                                    disabled={isGisApiKey}
                                    type={apiKeyHidden ? 'password' : 'text'}
                                />
                                <div class="input-group-addon" role="button" onClick={this.toggleApiKeyVisibility}>
                                    {apiKeyHidden ? <EyeSlash /> : <Eye />}
                                </div>
                                <div class="input-group-addon" role="button" onClick={this.copyApiKey}>
                                    {apiKeyCopyError ? <XCircle /> : apiKeyCopied ? <CheckLg /> : <Clipboard />}
                                </div>
                            </div>
                        </div>
                    )}

                    {isOrgaAdmin && (
                        <fieldset className="form-group">
                            <legend>
                                <span>{t('EnterpriseApi-EditKey-OrganizationWideAccess-title')}</span>
                            </legend>
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="organization-wide-radio"
                                    id="organization-wide"
                                    checked={organizationWideAccess}
                                    disabled={isGisApiKey}
                                    onChange={this.toggleOrganizationWideAccess}
                                    value="organization-wide"
                                />
                                <label class="form-check-label" for="organization-wide">
                                    {t('EnterpriseApi-EditKey-OrganizationWideApiKey', {
                                        title: organization && organization.title,
                                    })}
                                </label>
                            </div>
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="organization-wide-radio"
                                    id="not-organization-wide"
                                    checked={!organizationWideAccess}
                                    onChange={this.toggleOrganizationWideAccess}
                                    disabled={isGisApiKey}
                                    value="not-organization-wide"
                                />
                                <label class="form-check-label" for="not-organization-wide">
                                    {t('EnterpriseApi-EditKey-NotOrganizationWideApiKey')}
                                </label>
                            </div>
                        </fieldset>
                    )}

                    {!organizationWideAccess && (
                        <fieldset className="form-group">
                            <legend>
                                <span> {t('EnterpriseApi-EditKey-BillingGroups-title')}</span>
                            </legend>
                            <p>{t('EnterpriseApi-EditKey-BillingGroups-sentence')}</p>

                            <Select
                                name="enterprise-api-billing-groups-access"
                                value={billingGroupsSelectedOptions}
                                isMulti
                                onChange={this.handleBillingGroupsChange}
                                options={billingGroupsOptions}
                                isDisabled={isGisApiKey}
                                styles={{
                                    container: (base, state) => {
                                        base.zIndex = '5';
                                        return base;
                                    },
                                }}
                            />
                        </fieldset>
                    )}

                    {!organizationWideAccess && (
                        <fieldset className="form-group">
                            <legend>
                                <span> {t('EnterpriseApi-EditKey-Projects-title')}</span>
                            </legend>
                            <p>{t('EnterpriseApi-EditKey-Projects-sentence')}</p>

                            <Select
                                name="enterprise-api-projects-access"
                                value={projectsSelectedOptions}
                                isMulti
                                onChange={this.handleProjectsChange}
                                options={projectsOptions}
                                isDisabled={isGisApiKey}
                                styles={{
                                    container: (base, state) => {
                                        base.zIndex = '4';
                                        return base;
                                    },
                                }}
                            />
                        </fieldset>
                    )}

                    <fieldset className="form-group">
                        <legend>
                            <span> {t('EnterpriseApi-EditKey-Scopes-title')}</span>
                        </legend>
                        <p>{t('EnterpriseApi-EditKey-Scopes-sentence')}</p>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="checkbox-all-scopes-selected"
                                    checked={allScopesSelected}
                                    disabled={isGisApiKey}
                                    onChange={this.toggleScopeSelectAll}
                                />

                                {allScopesSelected
                                    ? t('EnterpriseApi-EditKey-UnSelectAll')
                                    : t('EnterpriseApi-EditKey-SelectAll')}
                            </label>
                        </div>
                        {SCOPES_OPTIONS.map((scopeOption) => (
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id={`checkbox-${scopeOption}`}
                                        checked={scopesSelected[scopeOption]}
                                        disabled={isGisApiKey}
                                        onChange={this.handleScopeChange}
                                    />
                                    {scopeOption}
                                </label>
                            </div>
                        ))}
                    </fieldset>

                    {isGisApiKey && (
                        <div class="alert alert-warning">{t('EnterpriseApi-EditKey-GISApiKeyCannotBeEdited')}</div>
                    )}

                    <div className="form-buttons-w">
                        <Button color="primary" onClick={this.saveApiKey} disabled={isGisApiKey}>
                            {t('common-save')}
                        </Button>
                        {apiKeyId && (
                            <Button color="danger" className="ml-4" onClick={this.deleteApiKey} disabled={isGisApiKey}>
                                {t('common-delete')}
                            </Button>
                        )}
                    </div>
                </div>
            </EnterpriseApiLayout>
        );
    }
}

export default translate('admin')(EnterpriseApiEditKey);
