import axios from 'axios';

export default {
    async getByOrganizationId(organizationId, { billingGroupIds, title }) {
        const { data } = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_templates/search`,
            {
                billing_group_ids: billingGroupIds,
                title,
            },
        );
        return data;
    },

    /**
     * report_user_template
     */

    reportUserTemplate: {
        async getById(organizationId, formTemplateId) {
            const { data } = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_user_templates/${formTemplateId}`,
            );
            return data;
        },

        async addUser(organizationId, formTemplateId, userId) {
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_user_templates/${formTemplateId}/users/${userId}`,
            );
            return data;
        },

        async updateUser(organizationId, formTemplateId, userId, toUpdate) {
            const { data } = await axios.patch(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_user_templates/${formTemplateId}/users/${userId}`,
                toUpdate,
            );
            return data;
        },

        async addTeam(organizationId, formTemplateId, teamId) {
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_user_templates/${formTemplateId}/teams/${teamId}`,
            );
            return data;
        },

        async removeTeam(organizationId, formTemplateId, teamId) {
            const { data } = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_user_templates/${formTemplateId}/teams/${teamId}`,
            );
            return data;
        },
    },

    /**
     * report_custom_template
     */

    reportCustomTemplate: {
        async getById(organizationId, formTemplateId) {
            const { data } = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_custom_templates/${formTemplateId}`,
            );
            return data;
        },

        async addUser(organizationId, formTemplateId, userId) {
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_custom_templates/${formTemplateId}/users/${userId}`,
            );
            return data;
        },

        async updateUser(organizationId, formTemplateId, userId, toUpdate) {
            const { data } = await axios.patch(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_custom_templates/${formTemplateId}/users/${userId}`,
                toUpdate,
            );
            return data;
        },

        async addTeam(organizationId, formTemplateId, teamId) {
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_custom_templates/${formTemplateId}/teams/${teamId}`,
            );
            return data;
        },

        async removeTeam(organizationId, formTemplateId, teamId) {
            const { data } = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/report_custom_templates/${formTemplateId}/teams/${teamId}`,
            );
            return data;
        },
    },

    /**
     * note_export_template
     */

    reportNoteExportTemplate: {
        async getById(organizationId, formTemplateId) {
            const { data } = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/note_export_templates/${formTemplateId}`,
            );
            return data;
        },

        async addUser(organizationId, formTemplateId, userId) {
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/note_export_templates/${formTemplateId}/users/${userId}`,
            );
            return data;
        },

        async updateUser(organizationId, formTemplateId, userId, toUpdate) {
            const { data } = await axios.patch(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/note_export_templates/${formTemplateId}/users/${userId}`,
                toUpdate,
            );
            return data;
        },

        async addTeam(organizationId, formTemplateId, teamId) {
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/note_export_templates/${formTemplateId}/teams/${teamId}`,
            );
            return data;
        },

        async removeTeam(organizationId, formTemplateId, teamId) {
            const { data } = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/note_export_templates/${formTemplateId}/teams/${teamId}`,
            );
            return data;
        },
    },
};
