import React, { Component } from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import Chart from 'chart.js';

// set defaults
Chart.defaults.global.defaultFontFamily =
    '"Proxima Nova W01", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
Chart.defaults.global.tooltips.titleFontSize = 14;
Chart.defaults.global.tooltips.titleMarginBottom = 4;
Chart.defaults.global.tooltips.displayColors = false;
Chart.defaults.global.tooltips.bodyFontSize = 12;
Chart.defaults.global.tooltips.xPadding = 10;
Chart.defaults.global.tooltips.yPadding = 8;

const MAX_SIZE_GRAPH = 12;

class BillingHistoryChart extends Component {
    constructor(props) {
        super(props);
        this.refreshChart = this.refreshChart.bind(this);
    }

    componentDidMount() {
        this.refreshChart();
    }

    refreshChart() {
        const { t } = this.props;
        const { lineChart } = this.refs;

        const monthStrings = [];
        const monthValues = [];

        let min = null;
        let max = null;

        const months = this.props.months.slice(0, MAX_SIZE_GRAPH);

        months.forEach((month) => {
            monthStrings.unshift(moment(month.date).format('MMMM YYYY'));
            let currentMonthsValue = 0;
            month.billingGroups.forEach((billingGroup) => {
                currentMonthsValue += billingGroup.users.length;
            });
            if (min == null || currentMonthsValue < min) min = currentMonthsValue;
            if (max == null || currentMonthsValue > max) max = currentMonthsValue;
            monthValues.unshift(currentMonthsValue);
        });

        // line chart data
        const lineData = {
            labels: monthStrings,
            datasets: [
                {
                    label: t('BillingHistoryChart-licence-chartLabel'),
                    fill: false,
                    lineTension: 0.3,
                    backgroundColor: '#fff',
                    borderColor: '#005378',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#fff',
                    pointBackgroundColor: '#141E41',
                    pointBorderWidth: 3,
                    pointHoverRadius: 10,
                    pointHoverBackgroundColor: '#FC2055',
                    pointHoverBorderColor: '#fff',
                    pointHoverBorderWidth: 3,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: monthValues,
                    spanGaps: false,
                },
            ],
        };

        // line chart init
        const myLineChart = new Chart(lineChart, {
            type: 'line',
            data: lineData,
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontSize: '11',
                                fontColor: '#969da5',
                            },
                            gridLines: {
                                color: 'rgba(0,0,0,0.05)',
                                zeroLineColor: 'rgba(0,0,0,0.05)',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            display: false,
                            ticks: {
                                beginAtZero: false,
                                min: 0,
                                max: max + 2,
                            },
                        },
                    ],
                },
            },
        });
    }

    render() {
        const { t } = this.props;

        let licencesCurrentlyActive = 0;
        if (this.props.months && this.props.months.length > 0) {
            this.props.months[0].billingGroups.forEach((billingGroup) => {
                licencesCurrentlyActive += billingGroup.users.length;
            });
        }

        return (
            <div>
                <div className="el-tablo bigger">
                    <div className="label">{t('BillingHistoryChart-licenceCountLabel')}</div>
                    <div className="value">{licencesCurrentlyActive}</div>
                </div>
                <div className="el-chart-w">
                    <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand" />
                    </div>
                    <canvas
                        height="354"
                        id="lineChart"
                        ref="lineChart"
                        width="1422"
                        className="chartjs-render-monitor"
                        style={{ display: 'block', height: '177px', width: '711px' }}
                    />
                </div>
            </div>
        );
    }
}

export default translate('admin')(BillingHistoryChart);
