import React, { Component } from 'react';
import Select from 'react-select';
import { Button, Input, CustomInput, ButtonDropdown, DropdownToggle } from 'reactstrap';
import { translate } from 'react-i18next';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import update from 'immutability-helper';
import Csv from '../../api/Csv';
import { debounce } from 'debounce';
import Organization from '../../api/Organization';
import BillingGroup from '../../api/BillingGroup';

import StatisticLayout from './StatisticLayout';

import { nFormatter } from './nFormatter';
import svgLoader from '../../../theme/img/loader.svg';

const SORT_FIELD_LOCALSTORAGE_KEY = 'StatisticsProjectsTableSortField';
const SORT_ORDER_LOCALSTORAGE_KEY = 'StatisticsProjectsTableSortOrder';
const SIZE_PER_PAGE_FIELD_LOCALSTORAGE_KEY = 'StatisticsProjectsTableSizePerPage';
const COLUMNS_SELECTED_LOCALSTORAGE_KEY = 'StatisticsProjectsTableColumnsSelected';

class StatisticProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAllGroups: false,
            selectedProjectStatusFilter: 'all',
            selectedColumns: this.getDefaultColumnsSelected(),
            defaultTableOrder: this.getDefaultTableOrdering(),
            defaultPageSize: this.getDefaultPageSize(),
        };
        this.exportToCsv = this.exportToCsv.bind(this);
        this.toggleColumn = this.toggleColumn.bind(this);
        this.onChangeSelectProjectStatus = this.onChangeSelectProjectStatus.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onChangeSelectBillingGroup = this.onChangeSelectBillingGroup.bind(this);
        this.refreshProjectsWithDebounce = debounce(this.refreshProjects, 200);
        this.selectAllBillingGroups = this.selectAllBillingGroups.bind(this);
    }

    async componentDidMount() {
        await this.getOrganization();
        await this.getBillingGroups();
        await this.refreshProjects();
    }

    getDefaultTableOrdering() {
        const sort = {
            dataField: 'last_action_at',
            order: 'desc',
        };

        if (localStorage) {
            const field = localStorage.getItem(SORT_FIELD_LOCALSTORAGE_KEY);
            const order = localStorage.getItem(SORT_ORDER_LOCALSTORAGE_KEY);
            if (field) {
                sort.dataField = field;
            }
            if (order) {
                sort.order = order;
            }
        }
        return sort;
    }

    getDefaultColumnsSelected() {
        const selectedColumns = {
            billing_group_title: false,
            project_title: true,
            project_creator: false,
            phase: false,
            start_date: true,
            end_date: true,
            created_at: false,
            last_action_at: true,
            blocks_count: false,
            users_count: false,
            reports_count: false,
            picture_blocks_count: false,
            text_blocks_count: false,
            notes_count: false,
            progress: true,
        };

        if (localStorage) {
            const stringData = localStorage.getItem(COLUMNS_SELECTED_LOCALSTORAGE_KEY);
            if (stringData) {
                try {
                    const savedSelectedColumns = JSON.parse(stringData);
                    Object.assign(selectedColumns, savedSelectedColumns);
                } catch (e) {}
            }
        }

        return selectedColumns;
    }

    getDefaultPageSize() {
        if (localStorage) {
            const sizePerPageLocalStorage = localStorage.getItem(SIZE_PER_PAGE_FIELD_LOCALSTORAGE_KEY);
            if (sizePerPageLocalStorage && Number.isInteger(parseInt(sizePerPageLocalStorage, 10))) {
                return parseInt(sizePerPageLocalStorage, 10);
            }
        }
        // default value is 50
        return 50;
    }

    exportToCsv() {
        const { t } = this.props;
        const { projects, selectedColumns, customPropsTypes } = this.state;
        const headers = {
            billing_group_title: t('StatisticProjects-licenceGroup'),
            project_title: t('StatisticProjects-projectTitle'),
            project_creator: t('StatisticProjects-projectCreator'),
            phase: t('StatisticProjects-phase'),
            start_date: t('StatisticProjects-startDate'),
            end_date: t('StatisticProjects-endDate'),
            created_at: t('StatisticProjects-createdAt'),
            blocks_count: t('StatisticProjects-nbBlocks'),
            notes_count: t('StatisticProjects-nbNotes'),
            picture_blocks_count: t('StatisticProjects-nbPictures'),
            text_blocks_count: t('StatisticProjects-nbTextBlocks'),
            users_count: t('StatisticProjects-nbUsers'),
            reports_count: t('StatisticProjects-nbReports'),
            progress: t('StatisticProjects-progress'),
            last_action_at: t('StatisticProjects-lastAction'),
        };
        customPropsTypes.forEach((customPropName) => {
            headers[`custom_property_${customPropName}`] = customPropName;
        });
        const headersOrder = [];
        Object.keys(selectedColumns).forEach((col) => {
            if (selectedColumns[col] === true) {
                headersOrder.push(col);
            }
        });
        const rows = projects.map((project) => ({
            ...project,
            created_at: moment(project.created_at).format('L'),
            phase: project.phase ? t(`StatisticProjects-phase-${project.phase}`) : null,
            last_action_at: project.last_action_at ? moment(project.last_action_at).format('L') : null,
            start_date: project.start_date ? moment(project.start_date).format('L') : null,
            end_date: project.end_date ? moment(project.end_date).format('L') : null,
            progress: `${project.progress}%`,
        }));
        const fileName = `${t('StatisticProjects-csvExportFilename')}_${moment().format('L')}`;
        Csv.exportCSVFile(headers, headersOrder, rows, fileName);
    }

    async getBillingGroups() {
        const { organization } = this.state;
        const { t } = this.props;
        if (organization) {
            const billingGroups = await BillingGroup.getIncludeDeleted(organization.id);
            const billingGroupsNotDeletedOptions = [];
            const billingGroupsOptions = billingGroups.map((billingGroup) => {
                const billingGroupsOption = {
                    label: billingGroup.is_deleted
                        ? billingGroup.title + ' (' + t('Statistics-is-deleted') + ')'
                        : billingGroup.title,
                    value: billingGroup.id,
                };
                if (!billingGroup.is_deleted) {
                    billingGroupsNotDeletedOptions.push(billingGroupsOption);
                }
                return billingGroupsOption;
            });
            this.setState({ billingGroupsOptions, billingGroupsNotDeletedOptions });
        }
    }

    async getOrganization() {
        const organization = await Organization.getById(this.props.match.params.organization_id);
        await this.setState({ organization });
    }

    getColumns() {
        const { t } = this.props;
        const { selectedColumns, customPropsTypes } = this.state;

        const columns = [];

        // This column is ALWAYS hidden
        // this help us fix a bug with the react-table lib we use
        // the bug is: if you select all columns, refresh the page
        // and come back, and un-select one column, the column will not be un-selected..
        // this FIX the bug! :)
        columns.push({
            dataField: 'id',
            text: 'id',
            hidden: true,
            sort: true,
            onSort: this.onSort,
        });

        columns.push({
            dataField: 'billing_group_title',
            text: t('StatisticProjects-licenceGroup'),
            headerTitle: () => t('StatisticProjects-licenceGroup-tooltip'),
            hidden: selectedColumns.billing_group_title === false,
            sort: true,
            onSort: this.onSort,
        });

        columns.push({
            dataField: 'project_title',
            text: t('StatisticProjects-projectTitle'),
            headerTitle: () => t('StatisticProjects-projectTitle-tooltip'),
            hidden: selectedColumns.project_title === false,
            sort: true,
            onSort: this.onSort,
        });

        columns.push({
            dataField: 'project_creator',
            text: t('StatisticProjects-projectCreator'),
            headerTitle: () => t('StatisticProjects-projectCreator-tooltip'),
            hidden: selectedColumns.project_creator === false,
            sort: true,
            onSort: this.onSort,
        });

        columns.push({
            dataField: 'phase',
            text: t('StatisticProjects-phase'),
            headerTitle: () => t('StatisticProjects-phase-tooltip'),
            hidden: selectedColumns.phase === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                if (!cell) {
                    return null;
                }
                return t(`StatisticProjects-phase-${cell}`);
            },
        });

        columns.push({
            dataField: 'start_date',
            text: t('StatisticProjects-startDate'),
            headerTitle: () => t('StatisticProjects-startDate-tooltip'),
            hidden: selectedColumns.start_date === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return cell ? moment(cell).format('ll') : t('StatisticProjects-startDateEmpty');
            },
        });

        columns.push({
            dataField: 'end_date',
            text: t('StatisticProjects-endDate'),
            headerTitle: () => t('StatisticProjects-endDate-tooltip'),
            hidden: selectedColumns.end_date === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return cell ? moment(cell).format('ll') : t('StatisticProjects-endDateEmpty');
            },
        });

        columns.push({
            dataField: 'created_at',
            text: t('StatisticProjects-createdAt'),
            headerTitle: () => t('StatisticProjects-createdAt-tooltip'),
            hidden: selectedColumns.created_at === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return moment(cell).format('ll');
            },
        });

        columns.push({
            dataField: 'last_action_at',
            text: t('StatisticProjects-lastAction'),
            headerTitle: () => t('StatisticProjects-lastAction-tooltip'),
            hidden: selectedColumns.last_action_at === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return cell ? moment(cell).fromNow() : '';
            },
        });

        columns.push({
            dataField: 'blocks_count',
            text: t('StatisticProjects-nbBlocks'),
            headerTitle: () => t('StatisticProjects-nbBlocks-tooltip'),
            hidden: selectedColumns.blocks_count === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return nFormatter(cell);
            },
        });

        columns.push({
            dataField: 'notes_count',
            text: t('StatisticProjects-nbNotes'),
            headerTitle: () => t('StatisticProjects-nbNotes-tooltip'),
            hidden: selectedColumns.notes_count === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return nFormatter(cell);
            },
        });

        columns.push({
            dataField: 'picture_blocks_count',
            text: t('StatisticProjects-nbPictures'),
            headerTitle: () => t('StatisticProjects-nbPictures-tooltip'),
            hidden: selectedColumns.picture_blocks_count === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return nFormatter(cell);
            },
        });

        columns.push({
            dataField: 'reports_count',
            text: t('StatisticProjects-nbReports'),
            headerTitle: () => t('StatisticProjects-nbReports-tooltip'),
            hidden: selectedColumns.reports_count === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return nFormatter(cell);
            },
        });

        columns.push({
            dataField: 'text_blocks_count',
            text: t('StatisticProjects-nbTextBlocks'),
            headerTitle: () => t('StatisticProjects-nbTextBlocks-tooltip'),
            hidden: selectedColumns.text_blocks_count === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return nFormatter(cell);
            },
        });

        columns.push({
            dataField: 'users_count',
            text: t('StatisticProjects-nbUsers'),
            headerTitle: () => t('StatisticProjects-nbUsers-tooltip'),
            hidden: selectedColumns.users_count === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return nFormatter(cell);
            },
        });

        if (customPropsTypes) {
            customPropsTypes.forEach((customPropName) => {
                columns.push({
                    dataField: `custom_property_${customPropName}`,
                    headerTitle: () => t('StatisticProjects-customProp-tooltip'),
                    hidden: selectedColumns[`custom_property_${customPropName}`] === false,
                    text: customPropName,
                    sort: true,
                    onSort: this.onSort,
                });
            });
        }

        columns.push({
            dataField: 'progress',
            text: t('StatisticProjects-progress'),
            headerTitle: () => t('StatisticProjects-progress-tooltip'),
            hidden: selectedColumns.progress === false,
            sort: true,
            onSort: this.onSort,
            formatter: (cell) => {
                return (
                    <div className="progress">
                        <div
                            aria-valuemax="100"
                            aria-valuemin="0"
                            aria-valuenow={cell}
                            className="progress-bar"
                            style={{ width: `${cell}%` }}
                        >
                            {cell}%
                        </div>
                    </div>
                );
            },
        });

        return columns;
    }

    async onSearchChange(e) {
        const newSearchValue = e.target.value.length ? e.target.value : null;
        await this.setState({ searchValue: newSearchValue });
        this.refreshProjectsWithDebounce();
    }

    async onChangeSelectBillingGroup(selection) {
        await this.setState({ selectedBillingGroups: selection });
        await this.refreshProjects();
    }

    async onChangeSelectProjectStatus(e) {
        await this.setState({ selectedProjectStatusFilter: e.target.value });
        await this.refreshProjects();
    }

    async selectAllBillingGroups() {
        this.onChangeSelectBillingGroup(
            this.state.showAllGroups ? this.state.billingGroupsOptions : this.state.billingGroupsNotDeletedOptions,
        );
    }

    async toggleDeletedBillingGroups() {
        await this.setState({ showAllGroups: !this.state.showAllGroups });
        if (this.state.showAllGroups === false && this.state.selectedBillingGroups) {
            await this.setState({
                selectedBillingGroups: this.state.selectedBillingGroups.filter((selected) =>
                    this.state.billingGroupsNotDeletedOptions.find((elem) => elem.value === selected.value),
                ),
            });
        }
        await this.refreshProjects();
    }

    onSort(field, order) {
        if (localStorage) {
            localStorage.setItem(SORT_FIELD_LOCALSTORAGE_KEY, field);
            localStorage.setItem(SORT_ORDER_LOCALSTORAGE_KEY, order);
        }
    }

    onSizePerPageChange(sizePerPage) {
        if (localStorage) {
            localStorage.setItem(SIZE_PER_PAGE_FIELD_LOCALSTORAGE_KEY, sizePerPage);
        }
    }

    async refreshProjects() {
        const {
            organization,
            searchValue,
            selectedBillingGroups,
            selectedProjectStatusFilter,
            selectedColumns,
            showAllGroups,
            billingGroupsNotDeletedOptions,
        } = this.state;
        if (organization) {
            let billingGroupIds;
            if (!showAllGroups && !selectedBillingGroups) {
                billingGroupIds = billingGroupsNotDeletedOptions
                    ? billingGroupsNotDeletedOptions.map((option) => option.value).join(',')
                    : null;
            } else {
                billingGroupIds = selectedBillingGroups
                    ? selectedBillingGroups.map((option) => option.value).join(',')
                    : null;
            }
            const params = {
                date_filter: selectedProjectStatusFilter,
                search: searchValue,
                billing_group_ids: billingGroupIds,
            };
            const projects = await Organization.getProjectsWithStatistics(organization.id, params);
            const customPropsTypes = [];
            let newSelectedColumns = selectedColumns;
            projects.forEach((project) => {
                if (project.custom_properties) {
                    project.custom_properties.forEach((customProp) => {
                        const customPropKey = `custom_property_${customProp.name}`;
                        if (customProp.values) {
                            project[customPropKey] = customProp.values.join(', ');
                        } else {
                            project[customPropKey] = customProp.value;
                        }
                        if (customPropsTypes.indexOf(customProp.name) === -1) {
                            customPropsTypes.push(customProp.name);
                            if (newSelectedColumns[customPropKey] === undefined) {
                                newSelectedColumns = update(newSelectedColumns, {
                                    [customPropKey]: {
                                        $set: false,
                                    },
                                });
                            }
                        }
                    });
                }
                project.progress =
                    project.notes_count !== null && project.notes_count !== 0
                        ? Math.round((100 * project.notes_finished_count) / project.notes_count)
                        : 0;

                project.project_creator =
                    project.creator_firstname || project.creator_lastname
                        ? `${project.creator_firstname} ${project.creator_lastname}`
                        : project.creator_email;
            });
            this.setState({ projects, customPropsTypes, selectedColumns: newSelectedColumns });
        }
    }

    async toggleColumn(columnTitle, e) {
        const newState = update(this.state, {
            selectedColumns: {
                [columnTitle]: {
                    $set: e.target.checked,
                },
            },
        });
        await this.setState(newState);
        if (localStorage) {
            localStorage.setItem(COLUMNS_SELECTED_LOCALSTORAGE_KEY, JSON.stringify(newState.selectedColumns));
        }
    }

    print() {
        window.print();
    }

    render() {
        const { t } = this.props;
        const {
            selectedProjectStatusFilter,
            searchValue,
            selectedBillingGroups,
            selectedColumns,
            projects,
            customPropsTypes,
            defaultTableOrder,
            billingGroupsOptions,
            organization,
            defaultPageSize,
            billingGroupsNotDeletedOptions,
            showAllGroups,
        } = this.state;

        return (
            <StatisticLayout activeTab="project" organization={organization}>
                <div>
                    <p>{t('StatisticProjects-description')}</p>

                    <div className="row mb-3">
                        <small
                            className="col-md-10"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => this.toggleDeletedBillingGroups()}
                        >
                            {!this.state.showAllGroups
                                ? t('Statistics-showDeletedGroups')
                                : t('Statistics-hideDeletedGroups')}
                        </small>
                    </div>
                    <div className="row mb-1">
                        <div className="col-md-10 mb-2">
                            <Select
                                styles={{
                                    control: (base, state) => {
                                        // base.height = '34px';
                                        base['min-height'] = '34px';
                                        return base;
                                    },
                                    container: (base) => {
                                        base.zIndex = 9;
                                        return base;
                                    },
                                }}
                                isClearable
                                isMulti
                                closeMenuOnSelect={false}
                                options={showAllGroups ? billingGroupsOptions : billingGroupsNotDeletedOptions}
                                value={selectedBillingGroups}
                                onChange={this.onChangeSelectBillingGroup}
                                placeholder={t('StatisticProjects-billingGroupSelectPlaceholder')}
                                noOptionsMessage={() => t('Statistics-noBillingGroups')}
                            />
                        </div>
                        <div className="col-md-2 mb-2">
                            <Button outline color="primary" block onClick={this.selectAllBillingGroups}>
                                {t('StatisticProjects-SelectAllButton')}
                            </Button>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-2 mb-2">
                            <select
                                className="form-control"
                                onChange={this.onChangeSelectProjectStatus}
                                value={selectedProjectStatusFilter}
                            >
                                <option value="all">{t('StatisticProjects-allProjects')}</option>
                                <option value="active">{t('StatisticProjects-projectsInProgress')}</option>
                                <option value="past">{t('StatisticProjects-projectsEnded')}</option>
                                <option value="future">{t('StatisticProjects-futureProjects')}</option>
                            </select>
                        </div>

                        <div className="col-md-8 mb-2">
                            <form className="d-flex justify-content-center">
                                <Input
                                    placeholder={t('StatisticProjects-searchProjectPlaceholder')}
                                    style={{ height: '38px' }}
                                    onChange={this.onSearchChange}
                                    value={searchValue || ''}
                                    type="text"
                                />

                                <div className="ml-auto pl-4">
                                    <ButtonDropdown
                                        isOpen={this.state.dropDownOpen}
                                        style={{ width: '100%' }}
                                        toggle={() =>
                                            this.setState({
                                                dropDownOpen: !this.state.dropDownOpen,
                                            })
                                        }
                                    >
                                        <DropdownToggle caret color="primary">
                                            <i className="os-icon os-icon-ui-55"></i>
                                        </DropdownToggle>
                                        <div
                                            className="dropdown-menu dropdown-menu-right"
                                            style={{ maxHeight: '20rem', overflow: 'scroll' }}
                                        >
                                            <div className="text-center mb-2">
                                                {t('StatisticProjects-displayedColumns')}
                                            </div>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-billing-group-title"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-licenceGroup')}
                                                    checked={selectedColumns.billing_group_title}
                                                    onChange={(e) => this.toggleColumn('billing_group_title', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-project-title"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-projectTitle')}
                                                    checked={selectedColumns.project_title}
                                                    onChange={(e) => this.toggleColumn('project_title', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-project-creator"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-projectCreator')}
                                                    checked={selectedColumns.project_creator}
                                                    onChange={(e) => this.toggleColumn('project_creator', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-phase"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-phase')}
                                                    checked={selectedColumns.phase}
                                                    onChange={(e) => this.toggleColumn('phase', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-start-date"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-startDate')}
                                                    checked={selectedColumns.start_date}
                                                    onChange={(e) => this.toggleColumn('start_date', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-end-date"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-endDate')}
                                                    checked={selectedColumns.end_date}
                                                    onChange={(e) => this.toggleColumn('end_date', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-created-at"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-createdAt')}
                                                    checked={selectedColumns.created_at}
                                                    onChange={(e) => this.toggleColumn('created_at', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-nb-blocks"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-nbBlocks')}
                                                    checked={selectedColumns.blocks_count}
                                                    onChange={(e) => this.toggleColumn('blocks_count', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-nb-notes"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-nbNotes')}
                                                    checked={selectedColumns.notes_count}
                                                    onChange={(e) => this.toggleColumn('notes_count', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-nb-pictures"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-nbPictures')}
                                                    checked={selectedColumns.picture_blocks_count}
                                                    onChange={(e) => this.toggleColumn('picture_blocks_count', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-nb-users"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-nbUsers')}
                                                    checked={selectedColumns.users_count}
                                                    onChange={(e) => this.toggleColumn('users_count', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-nb-text-blocks"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-nbTextBlocks')}
                                                    checked={selectedColumns.text_blocks_count}
                                                    onChange={(e) => this.toggleColumn('text_blocks_count', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-nb-reports"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-nbReports')}
                                                    checked={selectedColumns.reports_count}
                                                    onChange={(e) => this.toggleColumn('reports_count', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-last-action"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-lastAction')}
                                                    checked={selectedColumns.last_action_at}
                                                    onChange={(e) => this.toggleColumn('last_action_at', e)}
                                                />
                                            </li>
                                            <li className="dropdown-item">
                                                <CustomInput
                                                    id="selected-column-progress"
                                                    type="checkbox"
                                                    label={t('StatisticProjects-progress')}
                                                    checked={selectedColumns.progress}
                                                    onChange={(e) => this.toggleColumn('progress', e)}
                                                />
                                            </li>
                                            {customPropsTypes && customPropsTypes.length && (
                                                <div className="text-center mb-2 mt-2">
                                                    {t('StatisticProjects-customPropertiesTitle')}
                                                </div>
                                            )}
                                            {customPropsTypes &&
                                                customPropsTypes.map((customPropName) => (
                                                    <li key={customPropName} className="dropdown-item">
                                                        <CustomInput
                                                            id={`selected-column-custom-prop-${customPropName}`}
                                                            type="checkbox"
                                                            label={customPropName}
                                                            checked={
                                                                selectedColumns[`custom_property_${customPropName}`]
                                                            }
                                                            onChange={(e) =>
                                                                this.toggleColumn(
                                                                    `custom_property_${customPropName}`,
                                                                    e,
                                                                )
                                                            }
                                                        />
                                                    </li>
                                                ))}
                                        </div>
                                    </ButtonDropdown>
                                </div>
                                <div className="ml-auto pl-4">
                                    <button className="btn btn-primary" onClick={this.print}>
                                        <i className="os-icon os-icon-edit-3" />
                                        {t('Statistic-printButton')}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-2  mb-2">
                            <div>
                                <Button block onClick={this.exportToCsv}>
                                    <i class="os-icon os-icon-documents-03" />
                                    {t('StatisticUsers-csvExportButton')}
                                </Button>
                            </div>
                        </div>
                    </div>

                    {!projects && (
                        <div className="d-flex justify-content-center" style={{ height: '300px' }}>
                            <img src={svgLoader} alt="loader" />
                        </div>
                    )}

                    {projects && (
                        <p className="text-muted">
                            {t('StatisticProjects-projectCounts', {
                                count: projects.length,
                            })}
                        </p>
                    )}

                    {projects && (
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            bordered={false}
                            data={projects}
                            tabIndexCell
                            columns={this.getColumns()}
                            pagination={paginationFactory({
                                onSizePerPageChange: this.onSizePerPageChange,
                                sizePerPage: defaultPageSize,
                                sizePerPageList: [
                                    {
                                        text: '50',
                                        value: 50,
                                    },
                                    {
                                        text: '100',
                                        value: 100,
                                    },
                                    {
                                        text: '200',
                                        value: 200,
                                    },
                                ],
                            })}
                            defaultSorted={[defaultTableOrder]}
                            noDataIndication={<div>{t('StatisticProjects-noProjects')}</div>}
                            wrapperClasses="table-responsive table-striped table-lightborder"
                        />
                    )}
                </div>
            </StatisticLayout>
        );
    }
}

export default translate('admin')(StatisticProjects);
