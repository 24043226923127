import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { I18nextProvider, translate } from 'react-i18next';

import RedirectToFirstOrganization from './organization/components/redirect-to-organization/RedirectToFirstOrganization';
import BillingGroup from './organization/components/billing-group/BillingGroup';
import BillingHistory from './organization/components/billing-history/BillingHistory';
import Login from './app/Login';
import FirstLogin from './app/FirstLogin';
import i18n from './i18n';
import Layout from './app/Layout';
import BillingGroupList from './organization/components/billing-group/BillingGroupList';
import TeamList from './organization/components/team/TeamList';
import Team from './organization/components/team/Team';
import Welcome from './organization/components/welcome/Welcome';
import Statistic from './organization/components/statistic/Statistic';
import StatisticProjects from './organization/components/statistic/StatisticProjects';
import ExportApiDatasets from './organization/components/export-api-datasets/ExportApiDatasets';
import FormTemplates from './organization/components/form-template/FormTemplate';
import EditFormTemplate from './organization/components/form-template/EditFormTemplate';
import ProjectTemplate from './organization/components/project-template/ProjectTemplate';
import EditProjectTemplate from './organization/components/project-template/EditProjectTemplate';
import ReportTemplate from './organization/components/report-template/ReportTemplate';
import EditReportTemplate from './organization/components/report-template/EditReportTemplate';
import EnterpriseApiWelcome from './organization/components/enterprise-api/EnterpriseApi';
import EnterpriseApiDocumentation from './organization/components/enterprise-api/EnterpriseApiDocumentation';
import EnterpriseApiKeys from './organization/components/enterprise-api/EnterpriseApiKeys';
import EnterpriseApiEditKey from './organization/components/enterprise-api/EnterpriseApiEditKey';

class RootRouter extends Component {
    componentDidMount() {
        const { t } = this.props;
        document.title = t('clientAdminToolBrowserTitle');
    }
    render() {
        return (
            <Switch>
                <Route exact path="/" component={RedirectToFirstOrganization} />
                <Route exact path="/welcome" component={RedirectToFirstOrganization} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/first-login/:first_login_id" component={FirstLogin} />
                <Route path="/:organization_id/">
                    <Layout>
                        <Route exact path="/:organization_id/welcome" component={Welcome} />
                        <Route exact path="/:organization_id/billing-group" component={BillingGroupList} />
                        <Route
                            exact
                            path="/:organization_id/billing-group/:billing_group_id"
                            component={BillingGroup}
                        />
                        <Route exact path="/:organization_id/team" component={TeamList} />
                        <Route exact path="/:organization_id/team/:team_id" component={Team} />
                        <Route exact path="/:organization_id/billing-history" component={BillingHistory} />
                        <Route exact path="/:organization_id/statistic/user" component={Statistic} />
                        <Route exact path="/:organization_id/statistic/project" component={StatisticProjects} />
                        <Route exact path="/:organization_id/export-api-datasets" component={ExportApiDatasets} />
                        <Route exact path="/:organization_id/form-template" component={FormTemplates} />
                        <Route exact path="/:organization_id/enterprise-api/welcome" component={EnterpriseApiWelcome} />
                        <Route
                            exact
                            path="/:organization_id/enterprise-api/documentation"
                            component={EnterpriseApiDocumentation}
                        />
                        <Route exact path="/:organization_id/enterprise-api/keys" component={EnterpriseApiKeys} />
                        <Route
                            exact
                            path="/:organization_id/enterprise-api/key/create/new"
                            component={EnterpriseApiEditKey}
                        />
                        <Route
                            exact
                            path="/:organization_id/enterprise-api/key/:api_key_id"
                            component={EnterpriseApiEditKey}
                        />
                        <Route
                            exact
                            path="/:organization_id/form-template/:form_template_id"
                            component={EditFormTemplate}
                        />
                        <Route exact path="/:organization_id/project-template" component={ProjectTemplate} />
                        <Route
                            exact
                            path="/:organization_id/project-template/:project_template_id"
                            component={EditProjectTemplate}
                        />
                        <Route exact path="/:organization_id/report-template" component={ReportTemplate} />
                        <Route
                            exact
                            path="/:organization_id/report-user-template/:report_user_template_id"
                            component={EditReportTemplate}
                        />
                        <Route
                            exact
                            path="/:organization_id/report-custom-template/:report_custom_template_id"
                            component={EditReportTemplate}
                        />
                        <Route
                            exact
                            path="/:organization_id/note-export-template/:report_note_export_template_id"
                            component={EditReportTemplate}
                        />
                    </Layout>
                </Route>
            </Switch>
        );
    }
}

const RootRouterWithI18n = translate('admin')(RootRouter);

function Root(props) {
    const { history } = props;
    return (
        <I18nextProvider i18n={i18n}>
            <Router history={history}>
                <RootRouterWithI18n />
            </Router>
        </I18nextProvider>
    );
}

Root.propTypes = {
    history: PropTypes.object.isRequired,
};

export default Root;
