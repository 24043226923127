import React, { Component } from 'react';
import update from 'immutability-helper';
import { Link } from 'react-router-dom';

import { translate } from 'react-i18next';
import Organization from '../../api/Organization';
import Team from '../../api/Team.js';
import TeamModal from './TeamModal';
import Auth from '../../../app/Auth';

class TeamList extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {
            teams: [],
            newTeamModalOpen: false,
            currentOrganization: null,
            isOrgaAdmin: false,
        };
        this.toggleNewTeamModal = this.toggleNewTeamModal.bind(this);
        this.createTeam = this.createTeam.bind(this);
        this.getTeams = this.getTeams.bind(this);
        this.getCurrentOrganization = this.getCurrentOrganization.bind(this);
        this.getRights = this.getRights.bind(this);
    }

    toggleNewTeamModal() {
        this.setState({ newTeamModalOpen: !this.state.newTeamModalOpen });
    }

    createTeam(organizationId, title) {
        Team.create(organizationId, { title }).then((team) => {
            team.nb_user = 0;

            const newState = update(this.state, {
                teams: { $push: [team] },
                newTeamModalOpen: { $set: false },
            });

            this.setState(newState);
        });
    }

    async getTeams() {
        const currentOrganizationId = this.props.match.params.organization_id;
        const teams = await Team.get(currentOrganizationId);
        this.setState({ teams });
    }

    async getCurrentOrganization() {
        const currentOrganizationId = this.props.match.params.organization_id;
        const organization = await Organization.getById(currentOrganizationId);
        this.setState({ organization });
    }

    async getRights() {
        const rights = await Auth.getRights();
        const isOrgaAdmin =
            rights.organizations.find((o) => o.id === this.props.match.params.organization_id) !== undefined;
        this.setState({ isOrgaAdmin });
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        this.getTeams();
        this.getCurrentOrganization();
        this.getRights();
    }

    render() {
        const { t } = this.props;
        const currentOrganizationId = this.props.match.params.organization_id;

        const teamElement = this.state.teams.map((team, index) => {
            return (
                <tr key={team.id}>
                    <td>{team.title}</td>
                    <td>{team.nb_user}</td>
                    <td>
                        <Link
                            to={{
                                pathname: `/${currentOrganizationId}/team/${team.id}`,
                                state: { teamTitle: team.title },
                            }}
                        >
                            <button className="mr-2 mb-2 btn btn-sm btn-primary">
                                {t('BillingGroupModal-seeButton')}
                            </button>
                        </Link>
                    </td>
                </tr>
            );
        });

        if (!this.state.organization) return <div />;

        return (
            <div className="element-wrapper">
                <h6 className="element-header">
                    {this.state.organization && this.state.organization.title} / {t('TeamList-title')}
                </h6>
                <div className="element-box">
                    <h5 className="form-header">{this.state.organization.title}</h5>
                    <div className="form-desc">{t('TeamList-sentence')}</div>

                    {this.state.isOrgaAdmin ? (
                        <div className="controls-above-table">
                            <button className="btn btn-sm btn-primary" onClick={this.toggleNewTeamModal}>
                                {t('common-create')}
                            </button>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="table-responsive">
                        <table className="table table-lightborder">
                            <thead>
                                <tr>
                                    <th>{t('TeamList-name-column')}</th>
                                    <th>{t('TeamList-nb-users-column')}</th>
                                    <th>{t('TeamList-manage-column')}</th>
                                </tr>
                            </thead>
                            <tbody>{teamElement}</tbody>
                        </table>
                    </div>
                </div>

                <TeamModal
                    isOpen={this.state.newTeamModalOpen}
                    toggle={this.toggleNewTeamModal}
                    organization={this.state.organization}
                    createTeam={this.createTeam}
                />
            </div>
        );
    }
}

export default translate('admin')(TeamList);
