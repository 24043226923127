import React, { Component } from 'react';
import { translate, Trans } from 'react-i18next';
import Organization from '../../api/Organization';

import EnterpriseApiLayout from './EnterpriseApiLayout';

class FormTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async getOrganization() {
        const organization = await Organization.getById(this.props.match.params.organization_id);
        await this.setState({ organization });
    }

    componentDidMount() {
        this.getOrganization();
    }

    render() {
        const { t } = this.props;
        const { organization } = this.state;

        return (
            <EnterpriseApiLayout organization={organization} activeTab="welcome">
                <h5 className="form-header">{t('EnterpriseApi-Welcome-title')}</h5>
                <div className="form-desc">{t('EnterpriseApi-Welcome-sentence')}</div>
                <p>{t('EnterpriseApi-Welcome-introduction')}</p>
                <p>
                    <ul>
                        <li>{t('EnterpriseApi-Welcome-goal-1')}</li>
                        <li>{t('EnterpriseApi-Welcome-goal-2')}</li>
                        <li>{t('EnterpriseApi-Welcome-goal-3')}</li>
                    </ul>
                </p>

                <p>{t('EnterpriseApi-Welcome-conclusion-1')}</p>
                <p>
                    <Trans i18nKey="EnterpriseApi-Welcome-conclusion-2">
                        If you have any questions, you can contact us by email at{' '}
                        <a href="mailto:support@bulldozair.com">support@bulldozair.com</a>.
                    </Trans>
                </p>
            </EnterpriseApiLayout>
        );
    }
}

export default translate('admin')(FormTemplateList);
