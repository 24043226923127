import React, { Component } from 'react';
import $ from 'jquery';
import { translate } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import get from 'get-value';
import moment from 'moment';
import Auth from './Auth';
import ReactGA from 'react-ga';
import Intercom from './Intercom';
import Organization from '../organization/api/Organization';
import logoSquareWhite from '../theme/img/bulldozair-logo-square-white.svg';
import bulldozairLogo from '../theme/img/new-bulldozair-logo.svg';
import defaultUserImg from '../theme/img/default-user-picture.svg';
import { Plug, FileEarmarkLock2, Boxes, People, ClipboardCheck, Clipboard } from 'react-bootstrap-icons';

const organizationSelectorStyle = {
    paddingTop: '20px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '0px',
};

const NavTitles = ({ logout, rights, t, hasRightToSeeStatistics, currentOrganizationId }) => {
    const isOrgaAdmin = rights.organizations.find((o) => o.id === currentOrganizationId) !== undefined;
    const adminLicense = rights.organizations.length > 0 || rights.billingGroups.length > 0;
    const adminTeam = rights.organizations.length > 0 || rights.teams.length > 0;

    return (
        <ul className="main-menu">
            <li>
                <Link to={`/${currentOrganizationId}/welcome`}>
                    <div className="icon-w">
                        <div className="os-icon os-icon-user-male-circle" />
                    </div>
                    <span>{t('Layout-sidebar-home')}</span>
                </Link>
            </li>
            {adminLicense && (
                <li>
                    <Link to={`/${currentOrganizationId}/billing-group`}>
                        <div className="icon-w">
                            <div className="os-icon os-icon-cv-2" />
                        </div>
                        <span>{t('Layout-sidebar-my-licenses')}</span>
                    </Link>
                </li>
            )}
            {adminTeam && (
                <li>
                    <Link to={`/${currentOrganizationId}/team`}>
                        <div className="icon-w">
                            <People size={30} />
                        </div>
                        <span>{t('Layout-sidebar-my-work-group')}</span>
                    </Link>
                </li>
            )}
            {isOrgaAdmin ? (
                <li>
                    <Link to={`/${currentOrganizationId}/form-template`}>
                        <div className="icon-w">
                            <ClipboardCheck size={30} />
                        </div>
                        <span>{t('Layout-sidebar-form-template')}</span>
                    </Link>
                </li>
            ) : null}
            {isOrgaAdmin ? (
                <li>
                    <Link to={`/${currentOrganizationId}/project-template`}>
                        <div className="icon-w">
                            <Boxes size={30} />
                        </div>
                        <span>{t('Layout-sidebar-project-template')}</span>
                    </Link>
                </li>
            ) : null}
            {isOrgaAdmin ? (
                <li>
                    <Link to={`/${currentOrganizationId}/report-template`}>
                        <div className="icon-w">
                            <Clipboard size={30} />
                        </div>
                        <span>{t('Layout-sidebar-report-template')}</span>
                    </Link>
                </li>
            ) : null}
            {isOrgaAdmin ? (
                <li>
                    <Link to={`/${currentOrganizationId}/billing-history`}>
                        <div className="icon-w">
                            <div className="os-icon os-icon-bar-chart-stats-up" />
                        </div>
                        <span>{t('Layout-sidebar-billing-history')}</span>
                    </Link>
                </li>
            ) : null}
            {isOrgaAdmin && hasRightToSeeStatistics ? (
                <li>
                    <Link to={`/${currentOrganizationId}/statistic/user`}>
                        <div className="icon-w">
                            <div className="os-icon os-icon-pie-chart-1" />
                        </div>
                        <span>{t('Layout-sidebar-statistic')}</span>
                    </Link>
                </li>
            ) : null}

            <li>
                <Link to={`/${currentOrganizationId}/enterprise-api/welcome`}>
                    <div className="icon-w">
                        <FileEarmarkLock2 size={30} />
                    </div>
                    <span>{t('Layout-sidebar-enterprise-api')}</span>
                </Link>
            </li>

            {isOrgaAdmin ? (
                <li>
                    <Link to={`/${currentOrganizationId}/export-api-datasets`}>
                        <div className="icon-w">
                            <Plug size={30} />
                        </div>
                        <span>{t('Layout-sidebar-export-api-datasets')}</span>
                    </Link>
                </li>
            ) : null}
            <li>
                <a href={process.env.REACT_APP_WEBAPP_URL} style={{ cursor: 'pointer' }}>
                    <div className="icon-w">
                        <div className="os-icon os-icon-arrow-left5" />
                    </div>
                    <span>{t('Layout-sidebar-bulldozair')}</span>
                </a>
            </li>
            <li>
                <Link onClick={logout} style={{ cursor: 'pointer' }}>
                    <div className="icon-w">
                        <div className="os-icon os-icon-signs-11" />
                    </div>
                    <span>{t('Layout-sidebar-logout')}</span>
                </Link>
            </li>
        </ul>
    );
};

class NavbarComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            hasRightToSeeStatistics: false,
            rights: {
                organizations: [],
                billingGroups: [],
                teams: [],
            },
        };
        this.init = this.init.bind(this);
        this.logout = this.logout.bind(this);
        this.getMyself = this.getMyself.bind(this);
        this.getOrganizations = this.getOrganizations.bind(this);
        this.initAnalytics = this.initAnalytics.bind(this);
        this.selectOrganization = this.selectOrganization.bind(this);
    }

    logout() {
        Auth.logout();
        this.props.history.push('/login');
    }

    async getOrganizations() {
        const organizations = await Organization.get();
        this.setState({ organizations });
    }

    async selectOrganization(e) {
        e.preventDefault();
        this.props.history.push(`/${e.target.value}/welcome`);
    }

    activateMenu() {
        $('.mobile-menu-trigger').on('click', function() {
            $('.menu-mobile .menu-and-user').slideToggle(200, 'swing');
            return false;
        });

        // INIT MENU TO ACTIVATE ON HOVER
        let menu_timer;
        $('.menu-activated-on-hover ul.main-menu > li.has-sub-menu').mouseenter(function() {
            const $elem = $(this);
            clearTimeout(menu_timer);
            $elem
                .closest('ul')
                .addClass('has-active')
                .find('> li')
                .removeClass('active');
            $elem.addClass('active');
        });
        $('.menu-activated-on-hover ul.main-menu > li.has-sub-menu').mouseleave(function() {
            const $elem = $(this);
            menu_timer = setTimeout(function() {
                $elem
                    .removeClass('active')
                    .closest('ul')
                    .removeClass('has-active');
            }, 30);
        });

        // INIT MENU TO ACTIVATE ON CLICK
        $('.menu-activated-on-click li.has-sub-menu > a').on('click', function(event) {
            const $elem = $(this).closest('li');
            if ($elem.hasClass('active')) {
                $elem.removeClass('active');
            } else {
                $elem
                    .closest('ul')
                    .find('li.active')
                    .removeClass('active');
                $elem.addClass('active');
            }
            return false;
        });
    }

    async onVisibilityChange() {
        // send event only if the last event was more than 1 minute ago
        if (!document.hidden && Date.now() - this.lastFocusEvent > 1000 * 60) {
            this.lastFocusEvent = Date.now();
            await Auth.sendEvent(null, 'app_opened');
        }
    }

    activateVisibilityChangeDetection() {
        let visibilityChange = null;

        if (typeof document.hidden !== 'undefined') {
            visibilityChange = 'visibilitychange';
        } else if (typeof document.msHidden !== 'undefined') {
            visibilityChange = 'msvisibilitychange';
        } else if (typeof document.webkitHidden !== 'undefined') {
            visibilityChange = 'webkitvisibilitychange';
        } else {
            // not compatible, return
            return;
        }

        // handle tab losing focus
        if (typeof document.addEventListener !== 'undefined' && typeof document.hidden !== 'undefined') {
            document.addEventListener(visibilityChange, this.onVisibilityChange, false);
        }

        // handle browser window losing focus
        window.onfocus = () => {
            this.onVisibilityChange('focus');
        };
    }

    generateBreadCrumb(pathname) {
        let paths = pathname.split('/');

        // remove the last element if there was a / at the end of the pathname
        paths = paths[paths.length - 1] === '' ? paths.slice(0, paths.length - 1) : paths;

        // remove the first element if the second one is an empty string which means that we are in the root of the website
        paths = paths[1] === '' ? paths.slice(1) : paths;

        if (paths.length && paths[paths.length - 1] == 'welcome') {
            paths = [''];
        }

        const breadcrumb = paths.map((path, index) => {
            if (index === 0) {
                return (
                    <li className="breadcrumb-item" key={index}>
                        <NavLink to="/" activeClassName="active">
                            {this.props.t('Layout-breadcrumb-home')}
                        </NavLink>
                    </li>
                );
            }

            // Build the path for the current URL
            const url = paths.slice(0, index + 1).join('/');

            if (path.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
                path = this.props.t('Layout-breadcrumb-current-item');
            } else {
                path = this.props.t(`Layout-breadcrumb-${path}`);
            }

            // HTML structure for every link except the first
            return (
                <li className="breadcrumb-item" key={index}>
                    <Link to={url}>{path}</Link>
                </li>
            );
        });

        // Return a list of links
        return <ul className="breadcrumb">{breadcrumb}</ul>;
    }

    async getMyself() {
        const { i18n } = this.props;
        const [user, rights] = await Promise.all([Auth.getUser(), Auth.getRights()]);
        Intercom.setUser(user);
        if (user.language) {
            const language = user.language.substr(0, 2);
            i18n.changeLanguage(language);
            moment.locale(language);
        }

        // Only organization admin + has feature flag can see statistics tab
        const currentOrganizationId = this.props.match.params.organization_id;
        const isOrgaAdmin = rights.organizations.find((o) => o.id === currentOrganizationId) !== undefined;
        const hasRightToSeeStatistics = isOrgaAdmin && user.features.includes('client-admin-statistics');

        this.setState({
            user,
            hasRightToSeeStatistics,
            rights: {
                organizations: rights.organizations,
                billingGroups: rights.billingGroups,
                teams: rights.teams,
            },
        });
    }

    checkIfValidUUID(str) {
        // Regular expression to check if string is a valid UUID
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

        return regexExp.test(str);
    }

    init() {
        // Make sure all 401 requests are intercepted and redirects to logout
        Auth.intercept401Requests(this.logout);
        // Active the Jquery animation on the menu
        this.activateMenu();
        // Check if the user is authenticated
        if (!Auth.authenticate()) {
            return this.logout();
        }
        // This helps users that have "/billing-history" as bookmark
        // This will redirect to home instead of displaying buggy page
        const currentOrganizationId = this.props.match.params.organization_id;
        if (!this.checkIfValidUUID(currentOrganizationId)) {
            this.props.history.push('/');
            return;
        }
        // Init stat events related events
        this.initAnalytics();
        this.activateVisibilityChangeDetection();
        // Get data
        this.getMyself();
        this.getOrganizations();
    }

    async componentDidMount() {
        this.lastFocusEvent = 0;
        this.init();
    }

    initAnalytics() {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        }
        this.unlisten = this.props.history.listen(async (location, action) => {
            if (location.pathname) {
                const pathName = location.pathname.replace(
                    /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/,
                    ':id',
                );
                let routeName = null;
                switch (pathName) {
                    case '/':
                        routeName = 'ClientAdmin.Base';
                        break;
                    case '/first-login/:id':
                        routeName = 'ClientAdmin.FirstLogin';
                        break;
                    case '/login':
                        routeName = 'ClientAdmin.Login';
                        break;
                    case '/welcome':
                        routeName = 'ClientAdmin.Welcome';
                        break;
                    case '/billing-group':
                        routeName = 'ClientAdmin.BillingGroupList';
                        break;
                    case '/billing-group/:id':
                        routeName = 'ClientAdmin.BillingGroupView';
                        break;
                    case '/team':
                        routeName = 'ClientAdmin.TeamView';
                        break;
                    case '/team/:id':
                        routeName = 'ClientAdmin.TeamView';
                        break;
                    case '/billing-history':
                        routeName = 'ClientAdmin.BillingHistory';
                        break;
                    case '/statistic/user':
                        routeName = 'ClientAdmin.StatisticsUsers';
                        break;
                    case '/statistic/project':
                        routeName = 'ClientAdmin.StatisticsProjects';
                        break;
                    case '/export-api-datasets':
                        routeName = 'ClientAdmin.OrganizationExportApiCredentials';
                        break;
                    default:
                        routeName = pathName;
                }
                // use Google analytics if defined in config
                if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
                    ReactGA.set({ page: routeName });
                    ReactGA.pageview(routeName);
                }
                // send bulldozair internal event if user is logged in
                if (Auth.authenticate()) {
                    await Auth.sendEvent(routeName);
                }
            }
        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {
        const { t } = this.props;
        const { organizations } = this.state;
        const userPicture = get(this.state, 'user.file_url', defaultUserImg) || defaultUserImg;
        const currentOrganizationId = this.props.match.params.organization_id;

        return (
            <div className="menu-side with-side-panel">
                <div className="layout-w">
                    <div className="menu-mobile menu-activated-on-click color-scheme-dark">
                        <div className="mm-logo-buttons-w">
                            <Link className="mm-logo" to="/">
                                <img src={logoSquareWhite} alt="Bulldozair logo" />
                            </Link>
                            <div className="mm-buttons">
                                <div className="mobile-menu-trigger">
                                    <div className="os-icon os-icon-hamburger-menu-1" />
                                </div>
                            </div>
                        </div>
                        <div className="menu-and-user">
                            <div className="logged-user-w">
                                <div className="avatar-w">
                                    {this.state.user && <img alt="Avatar" src={userPicture} />}
                                </div>
                                <div className="logged-user-info-w">
                                    <div className="logged-user-name">
                                        {this.state.user && `${this.state.user.firstname} ${this.state.user.lastname}`}
                                    </div>
                                    <div className="logged-user-role">{t('Layout-sidebar-administrator')}</div>
                                </div>
                            </div>

                            <NavTitles
                                currentOrganizationId={currentOrganizationId}
                                hasRightToSeeStatistics={this.state.hasRightToSeeStatistics}
                                logout={this.logout}
                                rights={this.state.rights}
                                isOrgaAdmin={this.state.isOrgaAdmin}
                                t={t}
                            />
                        </div>
                    </div>

                    <div className="desktop-menu menu-side-w menu-activated-on-click">
                        <div className="logo-w">
                            <Link className="logo" to={`/${currentOrganizationId}/welcome`}>
                                <div className="logo-element">
                                    <img id="bulldozair-logo" src={bulldozairLogo} alt="Bulldozair logo" />
                                </div>
                            </Link>
                        </div>

                        <div className="menu-and-user">
                            <div className="logged-user-w">
                                <div className="logged-user-i">
                                    <div className="avatar-w">
                                        {this.state.user && <img alt="Avatar" src={userPicture} />}
                                    </div>
                                    <div className="logged-user-info-w">
                                        <div className="logged-user-name">
                                            {this.state.user &&
                                                `${this.state.user.firstname} ${this.state.user.lastname}`}
                                        </div>
                                        <div className="logged-user-role">{t('Layout-sidebar-administrator')}</div>
                                    </div>
                                </div>
                            </div>

                            {organizations && organizations.length > 1 && (
                                <div style={organizationSelectorStyle}>
                                    <select className="form-control" onChange={this.selectOrganization}>
                                        {organizations.map((organization) => (
                                            <option
                                                selected={organization.id === currentOrganizationId}
                                                value={organization.id}
                                            >
                                                {organization.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            <NavTitles
                                currentOrganizationId={currentOrganizationId}
                                hasRightToSeeStatistics={this.state.hasRightToSeeStatistics}
                                logout={this.logout}
                                rights={this.state.rights}
                                isOrgaAdmin={this.state.isOrgaAdmin}
                                t={t}
                            />
                        </div>
                    </div>

                    <div className="content-w">
                        <div className="content-i">
                            <div className="content-box">
                                {this.props.children}
                                <div className="display-type" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(translate('admin')(NavbarComponent));
