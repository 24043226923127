import React, { Component } from 'react';
import { Form, FormGroup, Button, Alert, Label } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { translate } from 'react-i18next';
import Auth from './Auth';
import Organization from '../organization/api/Organization';
import deviceDetector from 'ua-device-detector';
import packageJson from '../../package.json';
import bulldozairLogo from '../theme/img/new-bulldozair-logo.svg';

class LoginComponent extends Component {
    constructor(props) {
        super(props);

        this._notificationSystem = null;
        this.state = {
            email: '',
            password: '',
            errored: false,
        };

        if (window.navigator.language === 'fr') {
            this.props.i18n.changeLanguage('fr');
        } else {
            this.props.i18n.changeLanguage('en');
        }

        this.update = this.update.bind(this);
        this.login = this.login.bind(this);
    }

    async login(e) {
        e.preventDefault();
        this.setState({ loading: true, errored: false });
        try {
            const deviceInfo = deviceDetector.parseUserAgent(navigator.userAgent);
            const device = {
                name: deviceInfo.browser,
                platform: 'CLIENT_ADMIN_TOOL',
                os: deviceInfo.os,
                app_version: packageJson.version,
                system_version: deviceInfo.os_version,
            };
            await Auth.login(this.state.email, this.state.password, device);
            await Auth.getUser();
            const organizations = await Organization.get();
            this.props.history.replace(`/${organizations[0].id}/welcome`);
        } catch (e) {
            console.error(e);
            this.setState({ loading: false, errored: true });
        }
    }

    update(field, value) {
        this.setState({
            [field]: value,
        });
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
    }

    render() {
        const { t } = this.props;
        const { loading, errored } = this.state;

        return (
            <div className="background-bz auth-wrapper">
                <div className="all-wrapper">
                    <div className="auth-box-w">
                        <div className="logo-w mt-2 mb-2" style={{ padding: '12%' }}>
                            <img alt="" style={{ maxWidth: '320px' }} fill="#fffff" src={bulldozairLogo} />
                        </div>
                        <h4 className="auth-header">{t('Login-title')}</h4>
                        <Form onSubmit={this.login}>
                            {errored && <Alert color="danger">{t('login-failed-message')}</Alert>}
                            <FormGroup>
                                <Label>{t('Login-email')}</Label>
                                <input
                                    className="form-control"
                                    placeholder={t('Login-email')}
                                    type="text"
                                    onChange={(e) => this.update('email', e.target.value)}
                                />
                                <div className="pre-icon os-icon os-icon-user-male-circle"></div>
                            </FormGroup>
                            <FormGroup>
                                <Label>{t('Login-password')}</Label>
                                <input
                                    className="form-control"
                                    placeholder={t('Login-password')}
                                    type="password"
                                    onChange={(e) => this.update('password', e.target.value)}
                                />
                                <div className="pre-icon os-icon os-icon-fingerprint"></div>
                            </FormGroup>
                            <div className="buttons-w">
                                <Button color="primary" block disabled={loading}>
                                    {t('Login-login-button')}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default translate('admin')(LoginComponent);
