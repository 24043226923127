import React, { Component } from 'react';
import update from 'immutability-helper';
import { Row, Col, Button, Table } from 'reactstrap';
import { translate } from 'react-i18next';
import Team from '../../api/Team.js';
import TeamFormTemplateReportModal from './TeamFormTemplateReportModal';

class FormTemplateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formTemplates: [],
            isOpen: false,
        };
        this.deleteFormTemplate = this.deleteFormTemplate.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openCsvReportModal = this.openCsvReportModal.bind(this);
    }

    async componentDidMount() {
        const [formTemplates, projects] = await Promise.all([
            Team.getFormTemplates(this.props.team.id),
            Team.getProjects(this.props.team.id),
        ]);
        this.setState({ formTemplates, projects });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.team.id !== nextProps.team.id) {
            this.setState({ formTemplates: [] });
            Team.getFormTemplates(nextProps.team.id).then((formTemplates) => this.setState({ formTemplates }));
        }
    }

    deleteFormTemplate(index, formTemplate) {
        Team.removeFormTemplate(this.props.team.id, formTemplate.id).then(() => {
            const newState = update(this.state, {
                formTemplates: { $splice: [[index, 1]] },
            });

            this.setState(newState);
        });
    }

    toggle() {
        const newState = {
            isOpen: !this.state.isOpen,
        };
        if (newState.isOpen === false) {
            newState.selectedFormTemplateId = null;
        }
        this.setState(newState);
    }

    openCsvReportModal(formTemplate) {
        this.setState({
            isOpen: true,
            selectedFormTemplateId: formTemplate.id,
        });
    }

    render() {
        const { t } = this.props;
        const { isOpen, projects } = this.state;
        const listFormTemplates = this.state.formTemplates.map((formTemplate, index) => {
            return (
                <tr key={formTemplate.id}>
                    <td>{formTemplate.title}</td>
                    <td>
                        <Button outline color="secondary" onClick={() => this.openCsvReportModal(formTemplate)}>
                            {t('TeamFormTemplates-generateReportButton')}
                        </Button>
                    </td>
                    <td>
                        <Button outline color="danger" onClick={() => this.deleteFormTemplate(index, formTemplate)}>
                            {t('common-delete')}
                        </Button>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <Row>
                    <Col md="12">
                        <h4>{t('TeamFormTemplates-title')}</h4>
                        <Row>
                            <Col md="12">{t('TeamFormTemplates-explanation')}</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>{t('common-name')}</th>
                                            <th></th>
                                            <th>{t('common-delete')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>{listFormTemplates}</tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <TeamFormTemplateReportModal
                    teamId={this.props.team.id}
                    formTemplateId={this.state.selectedFormTemplateId}
                    isOpen={isOpen}
                    toggle={this.toggle}
                    projects={projects}
                />
            </div>
        );
    }
}

export default translate('admin')(FormTemplateComponent);
