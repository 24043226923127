import React from 'react';
import {
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Col,
    FormText,
    Input,
    Label,
} from 'reactstrap';
import Select from 'react-select';
import { translate } from 'react-i18next';
import moment from 'moment';
import Team from '../../api/Team.js';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import '../statistic/react-dates-overrides.css';

const OLDEST_FORM = '2012-01-01';

class TeamFormTemplateReportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectIds: [],
            generating: false,
            errored: false,
        };
        this.onFocusChangeDatePicker = this.onFocusChangeDatePicker.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.selectAllProjects = this.selectAllProjects.bind(this);
        this.handleChangeProjects = this.handleChangeProjects.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this);
    }

    onFocusChangeDatePicker(datePickerFocusedInput) {
        this.setState({ datePickerFocusedInput });
    }

    selectAllProjects() {
        this.setState({
            projectIds: this.props.projects.map((p) => ({
                label: p.title,
                value: p.id,
            })),
        });
    }

    handleChangeProjects(projectIds) {
        this.setState({
            projectIds,
        });
    }

    onDatesChange({ startDate, endDate }) {
        this.setState({ startDate, endDate });
    }

    isOutsideRange(day) {
        return moment(day).isAfter(moment()) || moment(day).isBefore(moment(OLDEST_FORM));
    }

    async generateReport() {
        if (this.state.projectIds.length === 0 || !this.state.startDate || !this.state.endDate) {
            return this.setState({ errored: true });
        } else {
            await this.setState({ errored: false, generating: true });
        }
        try {
            const projectIds = this.state.projectIds.map((p) => p.value);
            const startDate = this.state.startDate.startOf('day').valueOf();
            const endDate = this.state.endDate.endOf('day').valueOf();
            await Team.generateCsvFormReport(
                this.props.teamId,
                this.props.formTemplateId,
                projectIds,
                startDate,
                endDate,
            );
        } catch (e) {
            console.error(e);
            this.setState({ serverError: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.formTemplateId !== prevProps.formTemplateId) {
            this.setState({
                projectIds: [],
                startDate: null,
                endDate: null,
                generating: false,
                errored: false,
            });
        }
    }

    render() {
        const options = this.props.projects
            ? this.props.projects.map((p) => ({
                  label: p.title,
                  value: p.id,
              }))
            : [];
        const { t } = this.props;
        const { generating, errored, serverError } = this.state;
        return (
            <div>
                <Modal isOpen={this.props.isOpen} size="lg" toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}>{t('GenerateFormReportModal-title')}</ModalHeader>
                    <ModalBody>
                        {errored === true && <Alert color="danger">{t('GenerateFormReportModal-errorMessage')}</Alert>}
                        {serverError === true && (
                            <Alert color="danger">{t('GenerateFormReportModal-serverErrorMessage')}</Alert>
                        )}
                        {generating === false && (
                            <Form>
                                <FormGroup row>
                                    <Label for="orgaName" sm={2}>
                                        {t('GenerateFormReportModal-projectsLabel')}
                                    </Label>
                                    <Col sm={10}>
                                        <Select
                                            name="form-field-name"
                                            value={this.state.projectIds}
                                            isMulti
                                            placeholder={t('GenerateFormReportModal-projectSelectPlaceholder')}
                                            closeMenuOnSelect={false}
                                            onChange={this.handleChangeProjects}
                                            options={options}
                                            styles={{
                                                container: (base, state) => {
                                                    base.zIndex = '3';
                                                    return base;
                                                },
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    maxHeight: '10rem',
                                                    overflowY: 'scroll',
                                                }),
                                            }}
                                        />
                                        <Button
                                            className="mt-2"
                                            size="sm"
                                            outline
                                            color="secondary"
                                            onClick={this.selectAllProjects}
                                        >
                                            {t('GenerateFormReportModal-selectAllProjectsButton')}
                                        </Button>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="orgaName" sm={2}>
                                        {t('GenerateFormReportModal-dateRangeLabel')}
                                    </Label>
                                    <Col sm={10}>
                                        <DateRangePicker
                                            hideKeyboardShortcutsPanel
                                            small
                                            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                            startDateId="start_date_id" // PropTypes.string.isRequired,
                                            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                            endDateId="end_date_id" // PropTypes.string.isRequired,
                                            onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
                                            startDatePlaceholderText={t('GenerateFormReportModal-startDateText')}
                                            endDatePlaceholderText={t('GenerateFormReportModal-endDateText')}
                                            focusedInput={this.state.datePickerFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                            onFocusChange={this.onFocusChangeDatePicker} // PropTypes.func.isRequired,
                                            isOutsideRange={this.isOutsideRange}
                                            styles={{
                                                zIndex: 10,
                                            }}
                                            minDate={moment(OLDEST_FORM)}
                                            maxDate={moment()}
                                        />
                                        <FormText color="muted">{t('GenerateFormReportModal-datesFormText')}</FormText>
                                    </Col>
                                </FormGroup>
                            </Form>
                        )}
                        {generating === true && (
                            <Alert color="primary">{t('GenerateFormReportModal-generatingReportMessage')}</Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {generating === false && (
                            <Button color="primary" onClick={this.generateReport}>
                                {t('GenerateFormReportModal-generateButton')}
                            </Button>
                        )}{' '}
                        <Button color="secondary" onClick={this.props.toggle}>
                            {t('GenerateFormReportModal-closeButton')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default translate('admin')(TeamFormTemplateReportModal);
