import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table } from 'reactstrap';

const BillingTableOneMonth = ({ month, t }) => {
    const internals = [];
    const subcontrators = [];
    const observators = [];
    let internalTotal = 0;
    let subonctractorTotal = 0;
    let observatorTotal = 0;

    month.billingGroups.forEach((billingGroup) => {
        if (billingGroup.billingGroup.license == 'internal') {
            internals.push(billingGroup);
            internalTotal += billingGroup.users.length;
        }
        if (billingGroup.billingGroup.license == 'subcontractor') {
            subcontrators.push(billingGroup);
            subonctractorTotal += billingGroup.users.length;
        }
        if (billingGroup.billingGroup.license == 'observator') {
            observators.push(billingGroup);
            observatorTotal += billingGroup.users.length;
        }
    });

    return (
        <div>
            {internals.length > 0 && (
                <div>
                    <h5 className="form-header">{t('BillingHistoryTableOneMonth-internalLicenses')}</h5>
                    <Table striped>
                        <thead>
                            <tr>
                                <th style={{ width: '50%' }}>{t('BillingHistoryTableOneMonth-billingGroup-column')}</th>
                                <th style={{ width: '50%' }}>
                                    {t('BillingHistoryTableOneMonth-numberOfLicenses-column')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {internals.map((billingGroup) => {
                                return (
                                    <tr key={billingGroup.billingGroup.id}>
                                        <td>{billingGroup.billingGroup.title}</td>
                                        <td>{billingGroup.users.length}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td>
                                    <strong>{t('BillingHistoryTableOneMonth-total')}</strong>
                                </td>
                                <td>{internalTotal}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}

            {subcontrators.length > 0 && (
                <div>
                    <h5 className="form-header">{t('BillingHistoryTableOneMonth-subcontractorLicenses')}</h5>
                    <Table striped>
                        <thead>
                            <tr>
                                <th style={{ width: '50%' }}>{t('BillingHistoryTableOneMonth-billingGroup-column')}</th>
                                <th style={{ width: '50%' }}>
                                    {t('BillingHistoryTableOneMonth-numberOfLicenses-column')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {subcontrators.map((billingGroup) => {
                                return (
                                    <tr>
                                        <td>{billingGroup.billingGroup.title}</td>
                                        <td>{billingGroup.users.length}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td>
                                    <strong>{t('BillingHistoryTableOneMonth-total')}</strong>
                                </td>
                                <td>{subonctractorTotal}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}

            {observators.length > 0 && (
                <div>
                    <h5 className="form-header">{t('BillingHistoryTableOneMonth-observatorLicenses')}</h5>
                    <Table striped>
                        <thead>
                            <tr>
                                <th style={{ width: '50%' }}>{t('BillingHistoryTableOneMonth-billingGroup-column')}</th>
                                <th style={{ width: '50%' }}>
                                    {t('BillingHistoryTableOneMonth-numberOfLicenses-column')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {observators.map((billingGroup) => {
                                return (
                                    <tr>
                                        <td>{billingGroup.billingGroup.title}</td>
                                        <td>{billingGroup.users.length}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td>
                                    <strong>{t('BillingHistoryTableOneMonth-total')}</strong>
                                </td>
                                <td>{observatorTotal}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default translate('admin')(BillingTableOneMonth);
