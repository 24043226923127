import React, { Component } from 'react';
import update from 'immutability-helper';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { translate } from 'react-i18next';
import Team from '../../api/Team.js';

class TeamContactBookComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactBooks: [],
        };
        this.deleteContactBook = this.deleteContactBook.bind(this);
    }

    componentDidMount() {
        Team.getContactBooks(this.props.team.id).then((contactBooks) => this.setState({ contactBooks }));
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.team.id !== nextProps.team.id) {
            this.setState({ formTemplates: [] });
            Team.getContactBooks(nextProps.team.id).then((contactBooks) => this.setState({ contactBooks }));
        }
    }

    deleteContactBook(index, contactBook) {
        Team.removeContactBook(this.props.team.id, contactBook.id).then(() => {
            const newState = update(this.state, {
                contactBooks: { $splice: [[index, 1]] },
            });

            this.setState(newState);
        });
    }

    render() {
        const { t } = this.props;

        const listContactBooks = this.state.contactBooks.map((contactBook, index) => {
            return (
                <tr key={contactBook.id}>
                    <td>{contactBook.title}</td>
                    <td>
                        <Button outline color="danger" onClick={() => this.deleteContactBook(index, contactBook)}>
                            {t('common-delete')}
                        </Button>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <Row>
                    <Col md="12">
                        <h4>{t('TeamContactBook-title')}</h4>
                        <Row>
                            <Col md="12">{t('TeamContactBook-explanation')}</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>{t('common-name')}</th>
                                            <th>{t('common-delete')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>{listContactBooks}</tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default translate('admin')(TeamContactBookComponent);
