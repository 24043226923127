import React from 'react';
import { translate } from 'react-i18next';
import powerBiImage from '../../../theme/img/powerbi-001.jpeg';

const EmptyState = (props) => {
    const { t } = props;
    return (
        <div>
            <p>{t('ExportApiDatasets-empyStateText')}</p>
            <p>
                {t('ExportApiDatasets-empyStateContactUs')}{' '}
                <a href="mailto:sales@bulldozair.com">sales@bulldozair.com</a>.
            </p>
            <img className="img-fluid" src={powerBiImage} alt="Power BI" />
        </div>
    );
};

export default translate('admin')(EmptyState);
