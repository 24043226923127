import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import NotificationSystem from 'react-notification-system';
import moment from 'moment';
import BillingHistoryChart from './BillingHistoryChart';
import Organization from '../../api/Organization';
import BillingList from './BillingHistoryList';
import BillingHistoryExport from './BillingHistoryExport';
import Csv from '../../api/Csv';

class BillingHistory extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {
            months: null,
            idOpened: null,
            activeTab: '1',
        };
        this.getData = this.getData.bind(this);
        this.getCurrentOrganization = this.getCurrentOrganization.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggle = this.toggle.bind(this);
        this.exportCsv = this.exportCsv.bind(this);
        this.exportTotalStatsCsv = this.exportTotalStatsCsv.bind(this);
    }

    componentDidMount() {
        this.getCurrentOrganization();
        this.getData();
    }

    async getCurrentOrganization() {
        const currentOrganizationId = this.props.match.params.organization_id;
        const organization = await Organization.getById(currentOrganizationId);
        this.setState({ organization });
    }

    async getData() {
        const currentOrganizationId = this.props.match.params.organization_id;
        const months = await Organization.getHistory(currentOrganizationId);
        this.setState({ months });
    }

    toggleCollapse(idOpened) {
        if (this.state.idOpened == idOpened) {
            this.setState({ idOpened: null });
        } else {
            this.setState({ idOpened });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    exportCsv(billingGroupId, billingGroupTitle) {
        const headers = {};
        const headersOrder = [];
        const rowsArray = [];
        const columns = {};
        let rowMax = 0;
        const rows = [];

        this.state.months.forEach((month) => {
            month.billingGroups.forEach((billingGroup) => {
                if (billingGroup.billingGroup.id == billingGroupId) {
                    headers[month.date] = moment(month.date).format('MMMM YYYY');
                    headersOrder.push(month.date);
                    columns[month.date] = billingGroup.users;
                    if (billingGroup.users.length > rowMax) rowMax = billingGroup.users.length;
                }
            });
        });

        for (let i = 0; i < rowMax; i++) {
            const row = {};
            for (const date in columns) {
                if (columns[date][i]) {
                    row[date] = columns[date][i].email;
                } else {
                    row[date] = ' ';
                }
            }
            rows.push(row);
        }

        Csv.exportCSVFile(headers, headersOrder, rows, `stats-${billingGroupTitle}`);
    }

    exportTotalStatsCsv() {
        const headers = {
            date: this.props.t('BillinHistoryExport-csv-column-date'),
        };
        const headersOrder = ['date'];
        const rows = [];

        this.state.months.forEach((month) => {
            const row = {
                date: moment(month.date).format('MMMM YYYY'),
            };
            month.billingGroups.forEach((billingGroup) => {
                if (!headers.hasOwnProperty(billingGroup.billingGroup.id)) {
                    headers[billingGroup.billingGroup.id] = billingGroup.billingGroup.title;
                    headersOrder.push(billingGroup.billingGroup.id);
                }
                row[billingGroup.billingGroup.id] = billingGroup.users.length;
            });
            rows.push(row);
        });

        // fill undefined column with zero, so it doesn't display "undefined" in the CSV.
        headersOrder.forEach(function(headerId) {
            rows.forEach((row) => {
                if (row[headerId] === undefined) row[headerId] = 0;
            });
        });

        Csv.exportCSVFile(headers, headersOrder, rows, `bulldozair-stats`);
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <div className="element-wrapper">
                    <h6 className="element-header">{this.state.organization && this.state.organization.title}</h6>
                    <div className="element-box">
                        <div className="os-tabs-w">
                            <div className="os-tabs-controls">
                                <Nav tabs className="smaller">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => {
                                                this.toggle('1');
                                            }}
                                        >
                                            {t('BillingHistory-history-tab')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => {
                                                this.toggle('2');
                                            }}
                                        >
                                            {t('BillingHistory-export-tab')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    {this.state.months && <BillingHistoryChart months={this.state.months} />}

                                    <hr />

                                    {this.state.months && (
                                        <BillingList
                                            months={this.state.months}
                                            idOpened={this.state.idOpened}
                                            toggleCollapse={this.toggleCollapse}
                                        />
                                    )}
                                </TabPane>
                                <TabPane tabId="2">
                                    {this.state.months && (
                                        <BillingHistoryExport
                                            months={this.state.months}
                                            exportCsv={this.exportCsv}
                                            exportTotalStatsCsv={this.exportTotalStatsCsv}
                                        />
                                    )}
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>

                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default translate('admin')(BillingHistory);
