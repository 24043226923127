import React, { Component } from 'react';
import update from 'immutability-helper';
import { Row, Col, Button, FormGroup, Input, Table, Spinner } from 'reactstrap';
import Infinite from 'react-infinite';
import { translate } from 'react-i18next';
import Team from '../../api/Team.js';
import checkSquare from '../../../theme/img/check-square.svg';
import square from '../../../theme/img/square.svg';
import minusSquare from '../../../theme/img/minus-square.svg';
import threeDotsImg from '../../../theme/img/three-dots.svg';
import Organization from '../../api/Organization.js';
import { debounce } from 'debounce';
import './style.css';

const USER_SELECTED_STATES = {
    NONE: 'none',
    ALL: 'all',
    PARTIAL: 'partial',
};

class TeamUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            elements: this.buildElements([]),
            usersSelectedState: USER_SELECTED_STATES.NONE,
            usersSelectedToAdd: {},
            billingGroupsSelectedToAdd: {},
            newEmails: '',
            isInfiniteLoading: false,
            infiniteLoadBeginEdgeOffset: 200,
            resendInvitationStatus: {},
            organizationId: this.props.organizationId,
            refreshingOrgUsers: false,
            dontDisplayOrgUsersTab: false,
            organizationUsers: [],
            organizationUsersByBillingGroups: [],
            searchValue: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.addUsers = this.addUsers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.selectAllUsers = this.selectAllUsers.bind(this);
        this.selectAllUsersFromBillingGroup = this.selectAllUsersFromBillingGroup.bind(this);
        this.unSelectAllUsersFromBillingGroup = this.unSelectAllUsersFromBillingGroup.bind(this);
        this.unSelectAllUsers = this.unSelectAllUsers.bind(this);
        this.addSelectedUsersToTeam = this.addSelectedUsersToTeam.bind(this);
        this.makeAdmin = this.makeAdmin.bind(this);
        this.revokeAdmin = this.revokeAdmin.bind(this);
        this.resendInvitationEmail = this.resendInvitationEmail.bind(this);
        this.addUserToTeam = this.addUserToTeam.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.refreshOrgUsers = this.refreshOrgUsers.bind(this);
        this.refreshOrgUsersWithDebounce = debounce(this.refreshOrgUsers, 500);
    }

    addUsers() {
        this.setState({ saving: true });
        const emailsUnfiltered = this.state.newEmails.split('\n');
        const emails = [];

        // remove useless empty rows, keep only valid emails
        for (let i = 0; i < emailsUnfiltered.length; i++) {
            if (this._validateEmail(emailsUnfiltered[i])) {
                emails.push(emailsUnfiltered[i]);
            }
        }

        return Team.addUsers(this.props.team.id, emails)
            .then((results) => {
                let failedUsers = '';

                results.errors.forEach((err) => {
                    this.props.notificationSystem.addNotification({
                        message: this.props.t('TeamUserList-fail-to-add-user', { email: err.userEmail }),
                        level: 'error',
                    });
                    failedUsers += `${err.userEmail}\n`;
                });

                let newState = update(this.state, {
                    users: { $push: results.results },
                    newEmails: { $set: failedUsers },
                    saving: { $set: false },
                });

                newState = update(newState, {
                    elements: { $set: this.buildElements(newState.users) },
                });

                this.setState(newState);
            })
            .catch((err) => {
                this.setState({ saving: false });
            });
    }

    _validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    getSelectButtonImage() {
        const { usersSelectedState } = this.state;
        if (usersSelectedState === USER_SELECTED_STATES.NONE) {
            return square;
        }
        if (usersSelectedState === USER_SELECTED_STATES.PARTIAL) {
            return minusSquare;
        }
        if (usersSelectedState === USER_SELECTED_STATES.ALL) {
            return checkSquare;
        }
    }

    getSelectButtonFunction() {
        const { usersSelectedState } = this.state;
        if (usersSelectedState === USER_SELECTED_STATES.NONE) {
            return this.selectAllUsers;
        }
        if (usersSelectedState === USER_SELECTED_STATES.PARTIAL) {
            return this.unSelectAllUsers;
        }
        if (usersSelectedState === USER_SELECTED_STATES.ALL) {
            return this.unSelectAllUsers;
        }
    }

    handleKeyUp(e) {
        if (e.key === 'Shift' && this.state.isShiftDown) {
            this.setState({ isShiftDown: false });
        }
    }

    handleKeyDown(e) {
        if (e.key === 'Shift' && !this.state.isShiftDown) {
            this.setState({ isShiftDown: true });
        }
    }

    selectAllUsers() {
        const {
            usersSelectedToAdd,
            organizationUsers,
            organizationUsersByBillingGroups,
            billingGroupsSelectedToAdd,
        } = this.state;
        organizationUsers.forEach((user) => {
            usersSelectedToAdd[user.id] = true;
        });
        organizationUsersByBillingGroups.forEach((billingGroup) => {
            billingGroupsSelectedToAdd[billingGroup.id] = true;
        });
        const usersSelectedState = USER_SELECTED_STATES.ALL;
        this.setState({
            usersSelectedToAdd,
            billingGroupsSelectedToAdd,
            usersSelectedState,
        });
    }

    getUserSelectedState(organizationUsers, usersSelectedToAdd) {
        const numberOfSelectedUsers = Object.keys(usersSelectedToAdd).length;
        if (numberOfSelectedUsers === 0) {
            return USER_SELECTED_STATES.NONE;
        }
        if (numberOfSelectedUsers === organizationUsers.length) {
            return USER_SELECTED_STATES.ALL;
        }

        return USER_SELECTED_STATES.PARTIAL;
    }

    getBillingGroupSelected(organizationUsersByBillingGroups, usersSelectedToAdd) {
        const billingGroupsSelectedToAdd = {};
        organizationUsersByBillingGroups.forEach((billingGroup) => {
            let userSelectedCount = 0;
            billingGroup.users.forEach((user) => {
                if (usersSelectedToAdd[user.id]) {
                    userSelectedCount += 1;
                }
            });
            if (userSelectedCount === billingGroup.users.length) {
                billingGroupsSelectedToAdd[billingGroup.id] = true;
            }
        });
        return billingGroupsSelectedToAdd;
    }

    selectAllUsersFromBillingGroup(event) {
        const { name } = event.target;
        const {
            usersSelectedToAdd,
            billingGroupsSelectedToAdd,
            organizationUsersByBillingGroups,
            organizationUsers,
        } = this.state;
        const billingGroup = organizationUsersByBillingGroups.find((bg) => bg.id === name);
        if (billingGroup) {
            billingGroup.users.forEach((user) => {
                usersSelectedToAdd[user.id] = true;
            });
            billingGroupsSelectedToAdd[billingGroup.id] = true;
            const usersSelectedState = this.getUserSelectedState(organizationUsers, usersSelectedToAdd);
            this.setState({
                usersSelectedToAdd,
                usersSelectedState,
            });
        }
    }

    unSelectAllUsersFromBillingGroup(event) {
        const { name } = event.target;
        const {
            usersSelectedToAdd,
            billingGroupsSelectedToAdd,
            organizationUsersByBillingGroups,
            organizationUsers,
        } = this.state;
        const billingGroup = organizationUsersByBillingGroups.find((bg) => bg.id === name);
        if (billingGroup) {
            billingGroup.users.forEach((user) => {
                delete usersSelectedToAdd[user.id];
            });
            delete billingGroupsSelectedToAdd[billingGroup.id];
            const usersSelectedState = this.getUserSelectedState(organizationUsers, usersSelectedToAdd);
            this.setState({
                usersSelectedToAdd,
                usersSelectedState,
                billingGroupsSelectedToAdd,
            });
        }
    }

    unSelectAllUsers() {
        const usersSelectedToAdd = {};
        const billingGroupsSelectedToAdd = {};
        const usersSelectedState = USER_SELECTED_STATES.NONE;
        this.setState({
            usersSelectedToAdd,
            billingGroupsSelectedToAdd,
            usersSelectedState,
        });
    }

    getNextValue(selectedUserId, checked) {
        const { isShiftDown, usersSelectedToAdd, organizationUsers } = this.state;

        if (isShiftDown) {
            const firstSelectedItem = organizationUsers.findIndex((user) => usersSelectedToAdd[user.id]);
            const currentSelectedItem = organizationUsers.findIndex((user) => user.id === selectedUserId);
            const firstItem = currentSelectedItem < firstSelectedItem ? currentSelectedItem : firstSelectedItem;
            const lastItem = currentSelectedItem >= firstSelectedItem ? currentSelectedItem : firstSelectedItem;
            let finished = false;
            let i = firstItem;
            while (!finished && i < organizationUsers.length) {
                const currentUserId = organizationUsers[i].id;
                if (checked) {
                    usersSelectedToAdd[currentUserId] = true;
                } else {
                    delete usersSelectedToAdd[currentUserId];
                }
                finished = i === lastItem;
                i++;
            }
            return usersSelectedToAdd;
        }
        if (checked) {
            usersSelectedToAdd[selectedUserId] = true;
        } else {
            delete usersSelectedToAdd[selectedUserId];
        }
        return usersSelectedToAdd;
    }

    handleCheckboxChange(event) {
        const { target } = event;
        const userId = target.name;
        const { organizationUsers, organizationUsersByBillingGroupsWithoutFilters } = this.state;

        const usersSelectedToAdd = this.getNextValue(userId, target.checked);
        const usersSelectedState = this.getUserSelectedState(organizationUsers, usersSelectedToAdd);

        const billingGroupsSelectedToAdd = this.getBillingGroupSelected(
            organizationUsersByBillingGroupsWithoutFilters,
            usersSelectedToAdd,
        );

        this.setState({
            usersSelectedToAdd,
            usersSelectedState,
            billingGroupsSelectedToAdd,
        });
    }

    async addSelectedUsersToTeam() {
        const { usersSelectedToAdd, organizationUsers } = this.state;
        const userEmails = [];
        Object.keys(usersSelectedToAdd).forEach((userId) => {
            const user = organizationUsers.find((u) => u.id === userId);
            userEmails.push(user.email);
        });
        try {
            await this.setState({ refreshingOrgUsers: true });
            const results = await Team.addUsers(this.props.team.id, userEmails);
            results.errors.forEach((err) => {
                this.props.notificationSystem.addNotification({
                    message: this.props.t('TeamUserList-fail-to-add-user', { email: err.userEmail }),
                    level: 'error',
                });
            });
            await this.setState({
                users: [],
                elements: [],
                usersSelectedToAdd: {},
                billingGroupsSelectedToAdd: {},
                usersSelectedState: USER_SELECTED_STATES.NONE,
                searchValue: '',
                organizationUsers: [],
                organizationUsersByBillingGroups: [],
            });
            await this.handleInfiniteLoad();
            await this.refreshOrgUsers();
        } catch (e) {
            console.error(e);
            this.props.notificationSystem.addNotification({
                message: this.props.t('TeamUserList-fail-to-add-users-unknown-error'),
                level: 'error',
            });
        }
        await this.setState({ refreshingOrgUsers: false });
    }

    async deleteUser(userId) {
        await Team.deleteUser(this.props.team.id, userId);
        const userIndex = this.state.users.findIndex((user) => user.id === userId);
        if (userIndex !== -1) {
            const newState = update(this.state, {
                users: { $splice: [[userIndex, 1]] },
                elements: { $splice: [[userIndex, 1]] },
            });

            await this.setState(newState);
        }
        await this.refreshOrgUsers();
    }

    async resendInvitationEmail(userId) {
        try {
            await this.setState(
                update(this.state, {
                    elements: { $set: this.buildElements(this.state.users) },
                    resendInvitationStatus: {
                        [userId]: {
                            $set: 'sending',
                        },
                    },
                }),
            );
            await Team.resendInvitationEmail(this.props.team.id, userId);
            this.props.notificationSystem.addNotification({
                message: this.props.t('TeamUserList-resendInvitationSuccess'),
                level: 'success',
            });
            await this.setState(
                update(this.state, {
                    elements: { $set: this.buildElements(this.state.users) },
                    resendInvitationStatus: {
                        [userId]: {
                            $set: 'sent',
                        },
                    },
                }),
            );
        } catch (e) {
            console.error(e);
            await this.setState(
                update(this.state, {
                    elements: { $set: this.buildElements(this.state.users) },
                    resendInvitationStatus: {
                        [userId]: {
                            $set: undefined,
                        },
                    },
                }),
            );
            this.props.notificationSystem.addNotification({
                message: this.props.t('TeamUserList-resendInvitationError'),
                level: 'error',
            });
        }
    }

    makeAdmin(index, userId) {
        Team.makeAdmin(this.props.team.id, userId).then(() => {
            let newState = update(this.state, {
                users: { [index]: { role: { $set: 'admin' } } },
            });

            newState = update(newState, {
                elements: { $set: this.buildElements(newState.users) },
            });

            this.setState(newState);
        });
    }

    revokeAdmin(index, userId) {
        Team.revokeAdmin(this.props.team.id, userId).then(() => {
            let newState = update(this.state, {
                users: { [index]: { role: { $set: null } } },
            });

            newState = update(newState, {
                elements: { $set: this.buildElements(newState.users) },
            });

            this.setState(newState);
        });
    }

    handleChange(event) {
        this.setState({ newEmails: event.target.value });
    }

    async handleInfiniteLoad() {
        this.setState({
            isInfiniteLoading: true,
        });

        const newUsers = await Team.getUsers(this.props.team.id, 50, this.state.users.length);
        const newState = update(this.state, {
            users: { $push: newUsers },
            elements: { $push: this.buildElements(newUsers) },
            isInfiniteLoading: { $set: false },
        });

        if (newUsers.length === 0) {
            newState.infiniteLoadBeginEdgeOffset = undefined;
        }

        await this.setState(newState);
    }

    elementInfiniteLoad() {
        const { t } = this.props;
        return <div>{t('TeamUserList-loading')}...</div>;
    }

    buildElements(newUsers) {
        const { t, isAllowedToEditTeam } = this.props;

        const listUsers = newUsers.map((user) => {
            return (
                <tr key={user.id}>
                    <td style={{ maxWidth: '15rem', overflow: 'scroll' }}>{user.email}</td>
                    <td>
                        {user.account_activated === false && (
                            <Button
                                outline
                                color="primary"
                                disabled={this.state && this.state.resendInvitationStatus[user.id]}
                                onClick={() => this.resendInvitationEmail(user.id)}
                            >
                                {t('TeamUserList-resendInvitationEmail')}
                            </Button>
                        )}
                    </td>
                    <td>
                        {isAllowedToEditTeam && (
                            <Button outline color="danger" onClick={() => this.deleteUser(user.id)}>
                                {t('common-delete')}
                            </Button>
                        )}
                    </td>
                </tr>
            );
        });

        return listUsers;
    }

    async refreshOrgUsers() {
        await this.setState({ refreshingOrgUsers: true });
        try {
            const usersAlreadyInTeam = await Team.getUsers(this.props.team.id, 100000, 0);
            const users = await Organization.getAllUsers(this.state.organizationId);
            const usersEmailsAlreadyInTeam = usersAlreadyInTeam.map((u) => u.email);
            const usersNotAlreadyInTeam = users.filter((user) => !usersEmailsAlreadyInTeam.includes(user.email));
            const usersWithFilter = usersNotAlreadyInTeam.filter((user) =>
                user.email.toLowerCase().includes(this.state.searchValue.toLowerCase()),
            );

            // Build a list of users by billing group, without user filters
            const organizationUsersByBillingGroupsWithoutFilters = [];
            const billingGroupByIndexWithoutFilters = new Map();
            usersNotAlreadyInTeam.forEach((user) => {
                if (!billingGroupByIndexWithoutFilters.has(user.billing_group_id)) {
                    organizationUsersByBillingGroupsWithoutFilters.push({
                        id: user.billing_group_id,
                        title: user.billing_group_title,
                        users: [],
                    });
                    billingGroupByIndexWithoutFilters.set(
                        user.billing_group_id,
                        organizationUsersByBillingGroupsWithoutFilters.length - 1,
                    );
                }
                const billingGroupIndex = billingGroupByIndexWithoutFilters.get(user.billing_group_id);
                organizationUsersByBillingGroupsWithoutFilters[billingGroupIndex].users.push(user);
            });

            // Build a list of users by billing group with user filters
            const organizationUsersByBillingGroups = [];
            const billingGroupByIndex = new Map();
            usersWithFilter.forEach((user) => {
                if (!billingGroupByIndex.has(user.billing_group_id)) {
                    organizationUsersByBillingGroups.push({
                        id: user.billing_group_id,
                        title: user.billing_group_title,
                        users: [],
                    });
                    billingGroupByIndex.set(user.billing_group_id, organizationUsersByBillingGroups.length - 1);
                }
                const billingGroupIndex = billingGroupByIndex.get(user.billing_group_id);
                organizationUsersByBillingGroups[billingGroupIndex].users.push(user);
            });
            const billingGroupsSelectedToAdd = this.getBillingGroupSelected(
                organizationUsersByBillingGroupsWithoutFilters,
                this.state.usersSelectedToAdd,
            );
            await this.setState({
                refreshingOrgUsers: false,
                organizationUsers: usersWithFilter,
                organizationUsersByBillingGroups,
                organizationUsersByBillingGroupsWithoutFilters,
                billingGroupsSelectedToAdd,
            });
        } catch (e) {
            console.error(e);
            await this.setState({ refreshingOrgUsers: false });
            if (e.response.status === 401) {
                this.setState({ dontDisplayOrgUsersTab: true });
            }
        }
    }

    async onSearchChange(e) {
        const newSearchValue = e.target.value.length ? e.target.value : '';
        await this.setState({ searchValue: newSearchValue });
        this.refreshOrgUsersWithDebounce();
    }

    addUserToTeam(userEmail) {
        return Team.addUsers(this.props.team.id, [userEmail])
            .then((results) => {
                let failedUsers = '';

                results.errors.forEach((err) => {
                    this.props.notificationSystem.addNotification({
                        message: this.props.t('TeamUserList-fail-to-add-user', { email: err.userEmail }),
                        level: 'error',
                    });
                    failedUsers += `${err.userEmail}\n`;
                });

                let newState = update(this.state, {
                    users: { $push: results.results },
                    organizationUsers: {
                        $set: this.state.organizationUsers.filter((user) => user.email !== userEmail),
                    },
                    newEmails: { $set: failedUsers },
                    saving: { $set: false },
                });

                newState = update(newState, {
                    elements: {
                        $set: this.buildElements(newState.users),
                    },
                    searchValue: { $set: '' },
                });

                this.setState(newState);
            })
            .catch((err) => {
                this.setState({ saving: false });
            });
    }

    componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp, false);
        document.addEventListener('keydown', this.handleKeyDown, false);
        this.refreshOrgUsers();
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    render() {
        const { t, isAllowedToEditTeam } = this.props;
        const {
            searchValue,
            elements,
            organizationUsersByBillingGroups,
            usersSelectedToAdd,
            billingGroupsSelectedToAdd,
            refreshingOrgUsers,
            dontDisplayOrgUsersTab,
        } = this.state;

        return (
            <div>
                <h4>{t('TeamUserList-title')}</h4>
                <Row>
                    <Col md="12">
                        {isAllowedToEditTeam && <p>{t('TeamUserList-explanation')}</p>}

                        {!isAllowedToEditTeam && (
                            <p>
                                <div className="alert alert-primary">{t('TeamParameters-DefaultTeamWarning')}</div>{' '}
                            </p>
                        )}
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    {isAllowedToEditTeam && (
                        <Col lg="7">
                            <FormGroup row>
                                <Col sm={12}>
                                    <Input
                                        type="textarea"
                                        name="text"
                                        id="users"
                                        value={this.state.newEmails}
                                        onChange={this.handleChange}
                                        placeholder={t('TeamUserList-userFieldPlaceholder')}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col sm={{ size: 10 }}>
                                    <Button outline color="success" onClick={this.addUsers}>
                                        {this.state.saving ? (
                                            <img src={threeDotsImg} alt="waiting" className="loaderSvg" />
                                        ) : (
                                            t('TeamUserList-addUsersButton')
                                        )}
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Col>
                    )}
                    <Col lg="7">
                        <Infinite
                            elementHeight={63}
                            containerHeight={600}
                            preloadBatchSize={elements.length * 63}
                            infiniteLoadBeginEdgeOffset={this.state.infiniteLoadBeginEdgeOffset}
                            onInfiniteLoad={this.handleInfiniteLoad}
                            loadingSpinnerDelegate={this.elementInfiniteLoad()}
                            isInfiniteLoading={this.state.isInfiniteLoading}
                        >
                            <div className="table-responsive">
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>{t('TeamUserList-emailColumn')}</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>{elements}</tbody>
                                </Table>
                            </div>
                        </Infinite>
                    </Col>
                    {!dontDisplayOrgUsersTab && isAllowedToEditTeam && (
                        <Col lg="5">
                            <h4>{t('TeamUserList-addUsersFromBillingGroupTitle')}</h4>
                            <p>{t('TeamUserList-addUsersFromBillingGroupDescription')}</p>
                            <input
                                className="form-control"
                                placeholder={t('StatisticUsers-searchUserPlaceholder')}
                                onChange={this.onSearchChange}
                                value={searchValue}
                                type="text"
                            />
                            <div className="mt-3">
                                <button className="btn selectAllCheckbox" onClick={this.getSelectButtonFunction()}>
                                    <img
                                        src={this.getSelectButtonImage()}
                                        alt="square"
                                        className="image-button-select-all"
                                    />
                                </button>
                                <button className="ml-3 btn btn-outline-primary" onClick={this.addSelectedUsersToTeam}>
                                    {t('TeamUserList-addUsersToWorkGroupButton')}
                                </button>
                            </div>
                            {refreshingOrgUsers && (
                                <div className="d-flex justify-content-center mt-4 mb-4">
                                    <Spinner />
                                </div>
                            )}
                            {!refreshingOrgUsers && (
                                <div className="table-responsive billingGroupTable">
                                    {organizationUsersByBillingGroups.map((billingGroup) => (
                                        <div className="mt-4">
                                            <Table striped responsive key={billingGroup.id}>
                                                <thead>
                                                    <tr>
                                                        <th className="checkBoxColumn">
                                                            <input
                                                                type="checkbox"
                                                                name={billingGroup.id}
                                                                checked={billingGroupsSelectedToAdd[billingGroup.id]}
                                                                onChange={
                                                                    !billingGroupsSelectedToAdd[billingGroup.id]
                                                                        ? this.selectAllUsersFromBillingGroup
                                                                        : this.unSelectAllUsersFromBillingGroup
                                                                }
                                                            />
                                                        </th>
                                                        <th>{billingGroup.title}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {billingGroup.users.map((user) => (
                                                        <tr key={`${user.id}.${usersSelectedToAdd[user.id]}`}>
                                                            <td className="checkBoxColumn">
                                                                <input
                                                                    type="checkbox"
                                                                    name={user.id}
                                                                    checked={usersSelectedToAdd[user.id]}
                                                                    onChange={this.handleCheckboxChange}
                                                                />
                                                            </td>
                                                            <td className="emailColumn">{user.email}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

export default translate('admin')(TeamUserList);
