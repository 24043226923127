import React, { Component } from 'react';
import update from 'immutability-helper';
import { Link } from 'react-router-dom';

import { translate } from 'react-i18next';

import Organization from '../../api/Organization';
import NewBillingGroupModal from './BillingGroupModal';
import BillingGroup from '../../api/BillingGroup.js';
import Auth from '../../../app/Auth';

class BillingGroupList extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {
            billingGroups: [],
            newBillingGroupModalOpen: false,
            currentOrganization: null,
            notEnoughSeatAvailable: false,
            isOrgaAdmin: false,
            currentBillingGroupToEdit: null,
            newBillingGroupInvalid: false,
        };
        this.getBillingGroups = this.getBillingGroups.bind(this);
        this.createBillingGroup = this.createBillingGroup.bind(this);
        this.deleteBillingGroup = this.deleteBillingGroup.bind(this);
        this.editBillingGroup = this.editBillingGroup.bind(this);
        this.toggleNewBillingGroupModal = this.toggleNewBillingGroupModal.bind(this);
        this.getRights = this.getRights.bind(this);
    }

    toggleNewBillingGroupModal() {
        this.setState({
            newBillingGroupModalOpen: !this.state.newBillingGroupModalOpen,
            notEnoughSeatAvailable: false,
            nbRemainingSeats: null,
        });
    }

    async getBillingGroups() {
        const currentOrganizationId = this.props.match.params.organization_id;
        const billingGroups = await BillingGroup.get(currentOrganizationId);
        this.setState({ billingGroups });
    }

    async getCurrentOrganization() {
        const currentOrganizationId = this.props.match.params.organization_id;
        const organization = await Organization.getById(currentOrganizationId);
        this.setState({ organization });
    }

    async getRights() {
        const rights = await Auth.getRights();
        const isOrgaAdmin =
            rights.organizations.find((o) => o.id === this.props.match.params.organization_id) !== undefined;
        this.setState({ isOrgaAdmin });
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        this.getBillingGroups();
        this.getCurrentOrganization();
        this.getRights();
    }

    createBillingGroup(organizationId, title, max_nb_seat, license) {
        if (!title || title.length == 0 || max_nb_seat === null || license === null) {
            return this.setState({ newBillingGroupInvalid: true });
        }
        BillingGroup.create(organizationId, { title, max_nb_seat: parseInt(max_nb_seat), license })
            .then((newBillinGroup) => {
                newBillinGroup.occupied_seats = 0;

                const newState = update(this.state, {
                    billingGroups: { $push: [newBillinGroup] },
                    notEnoughSeatAvailable: { $set: false },
                    newBillingGroupModalOpen: { $set: false },
                });

                this.setState(newState);
            })
            .catch((err) => {
                if (err.response.status === 402) {
                    this.setState({
                        notEnoughSeatAvailable: true,
                        nbRemainingSeats: err.response.data.meta.customData.remaining_seats,
                    });
                }
            });
    }

    editBillingGroup(index, billingGroupId, title, max_nb_seat) {
        BillingGroup.update(billingGroupId, { title, max_nb_seat: parseInt(max_nb_seat) })
            .then((newBillingGroup) => {
                const newState = update(this.state, {
                    billingGroups: { [index]: { $set: newBillingGroup } },
                    notEnoughSeatAvailable: { $set: false },
                    editBillingGroupModalOpen: { $set: false },
                });

                this.setState(newState);
            })
            .catch((err) => {
                if (err.response && err.response.status === 402) {
                    this.setState({
                        notEnoughSeatAvailable: true,
                        nbRemainingSeats: err.response.data.meta.customData.remaining_seats,
                    });
                }
            });
    }

    deleteBillingGroup(billingGroupId, index) {
        BillingGroup.delete(billingGroupId).then(() => {
            const newState = update(this.state, {
                billingGroups: { $splice: [[index, 1]] },
            });

            this.setState(newState);
        });
    }

    render() {
        const { t } = this.props;
        let totalSeatsOccupied = 0;
        let totalSeatGivenToBillingGroups = 0;
        const currentOrganizationId = this.props.match.params.organization_id;

        const billingGroupElement = this.state.billingGroups.map((billingGroup, index) => {
            totalSeatsOccupied += billingGroup.occupied_seats;
            totalSeatGivenToBillingGroups += billingGroup.max_nb_seat;

            const isDefaultBillingGroup = billingGroup.is_default_external || billingGroup.is_default_internal;

            return (
                <tr key={billingGroup.id}>
                    <td>{billingGroup.title}</td>
                    <td>
                        {billingGroup.occupied_seats} {isDefaultBillingGroup ? '' : `/ ${billingGroup.max_nb_seat}`}
                    </td>
                    <td>
                        {billingGroup.license === 'internal' && t('BillingGroupList-license-internal')}
                        {billingGroup.license === 'subcontractor' && t('BillingGroupList-license-subcontractor')}
                        {billingGroup.license === 'observator' && t('BillingGroupList-license-observator')}
                    </td>
                    <td>
                        <Link
                            to={{
                                pathname: `/${currentOrganizationId}/billing-group/${billingGroup.id}`,
                                state: { billingGroupTitle: billingGroup.title },
                            }}
                        >
                            <button className="mr-2 mb-2 btn btn-sm btn-primary">
                                {t('BillingGroupModal-seeButton')}
                            </button>
                        </Link>
                    </td>
                </tr>
            );
        });

        if (!this.state.organization) return <div />;

        return (
            <div className="element-wrapper">
                <h6 className="element-header">
                    {this.state.organization.title} / {t('BillingGroupList-title')}
                </h6>
                <div className="element-box">
                    <h5 className="form-header">{this.state.organization.title}</h5>
                    <div className="form-desc">
                        {t('BillingGroupList-activeLicenseSentence', {
                            count: totalSeatsOccupied,
                            count_total: totalSeatGivenToBillingGroups,
                        })}
                    </div>

                    {this.state.isOrgaAdmin ? (
                        <div className="controls-above-table">
                            <button className="btn btn-sm btn-primary" onClick={this.toggleNewBillingGroupModal}>
                                {t('common-create')}
                            </button>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="table-responsive">
                        <table className="table table-lightborder">
                            <thead>
                                <tr>
                                    <th>{t('BillingGroupList-table-name-column')}</th>
                                    <th>{t('BillingGroupList-table-number-licenses-column')}</th>
                                    <th>{t('BillingGroupList-table-license-type-column')}</th>
                                    <th>{t('BillingGroupList-table-manage-column')}</th>
                                </tr>
                            </thead>
                            <tbody>{billingGroupElement}</tbody>
                        </table>
                    </div>
                </div>
                <NewBillingGroupModal
                    isOpen={this.state.newBillingGroupModalOpen}
                    toggle={this.toggleNewBillingGroupModal}
                    organization={this.state.organization}
                    createBillingGroup={this.createBillingGroup}
                    newBillingGroupInvalid={this.state.newBillingGroupInvalid}
                    notEnoughSeatAvailable={this.state.notEnoughSeatAvailable}
                    nbRemainingSeats={this.state.nbRemainingSeats}
                />
            </div>
        );
    }
}

export default translate('admin')(BillingGroupList);
